define("vimme/templates/school-admin/reports/week", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/school-admin/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/school-admin/reports/week", [], ["classroomId", ["subexpr", "@mut", [["get", "this.classroom", ["loc", [null, [2, 16], [2, 30]]]]], [], []], "courseAveragesRaw", ["subexpr", "@mut", [["get", "this.courseAveragesRaw", ["loc", [null, [3, 22], [3, 44]]]]], [], []], "courseId", ["subexpr", "@mut", [["get", "this.course", ["loc", [null, [4, 13], [4, 24]]]]], [], []], "courses", ["subexpr", "@mut", [["get", "this.model.courses", ["loc", [null, [5, 12], [5, 30]]]]], [], []], "isPrint", ["subexpr", "@mut", [["get", "this.print", ["loc", [null, [6, 12], [6, 22]]]]], [], []], "school", ["subexpr", "@mut", [["get", "this.model.school", ["loc", [null, [7, 11], [7, 28]]]]], [], []], "schoolYears", ["subexpr", "@mut", [["get", "this.model.schoolYears", ["loc", [null, [8, 16], [8, 38]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "this.model.weeks", ["loc", [null, [9, 10], [9, 26]]]]], [], []]], ["loc", [null, [1, 0], [10, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('vimme/components/vimme-week-view-row/component', ['exports', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _round, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    paddedWeeklyAverages: function () {
      var weeklyAverages = this.get('average.weeklyAverages');
      var weeks = this.get('weeks');
      var grade = this.get('average.course.grade.id');

      return weeks.map(function (week, num) {
        var weeklyAverage = weeklyAverages.find(function (weeklyAverage) {
          if (weeklyAverage.get('test')) {
            return weeklyAverage.get('test.week.number') === week.get('number');
          } else if (weeklyAverage.get('scheduledTest')) {
            return weeklyAverage.get('scheduledTest.test.week.number') === week.get('number');
          }
        });

        var scoreColor = weeklyAverage ? (0, _color.default)(weeklyAverage.get('roundedPercent'), grade) : null;

        return { average: weeklyAverage || null, num: num + 1, color: scoreColor };
      });
    }.property('average.{weeklyAverages,course.grade.id}'),

    percentages: Ember.computed.mapBy('average.weeklyAverages', 'percent'),

    percentagesSum: Ember.computed.sum('percentages'),

    percent: function () {
      var len = this.get('average.weeklyAverages.length');
      if (!len) return 0;
      return this.get('percentagesSum') / len;
    }.property('average.weeklyAverages.length', 'percentagesSum'),

    roundedPercent: function () {
      return (0, _round.default)(this.get('percent'));
    }.property('percent'),

    formattedPercent: function () {
      return this.get('roundedPercent') + '%';
    }.property('roundedPercent'),

    color: function () {
      return (0, _color.default)(this.get('roundedPercent'), this.get('average.course.grade.id'));
    }.property('roundedPercent', 'average.course.grade.id')
  });
});
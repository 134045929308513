define('vimme/routes/guardian/tests', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('guardian.reports.week');
    }
  });
});
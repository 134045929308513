define('vimme/routes/guardian/paper-assignment-builder/paper-assignment', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        categories: this.modelFor('guardian.paper-assignment-builder').categories,
        difficulties: this.modelFor('guardian.paper-assignment-builder').difficulties,
        grades: this.modelFor('guardian.paper-assignment-builder').grades,
        quiz: this.store.find('quiz', params.paper_assignment_id),
        guardian: this.modelFor('guardian.paper-assignment-builder').guardian
      });
    },
    resetController: function resetController(controller) {
      var quiz = controller.get('model.quiz');
      var quizQuestions = quiz.get('quizQuestions');
      quiz.rollback();
      quizQuestions.reload();
    }
  });
});
define('vimme/routes/student/resources', ['exports', 'vimme/routes/student', 'npm:jwt-decode'], function (exports, _student, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    session: Ember.inject.service(),
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          id = _ref.id;

      var student = this.store.findRecord('student', id);
      var week0 = Ember.Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var localWeeks = this.store.all('week');
      var weeks = [week0];
      if (localWeeks.get('length')) {
        weeks = weeks.concat(localWeeks.toArray());
      } else {
        weeks = this.store.findAll('week').then(function (moreWeeks) {
          return weeks.concat(moreWeeks.toArray());
        });
      }
      return student.then(function (student) {
        return Ember.RSVP.hash({
          grade: student.get('grade.id'),
          weeks: weeks
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!controller.get('grades')) {
        controller.set('grades', model.grade);
      }
    }
  });
});
define('vimme/components/vimme-percent/component', ['exports', 'vimme/utils/round'], function (exports, _round) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    total: 0,
    score: 0,

    percent: Ember.computed('total', 'score', function () {
      if (!this.get('total')) {
        return 0;
      }
      return this.get('score') / this.get('total') * 100;
    }),

    roundedPercent: Ember.computed('percent', function () {
      return (0, _round.default)(this.get('percent'));
    }),

    formattedPercent: Ember.computed('roundedPercent', function () {
      return this.get('roundedPercent') + '%';
    })
  });
});
define('vimme/controllers/admin/test/print', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    indexed: function () {
      var items = this.get('model.questions');

      return items.map(function (item, index) {
        item.set('index', index + 1);

        return item;
      });
    }.property('model.questions')
  });
});
define('vimme/routes/admin/management/schools/school', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        school: this.store.find('school', params.school_id)
      });
    }
  });
});
define("vimme/templates/school-admin/guardian-instructions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/school-admin/guardian-instructions.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/guardian-instructions", [], ["model", ["subexpr", "@mut", [["get", "this.model", ["loc", [null, [2, 10], [2, 20]]]]], [], []], "lang", ["subexpr", "@mut", [["get", "this.lang", ["loc", [null, [3, 9], [3, 18]]]]], [], []]], ["loc", [null, [1, 0], [4, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
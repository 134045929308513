define("vimme/templates/teacher/builder/paper-assignments/paper-assignment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/builder/paper-assignments/paper-assignment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quiz-question-list", [], ["quizQuestions", ["subexpr", "@mut", [["get", "quizQuestions", ["loc", [null, [3, 22], [3, 35]]]]], [], []], "categories", ["subexpr", "@mut", [["get", "visibleCategories", ["loc", [null, [4, 19], [4, 36]]]]], [], []], "difficulties", ["subexpr", "@mut", [["get", "model.difficulties", ["loc", [null, [5, 21], [5, 39]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [6, 15], [6, 27]]]]], [], []], "selectedCategories", ["subexpr", "@mut", [["get", "selectedCategories", ["loc", [null, [7, 27], [7, 45]]]]], [], []], "selectedDifficulties", ["subexpr", "@mut", [["get", "selectedDifficulties", ["loc", [null, [8, 29], [8, 49]]]]], [], []], "selectedGrades", ["subexpr", "@mut", [["get", "selectedGrades", ["loc", [null, [9, 23], [9, 37]]]]], [], []], "selected", ["subexpr", "@mut", [["get", "model.quiz.quizQuestions", ["loc", [null, [10, 17], [10, 41]]]]], [], []], "isSelecting", true, "page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [12, 13], [12, 17]]]]], [], []], "sort", ["subexpr", "@mut", [["get", "sort", ["loc", [null, [13, 13], [13, 17]]]]], [], []], "hide", "hideAdd"], ["loc", [null, [2, 4], [15, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/builder/paper-assignments/paper-assignment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quiz-editor", [], ["quiz", ["subexpr", "@mut", [["get", "model.quiz", ["loc", [null, [18, 13], [18, 23]]]]], [], []], "categories", ["subexpr", "@mut", [["get", "model.categories", ["loc", [null, [19, 19], [19, 35]]]]], [], []], "difficulties", ["subexpr", "@mut", [["get", "model.difficulties", ["loc", [null, [20, 21], [20, 39]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [21, 15], [21, 27]]]]], [], []], "addVisible", ["subexpr", "@mut", [["get", "addQuestions", ["loc", [null, [22, 19], [22, 31]]]]], [], []], "cancel", "delete", "save", "save", "showAdd", "showAdd"], ["loc", [null, [17, 4], [26, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 7
          }
        },
        "moduleName": "vimme/templates/teacher/builder/paper-assignments/paper-assignment.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "addQuestions", ["loc", [null, [1, 6], [1, 18]]]]], [], 0, 1, ["loc", [null, [1, 0], [27, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define('vimme/controllers/admin/builder/paper-assignment-questions/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    index: Ember.inject.controller('admin.builder.paper-assignment-questions.index'),

    isSaving: false,

    actions: {
      cancel: function cancel() {
        this.store.all('category').filterBy('id', null).invoke('deleteRecord');
        this.store.all('quizQuestion').filterBy('id', null).invoke('deleteRecord');
        this.transitionToRoute('admin.builder.paper-assignment-questions');
      },
      save: function save(quizQuestion) {
        var _this = this;

        this.set('isSaving', true);

        quizQuestion.save().then(function (quizQuestion) {
          _this.set('isSaving', false);
          _this.get('index').notifyPropertyChange('quizQuestions');
          _this.transitionToRoute('admin.builder.paper-assignment-questions.paper-assignment-question', quizQuestion.get('id'));
        }).catch(function () {
          _this.set('isSaving', false);
        });
      }
    }
  });
});
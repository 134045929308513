define('vimme/models/assignment-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    order: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    imagePath: _emberData.default.attr('string'),
    questionType: _emberData.default.attr('string'),

    allowRuler: _emberData.default.attr('boolean'),
    allowProtractor: _emberData.default.attr('boolean'),
    allowCalculator: _emberData.default.attr('boolean'),
    allowScientificCalculator: _emberData.default.attr('boolean'),

    number: Ember.computed('order', function () {
      return this.get('order') + 1;
    }),

    assignment: _emberData.default.belongsTo('assignment', { async: true }),

    assignmentQuestionAnswers: _emberData.default.hasMany('assignment-question-answer'),

    isMultipleQuestion: Ember.computed('questionType', function () {
      return this.get('questionType') === 'multiple';
    }),

    isNormalQuestion: Ember.computed('questionType', function () {
      return this.get('questionType') === 'normal' || !this.get('questionType');
    }),

    isTextQuestion: Ember.computed('questionType', function () {
      return this.get('questionType') === 'text';
    })
  });
});
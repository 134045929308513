define('vimme/components/number-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: 'number',

    didInsertElement: function didInsertElement() {
      this.$().keypress(function (e) {
        if (e.keyCode !== 46 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) {
          return false;
        }
      });
    }
  });
});
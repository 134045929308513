define('vimme/components/app-pages/student/assignments/assignment/answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    selectedAnswers: Ember.computed(function () {
      return [];
    }),
    answer: undefined,

    isSelected: Ember.computed('selectedAnswers.@each.id', 'answer.id', function () {
      var _this = this;

      return !!this.get('selectedAnswers').find(function (a) {
        return a.get('id') === _this.get('answer.id');
      });
    })
  });
});
define("vimme/components/app-pages/student/assignments/assignment-wrapper/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/student/assignments/assignment-wrapper/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/student/assignments/assignment-review", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.studentQuestions", ["loc", [null, [3, 25], [3, 46]]]]], [], []], "assignment", ["subexpr", "@mut", [["get", "this.assignment", ["loc", [null, [4, 19], [4, 34]]]]], [], []], "scheduledAssignment", ["subexpr", "@mut", [["get", "this.scheduledAssignment", ["loc", [null, [5, 28], [5, 52]]]]], [], []], "studentAssignment", ["subexpr", "@mut", [["get", "this.studentAssignment", ["loc", [null, [6, 26], [6, 48]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [7, 17], [7, 30]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.questions", ["loc", [null, [8, 18], [8, 32]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.number", ["loc", [null, [9, 15], [9, 26]]]]], [], []]], ["loc", [null, [2, 4], [10, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 0
              },
              "end": {
                "line": 22,
                "column": 0
              }
            },
            "moduleName": "vimme/components/app-pages/student/assignments/assignment-wrapper/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "app-pages/student/assignments/assignment", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.studentQuestions", ["loc", [null, [13, 25], [13, 46]]]]], [], []], "assignment", ["subexpr", "@mut", [["get", "this.assignment", ["loc", [null, [14, 19], [14, 34]]]]], [], []], "scheduledAssignment", ["subexpr", "@mut", [["get", "this.scheduledAssignment", ["loc", [null, [15, 28], [15, 52]]]]], [], []], "studentAssignment", ["subexpr", "@mut", [["get", "this.studentAssignment", ["loc", [null, [16, 26], [16, 48]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [17, 17], [17, 30]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.questions", ["loc", [null, [18, 18], [18, 32]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.number", ["loc", [null, [19, 15], [19, 26]]]]], [], []], "isFinishDialogVisible", ["subexpr", "@mut", [["get", "this.isFinishDialogVisible", ["loc", [null, [20, 30], [20, 56]]]]], [], []]], ["loc", [null, [12, 4], [21, 6]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/student/assignments/assignment-wrapper/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.scheduledAssignment.isTakable", ["loc", [null, [11, 10], [11, 44]]]]], [], 0, null, ["loc", [null, [11, 0], [22, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 7
          }
        },
        "moduleName": "vimme/components/app-pages/student/assignments/assignment-wrapper/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "this.studentAssignment.isComplete", ["loc", [null, [1, 11], [1, 44]]]], ["get", "this.isFinishDialogHidden", ["loc", [null, [1, 45], [1, 70]]]]], [], ["loc", [null, [1, 6], [1, 71]]]]], [], 0, 1, ["loc", [null, [1, 0], [22, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define('vimme/components/vimme-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['question-answer', 'is-selectable'],
    classNameBindings: ['isSelected', 'isUnsavable', 'isMutedSelection'],

    answer: null,
    studentAnswers: null,
    isSavable: null,
    isMutedSelection: false,

    isUnsavable: Ember.computed.not('isSavable'),

    studentAnswerIds: Ember.computed.mapBy('studentAnswers', 'id'),

    isSelected: function () {
      var studentAnswerIds = this.get('studentAnswerIds');

      if (studentAnswerIds && studentAnswerIds.get('length') > 0) {
        if (studentAnswerIds.indexOf(this.get('answer.id')) >= 0) {
          return true;
        }
      }

      return false;
    }.property('studentAnswerIds.[]'),

    click: function click() {
      if (this.get('isSavable')) {
        this.sendAction('action', this.get('answer'), this.get('isSelected'));
      }
    }
  });
});
define('vimme/routes/admin/builder/tests/test/questions/new', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var question = this.store.createRecord('question', {
        test: this.modelFor('admin.builder.tests.test').test,
        questionType: 'normal'
      });
      var standards = this.store.peekAll('standard');
      return Ember.RSVP.hash({
        question: question,
        standards: standards
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('question').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
define('vimme/routes/teacher/testing-calendar', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      return this.modelFor('teacher');
    }
  });
});
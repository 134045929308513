define('vimme/components/app-pages/teacher/testing-calendar/component', ['exports', 'vimme/utils/date-in-range'], function (exports, _dateInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    store: null,
    school: Ember.computed(function () {
      return { isTrial: false, isSubscription: false, teacherCanScheduleTests: false };
    }),
    scheduledTests: Ember.computed(function () {
      return [];
    }),
    classrooms: Ember.computed(function () {
      return [];
    }),
    tests: Ember.computed(function () {
      return [];
    }),

    selectedDate: moment().startOf('day').toDate(),

    selectedPacingGuide: null,

    isScheduling: false,
    isUnscheduling: false,

    filterTests: function filterTests(tests, scheduledTests, classroom) {
      if (!classroom) return tests;
      var filteredScheduledTests = scheduledTests.filter(function (st) {
        return st.get('classroom.id') === classroom.get('id');
      });
      return tests.filter(function (test) {
        return test.get('course.id') === classroom.get('course.id') && !filteredScheduledTests.find(function (st) {
          return st.get('test.id') === test.get('id');
        });
      });
    },


    filteredTests: Ember.computed('tests.@each.course.id', 'selectedClassroom.course.id', 'scheduledTests.@each.classroom.id', function () {
      return this.filterTests(this.get('tests'), this.get('scheduledTests'), this.get('selectedClassroom'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var classroom = this.get('classrooms.firstObject');
      this.set('selectedClassroom', classroom);
      this.set('selectedTest', this.get('filteredTests.firstObject'));
      var guides = this.get('pacingGuides') || [];
      this.set('selectedPacingGuide', guides[0]);
    },


    selectedClassroom: undefined,
    selectedTest: undefined,

    resetSelectedScheduledTest: Ember.observer('selectedDate', function () {
      this.set('_selectedScheduledTest', undefined);
    }),

    _selectedScheduledTest: undefined,

    canPauseCalculator: Ember.computed('selectedScheduledTest.test.grade.id', function () {
      return parseInt(this.get('selectedScheduledTest.test.grade.id'), 10) >= 6;
    }),

    isNew: Ember.computed('newScheduledTest', 'selectedScheduledTest', function () {
      return this.get('newScheduledTest.id') === this.get('selectedScheduledTest.id');
    }),

    newScheduledTest: Ember.computed('selectedDate', function () {
      return {
        id: 'new',
        startDate: this.get('selectedDate'),
        endDate: moment(this.get('selectedDate')).endOf('day').toDate()
      };
    }),

    selectedScheduledTest: Ember.computed('selectedScheduledTests.[]', '_selectedScheduledTest', 'newScheduledTest', function () {
      return this.get('_selectedScheduledTest') || this.get('selectedScheduledTests.firstObject') || this.get('newScheduledTest');
    }),

    selectedScheduledTests: Ember.computed('scheduledTests.@each.{startDate,endDate}', 'selectedDate', function () {
      var _this = this;

      return this.get('scheduledTests').sortBy('test.grade.id').filter(function (test) {
        return !!_this.get('selectedDate') && (0, _dateInRange.default)(_this.get('selectedDate'), test.get('startDate'), test.get('endDate'));
      });
    }),

    isValid: Ember.computed('selectedScheduledTest.{startDate,endDate}', function () {
      if (!this.get('selectedScheduledTest.startDate') || !this.get('selectedScheduledTest.endDate')) return false;
      if (this.get('selectedScheduledTest.startDate') >= this.get('selectedScheduledTest.endDate')) return false;
      return true;
    }),

    isStartDateDisabled: Ember.computed('selectedScheduledTest.studentTests.length', function () {
      return !!this.get('selectedScheduledTest.studentTests.length');
    }),

    actions: {
      selectDate: function selectDate(selectedDate) {
        this.setProperties({ selectedDate: selectedDate });
      },
      selectTab: function selectTab(_selectedScheduledTest) {
        this.setProperties({ _selectedScheduledTest: _selectedScheduledTest });
      },
      selectClassroom: function selectClassroom(selectedClassroom) {
        this.setProperties({ selectedClassroom: selectedClassroom });
        this.set('selectedTest', this.get('filteredTests.firstObject'));
      },
      selectTest: function selectTest(selectedTest) {
        this.setProperties({ selectedTest: selectedTest });
      },
      scheduleTest: function scheduleTest() {
        var _this2 = this;

        var selectedScheduledTest = this.get('selectedScheduledTest');
        var scheduledTest = this.get('isNew') ? this.get('store').createRecord('scheduledTest') : selectedScheduledTest;

        if (this.get('isNew')) {
          scheduledTest.set('startDate', selectedScheduledTest.startDate);
          scheduledTest.set('endDate', selectedScheduledTest.endDate);
          scheduledTest.set('test', this.get('selectedTest'));
          scheduledTest.set('classroom', this.get('selectedClassroom'));
        }

        this.set('isScheduling', true);

        scheduledTest.save().then(function (scheduledTest) {
          _this2.set('_selectedScheduledTest', scheduledTest);
          _this2.set('isScheduling', false);
          _this2.set('selectedTest', _this2.get('tests.firstObject'));
        }, function () {
          _this2.set('isScheduling', false);
          if (_this2.get('selectedScheduledTest.isNew')) {
            _this2.get('selectedScheduledTest').deleteRecord();
          }
          alert('There was an error scheduling your test!');
        });
      },
      unscheduleTest: function unscheduleTest() {
        var _this3 = this;

        this.set('isUnscheduling', true);

        var selectedScheduledTest = this.get('selectedScheduledTest');

        selectedScheduledTest.destroyRecord().then(function () {
          _this3.resetSelectedScheduledTest();
          _this3.set('isUnscheduling', false);
        }, function () {
          _this3.set('isUnscheduling', false);
          alert('There was an error unscheduling your test!');
        });
      },
      gotoTest: function gotoTest() {
        this.sendAction('gotoTest', this.get('selectedScheduledTest.test.id'));
      },
      toggleCalculatorPaused: function toggleCalculatorPaused() {
        var scheduledTest = this.get('selectedScheduledTest');

        if (scheduledTest) {
          scheduledTest.toggleProperty('isCalculatorPaused');
          scheduledTest.save().catch(function () {
            scheduledTest.toggleProperty('isCalculatorPaused');
          });
        }
      },
      togglePaused: function togglePaused() {
        var scheduledTest = this.get('selectedScheduledTest');

        if (scheduledTest) {
          scheduledTest.toggleProperty('isPaused');
          scheduledTest.save().catch(function () {
            scheduledTest.toggleProperty('isPaused');
          });
        }
      },
      toggleRetake: function toggleRetake() {
        var scheduledTest = this.get('selectedScheduledTest');

        if (scheduledTest) {
          var maxAttempts = scheduledTest.get('maxAttempts');
          scheduledTest.set('maxAttempts', maxAttempts === 1 ? 2 : 1);
          scheduledTest.save().catch(function (err) {
            console.error(err);
            scheduledTest.set('maxAttempts', maxAttempts === 1 ? 1 : 2);
          });
        }
      },
      toggleReview: function toggleReview() {
        var scheduledTest = this.get('selectedScheduledTest');

        if (scheduledTest) {
          scheduledTest.toggleProperty('isReviewable');
          scheduledTest.save().catch(function () {
            scheduledTest.toggleProperty('isReviewable');
          });
        }
      }
    }
  });
});
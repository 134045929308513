define('vimme/components/vimme-video/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-overlay'],

    // input
    video: null,

    videoId: Ember.computed('video', function () {
      var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      var parseUrl = regExp.exec(this.get('video'));
      return parseUrl[5];
    }),

    url: Ember.computed('videoId', function () {
      var id = this.get('videoId');
      if (id) {
        return 'https://player.vimeo.com/video/' + id + '?title=0&byline=0&portrait=0&autoplay=1&api=1';
      }
    }),

    startFroogaloop: Ember.on('didInsertElement', function () {
      var iframe = this.$('iframe')[0];
      var player = new Vimeo.Player(iframe);
      var that = this;
      var onFinish = function onFinish() {
        that.closeVideo();
      };
      player.on('finish', onFinish);
      this.set('player', player);
    }),

    closeVideo: function closeVideo() {
      var _this = this;

      this.get('player').off('finish');
      setTimeout(function () {
        _this.close();
      });
    },

    actions: {
      close: function close() {
        this.closeVideo();
      }
    }
  });
});
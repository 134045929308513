define('vimme/models/student-assignment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isComplete: _emberData.default.attr('boolean'),
    score: _emberData.default.attr('number'),

    assignment: _emberData.default.belongsTo('assignment', { async: true }),
    scheduledAssignment: _emberData.default.belongsTo('scheduledAssignment', { async: true }),
    student: _emberData.default.belongsTo('student', { async: true }),

    studentAssignmentQuestions: _emberData.default.hasMany('studentAssignmentQuestion', { async: true })
  });
});
define('vimme/controllers/teacher/reports/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'print', 'week'],

    classroom: null,
    print: false,
    week: null,

    actions: {
      gotoTestReviewC: function gotoTestReviewC() {
        this.send.apply(this, ['gotoTestReview'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});
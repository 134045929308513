define('vimme/components/vimme-ruler/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-ruler'],

    classNameBindings: ['rulerType'],

    rulerType: 'in',

    grade: 6,

    segments: 6,

    rotating: false,

    degree: 0,
    mouseDegree: null,

    deltaX: 0,
    deltaY: 0,
    tempDeltaX: 0,
    tempDeltaY: 0,

    centerX: 0,
    centerY: 0,

    setOrigin: function () {
      this.set('deltaX', 0);
      this.set('deltaY', 0);
      this.set('tempDeltaX', 0);
      this.set('tempDeltaY', 0);
      this.set('degree', 0);
      this.set('mouseDegree', null);
      this.set('centerX', this.$().offset().left + 12);
      this.set('centerY', this.$().height() + this.$().offset().top);
    }.on('didInsertElement'),

    rulerImage: function () {
      var rulerType = this.get('rulerType'),
          segments = this.get('segments'),
          cmSegments = segments * 2,
          ruler = 'ruler';

      if (parseInt(this.get('grade'), 10) === 2) {
        ruler = 'ruler_whole';
      }

      if (rulerType === 'in') {
        if (segments > 10) {
          segments = 10;
        } else if (segments < 2) {
          segments = 2;
        }

        return this.assets.resolve('assets/images/' + ruler + '_in' + '_' + segments + '.png');
      } else {
        if (cmSegments > 24) {
          cmSegments = 24;
        } else if (cmSegments < 6) {
          cmSegments = 6;
        }

        return this.assets.resolve('assets/images/' + ruler + '_cm' + '_' + cmSegments + '.png');
      }
    }.property('rulerType', 'segments'),

    disableGrow: function () {
      var rulerType = this.get('rulerType'),
          segments = this.get('segments'),
          cmSegments = segments * 2;

      return rulerType === 'in' && segments >= 10 || rulerType === 'cm' && cmSegments >= 24;
    }.property('rulerType', 'segments'),

    disableShrink: function () {
      var rulerType = this.get('rulerType'),
          segments = this.get('segments'),
          cmSegments = segments * 2;

      return rulerType === 'in' && segments <= 2 || rulerType === 'cm' && cmSegments <= 6;
    }.property('rulerType', 'segments'),

    actions: {
      shrink: function shrink() {
        var segments = this.get('segments');

        this.set('segments', --segments);
      },

      grow: function grow() {
        var segments = this.get('segments');

        this.set('segments', ++segments);
      },

      toggleRotate: function toggleRotate() {
        this.toggleProperty('rotating');
      },

      resetRotate: function resetRotate() {
        var deltaX = this.get('deltaX'),
            deltaY = this.get('deltaY');

        this.set('degree', 0);
        this.set('rotating', false);

        this.$().css({
          '-webkit-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(0deg)',
          '-moz-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(0deg)',
          '-o-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(0deg)',
          '-ms-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(0deg)',
          transform: 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(0deg)'
        });
      }
    },

    gestures: {
      dragstart: function dragstart() {
        this.set('mouseDegree', null);
      },

      drag: function drag(event) {
        event.gesture.preventDefault();

        var oldDegree = this.get('degree'),
            oldMouseDegree = this.get('mouseDegree'),
            deltaX = this.get('deltaX'),
            deltaY = this.get('deltaY'),
            centerX = this.get('centerX'),
            centerY = this.get('centerY'),
            rad,
            degree,
            mouseDegree,
            mouseDiff;

        if (!this.get('rotating')) {
          deltaX = deltaX + event.gesture.deltaX;
          deltaY = deltaY + event.gesture.deltaY;

          degree = oldDegree;
        } else {
          centerX = centerX + deltaX;
          centerY = centerY + deltaY;

          rad = Math.atan2(event.gesture.center.pageX - centerX, event.gesture.center.pageY - centerY);

          mouseDegree = rad * (180 / Math.PI * -1) + 180;

          if (mouseDegree % 360 < 0) {
            mouseDegree = 360 + mouseDegree % 360;
          }

          if (oldMouseDegree === null) {
            oldMouseDegree = mouseDegree;
          }

          mouseDiff = mouseDegree - oldMouseDegree;
          degree = oldDegree + mouseDiff;

          this.set('degree', degree);
          this.set('mouseDegree', mouseDegree);
        }

        this.$().css({
          '-webkit-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(' + degree + 'deg)',
          '-moz-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(' + degree + 'deg)',
          '-o-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(' + degree + 'deg)',
          '-ms-transform': 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(' + degree + 'deg)',
          transform: 'translate(' + deltaX + 'px,' + deltaY + 'px) rotate(' + degree + 'deg)'
        });

        this.set('tempDeltaX', deltaX);
        this.set('tempDeltaY', deltaY);
      },

      dragend: function dragend() {
        this.set('deltaX', this.get('tempDeltaX'));
        this.set('deltaY', this.get('tempDeltaY'));
      }
    }
  });
});
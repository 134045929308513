define('vimme/routes/admin/reports', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'admin.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('admin.reports.week');
        } else {
          this.transitionTo('admin.reports.week');
        }
      }
    },
    model: function model() {
      var courses = this.store.findAll('course');
      var grades = this.store.findAll('grade');
      var schools = this.store.findAll('school');
      var schoolYears = this.store.findAll('schoolYear');
      var weeks = this.store.findAll('week');

      return Ember.RSVP.hash({
        courses: courses,
        grades: grades,
        schools: schools,
        schoolYears: schoolYears,
        weeks: weeks
      });
    }
  });
});
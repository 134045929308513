define("vimme/components/growth-courses-report/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 25,
                "column": 12
              }
            },
            "moduleName": "vimme/components/growth-courses-report/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            dom.setAttribute(el2, "style", "width: 67.667%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "width: 33.333%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element4, 'class');
            morphs[2] = dom.createMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["content", "average.course.title", ["loc", [null, [22, 61], [22, 85]]]], ["attribute", "class", ["concat", ["cell ", ["subexpr", "color-diff", [["get", "average.diff", ["loc", [null, [23, 50], [23, 62]]]]], [], ["loc", [null, [23, 37], [23, 64]]]]]]], ["inline", "append-percent", [["subexpr", "diff", [["get", "average.diff", ["loc", [null, [23, 112], [23, 124]]]]], [], ["loc", [null, [23, 106], [23, 125]]]]], [], ["loc", [null, [23, 89], [23, 127]]]]],
          locals: ["average"],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 12
              },
              "end": {
                "line": 51,
                "column": 12
              }
            },
            "moduleName": "vimme/components/growth-courses-report/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "width: 50%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "width: 50%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createAttrMorph(element2, 'class');
            morphs[3] = dom.createMorphAt(element2, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "color", [["get", "average.from", ["loc", [null, [48, 48], [48, 60]]]], ["get", "average.course.grade.id", ["loc", [null, [48, 61], [48, 84]]]]], [], ["loc", [null, [48, 40], [48, 86]]]]]]], ["inline", "append-percent", [["get", "average.from", ["loc", [null, [48, 124], [48, 136]]]]], [], ["loc", [null, [48, 107], [48, 138]]]], ["attribute", "class", ["concat", ["cell ", ["subexpr", "color", [["get", "average.to", ["loc", [null, [49, 48], [49, 58]]]], ["get", "average.course.grade.id", ["loc", [null, [49, 59], [49, 82]]]]], [], ["loc", [null, [49, 40], [49, 84]]]]]]], ["inline", "append-percent", [["get", "average.to", ["loc", [null, [49, 122], [49, 132]]]]], [], ["loc", [null, [49, 105], [49, 134]]]]],
          locals: ["average"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "vimme/components/growth-courses-report/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-general column");
          dom.setAttribute(el1, "style", "width: 60%");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-general-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "style", "width: 67.667%");
          var el5 = dom.createTextNode("\n                    Course\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "style", "width: 33.333%");
          var el5 = dom.createTextNode("\n                    Change\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-detailed column");
          dom.setAttribute(el1, "style", "width: 40%");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-detailed-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "style", "width: 50%");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "style", "width: 50%");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var element9 = dom.childAt(fragment, [3, 1]);
          var element10 = dom.childAt(element9, [1]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element10, [3]);
          var morphs = new Array(12);
          morphs[0] = dom.createAttrMorph(element7, 'class');
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createAttrMorph(element8, 'class');
          morphs[3] = dom.createElementMorph(element8);
          morphs[4] = dom.createMorphAt(element5, 3, 3);
          morphs[5] = dom.createAttrMorph(element11, 'class');
          morphs[6] = dom.createElementMorph(element11);
          morphs[7] = dom.createMorphAt(element11, 1, 1);
          morphs[8] = dom.createAttrMorph(element12, 'class');
          morphs[9] = dom.createElementMorph(element12);
          morphs[10] = dom.createMorphAt(element12, 1, 1);
          morphs[11] = dom.createMorphAt(element9, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["cell-header ", ["subexpr", "if", [["subexpr", "includes", [["get", "currentSort", ["loc", [null, [6, 54], [6, 65]]]], "course.id"], [], ["loc", [null, [6, 44], [6, 78]]]], "selected"], [], ["loc", [null, [6, 39], [6, 91]]]]]]], ["element", "action", ["sort", "course.id"], [], ["loc", [null, [8, 20], [8, 49]]]], ["attribute", "class", ["concat", ["cell-header ", ["subexpr", "if", [["subexpr", "includes", [["get", "currentSort", ["loc", [null, [13, 54], [13, 65]]]], "diff"], [], ["loc", [null, [13, 44], [13, 73]]]], "selected"], [], ["loc", [null, [13, 39], [13, 86]]]]]]], ["element", "action", ["sort", "diff"], [], ["loc", [null, [15, 20], [15, 44]]]], ["block", "each", [["get", "sortedAverages", ["loc", [null, [20, 20], [20, 34]]]]], [], 0, null, ["loc", [null, [20, 12], [25, 21]]]], ["attribute", "class", ["concat", ["cell-header ", ["subexpr", "if", [["subexpr", "includes", [["get", "currentSort", ["loc", [null, [32, 58], [32, 69]]]], "from"], [], ["loc", [null, [32, 48], [32, 77]]]], "selected"], [], ["loc", [null, [32, 43], [32, 90]]]]]]], ["element", "action", ["sort", "from"], [], ["loc", [null, [34, 24], [34, 48]]]], ["content", "from.longName", ["loc", [null, [36, 24], [36, 41]]]], ["attribute", "class", ["concat", ["cell-header ", ["subexpr", "if", [["subexpr", "includes", [["get", "currentSort", ["loc", [null, [39, 58], [39, 69]]]], "to"], [], ["loc", [null, [39, 48], [39, 75]]]], "selected"], [], ["loc", [null, [39, 43], [39, 88]]]]]]], ["element", "action", ["sort", "to"], [], ["loc", [null, [41, 24], [41, 46]]]], ["content", "to.longName", ["loc", [null, [43, 24], [43, 39]]]], ["block", "each", [["get", "sortedAverages", ["loc", [null, [46, 20], [46, 34]]]]], [], 1, null, ["loc", [null, [46, 12], [51, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 11
          }
        },
        "moduleName": "vimme/components/growth-courses-report/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isLoading", ["loc", [null, [1, 10], [1, 19]]]]], [], 0, null, ["loc", [null, [1, 0], [54, 11]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
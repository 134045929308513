define('vimme/routes/teacher/builder/paper-assignments/paper-assignment', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        categories: this.modelFor('teacher.builder.paper-assignments').categories,
        difficulties: this.modelFor('teacher.builder.paper-assignments').difficulties,
        grades: this.modelFor('teacher.builder.paper-assignments').grades,
        quiz: this.store.find('quiz', params.paper_assignment_id),
        teacher: this.modelFor('teacher.builder.paper-assignments').teacher
      });
    },
    resetController: function resetController(controller) {
      var quiz = controller.get('model.quiz');
      var quizQuestions = quiz.get('quizQuestions');
      quiz.rollback();
      quizQuestions.reload();
    }
  });
});
define('vimme/models/classroom-leader', ['exports', 'ember-data', 'vimme/utils/round'], function (exports, _emberData, _round) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    score: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),

    classroom: _emberData.default.belongsTo('classroom', { async: true }),
    school: _emberData.default.belongsTo('school', { async: true }),
    teacher: _emberData.default.belongsTo('teacher', { async: true }),

    percent: Ember.computed('score', 'total', function () {
      var score = this.get('score');
      var total = this.get('total');

      return score / total * 100;
    }),

    roundedPercent: Ember.computed('percent', function () {
      return (0, _round.default)(this.get('percent'));
    }),

    formattedPercent: Ember.computed('roundedPercent', function () {
      return this.get('roundedPercent') + '%';
    })
  });
});
define('vimme/controllers/school-admin/reports/mathbots/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'grade'],

    classroom: null,
    grade: null,

    actions: {
      setGrade: function setGrade(grade) {
        this.setProperties({
          classroom: null,
          grade: grade ? grade.get('id') : null
        });
      },
      setClassroom: function setClassroom(classroom) {
        this.set('classroom', classroom ? classroom.get('id') : null);
      }
    }
  });
});
define('vimme/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'vimme/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.ActiveModelAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    coalesceFindRequests: true,
    host: _environment.default.API.host,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    pathForType: function pathForType(type) {
      var decamelized = Ember.String.decamelize(type);
      var dasherized = Ember.String.dasherize(decamelized);

      return Ember.String.pluralize(dasherized);
    }
  });
});
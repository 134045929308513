define('vimme/components/app-pages/teacher/reports/assignment/average-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    question: null,
    studentQuestions: Ember.computed(function () {
      return [];
    }),

    matchedQuestions: Ember.computed('studentQuestions.assignmentQuestion.id', 'question.id', function () {
      var _this = this;

      return this.get('studentQuestions').filter(function (sq) {
        return sq.get('assignmentQuestion.id') === _this.get('question.id');
      });
    }),

    correctQuestions: Ember.computed.filterBy('matchedQuestions', 'isCorrect')
  });
});
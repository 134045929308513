define("vimme/templates/teacher/questions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 3,
              "column": 124
            }
          },
          "moduleName": "vimme/templates/teacher/questions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Prev");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 8
            },
            "end": {
              "line": 5,
              "column": 106
            }
          },
          "moduleName": "vimme/templates/teacher/questions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Next");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 9,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/questions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["btn btn-icon-only btn-test-toggle-hide-answers ", ["subexpr", "if", [["get", "hideAnswers", ["loc", [null, [8, 105], [8, 116]]]], "icon-eye", "icon-eye-close"], [], ["loc", [null, [8, 100], [8, 146]]]]]]], ["element", "action", ["toggleHideAnswers"], [], ["loc", [null, [8, 15], [8, 45]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 16
                },
                "end": {
                  "line": 23,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/teacher/questions.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createTextNode("Protractor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "protractor", ["loc", [null, [22, 65], [22, 75]]]], "active"], [], ["loc", [null, [22, 60], [22, 86]]]]]]], ["element", "action", ["toggleProtractor"], [], ["loc", [null, [22, 23], [22, 52]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 12
              },
              "end": {
                "line": 24,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Ruler: Inches");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Ruler: Centimeters");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(fragment, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createAttrMorph(element3, 'class');
            morphs[3] = dom.createElementMorph(element3);
            morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "rulerIN", ["loc", [null, [19, 58], [19, 65]]]], "active"], [], ["loc", [null, [19, 53], [19, 76]]]]]]], ["element", "action", ["toggleRulerIN"], [], ["loc", [null, [19, 19], [19, 45]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "rulerCM", ["loc", [null, [20, 58], [20, 65]]]], "active"], [], ["loc", [null, [20, 53], [20, 76]]]]]]], ["element", "action", ["toggleRulerCM"], [], ["loc", [null, [20, 19], [20, 45]]]], ["block", "if", [["get", "showProtractor", ["loc", [null, [21, 22], [21, 36]]]]], [], 0, null, ["loc", [null, [21, 16], [23, 23]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 12
              },
              "end": {
                "line": 27,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Calculator");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "calc", ["loc", [null, [26, 55], [26, 59]]]], "active"], [], ["loc", [null, [26, 50], [26, 70]]]]]]], ["element", "action", ["toggleCalc"], [], ["loc", [null, [26, 19], [26, 42]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 8
              },
              "end": {
                "line": 32,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "vimme-ruler", [], ["rulerType", "in", "grade", ["subexpr", "@mut", [["get", "test.grade.id", ["loc", [null, [31, 47], [31, 60]]]]], [], []]], ["loc", [null, [31, 12], [31, 62]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 8
              },
              "end": {
                "line": 35,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "vimme-ruler", [], ["rulerType", "cm", "grade", ["subexpr", "@mut", [["get", "test.grade.id", ["loc", [null, [34, 47], [34, 60]]]]], [], []]], ["loc", [null, [34, 12], [34, 62]]]]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "vimme-protractor", ["loc", [null, [37, 12], [37, 32]]]]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 12
                },
                "end": {
                  "line": 42,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/teacher/questions.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "vimme-calculator", ["loc", [null, [41, 16], [41, 36]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 12
                  },
                  "end": {
                    "line": 44,
                    "column": 12
                  }
                },
                "moduleName": "vimme/templates/teacher/questions.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "vimme-scientific-calculator", ["loc", [null, [43, 16], [43, 47]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 12
                },
                "end": {
                  "line": 44,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/teacher/questions.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "showSciCalc", ["loc", [null, [42, 22], [42, 33]]]]], [], 0, null, ["loc", [null, [42, 12], [44, 12]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 8
              },
              "end": {
                "line": 45,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/questions.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showCalc", ["loc", [null, [40, 18], [40, 26]]]]], [], 0, 1, ["loc", [null, [40, 12], [44, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 4
            },
            "end": {
              "line": 47,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/questions.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "test-tools");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createMorphAt(element4, 2, 2);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "requiresTools", ["loc", [null, [18, 18], [18, 31]]]]], [], 0, null, ["loc", [null, [18, 12], [24, 19]]]], ["block", "if", [["subexpr", "or", [["get", "showCalc", ["loc", [null, [25, 22], [25, 30]]]], ["get", "showSciCalc", ["loc", [null, [25, 31], [25, 42]]]]], [], ["loc", [null, [25, 18], [25, 43]]]]], [], 1, null, ["loc", [null, [25, 12], [27, 19]]]], ["block", "if", [["get", "rulerIN", ["loc", [null, [30, 14], [30, 21]]]]], [], 2, null, ["loc", [null, [30, 8], [32, 15]]]], ["block", "if", [["get", "rulerCM", ["loc", [null, [33, 14], [33, 21]]]]], [], 3, null, ["loc", [null, [33, 8], [35, 15]]]], ["block", "if", [["get", "protractor", ["loc", [null, [36, 14], [36, 24]]]]], [], 4, null, ["loc", [null, [36, 8], [38, 15]]]], ["block", "if", [["get", "calc", ["loc", [null, [39, 14], [39, 18]]]]], [], 5, null, ["loc", [null, [39, 8], [45, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/questions.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "test-header");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "wrap");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "test-title");
        var el4 = dom.createTextNode("Grade ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(": ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "test-progress");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("/");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "btn btn-icon-only btn-test-exit icon-delete");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "wrap test-question");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element7, [5]);
        var element9 = dom.childAt(element6, [7]);
        var element10 = dom.childAt(fragment, [2]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element7, 1, 1);
        morphs[2] = dom.createMorphAt(element7, 3, 3);
        morphs[3] = dom.createMorphAt(element8, 0, 0);
        morphs[4] = dom.createMorphAt(element8, 2, 2);
        morphs[5] = dom.createMorphAt(element6, 5, 5);
        morphs[6] = dom.createElementMorph(element9);
        morphs[7] = dom.createMorphAt(element6, 9, 9);
        morphs[8] = dom.createMorphAt(element10, 1, 1);
        morphs[9] = dom.createMorphAt(element10, 3, 3);
        return morphs;
      },
      statements: [["block", "link-to", ["teacher.questions.question", ["get", "previousIndex", ["loc", [null, [3, 48], [3, 61]]]]], ["class", "btn btn-icon-left icon-prev btn-test-header-prev"], 0, null, ["loc", [null, [3, 8], [3, 136]]]], ["content", "test.grade.id", ["loc", [null, [4, 37], [4, 54]]]], ["content", "test.title", ["loc", [null, [4, 56], [4, 70]]]], ["content", "currentIndex", ["loc", [null, [4, 99], [4, 115]]]], ["content", "length", ["loc", [null, [4, 116], [4, 126]]]], ["block", "link-to", ["teacher.questions.question", ["get", "nextIndex", ["loc", [null, [5, 48], [5, 57]]]]], ["class", "btn icon-next btn-test-header-next"], 1, null, ["loc", [null, [5, 8], [5, 118]]]], ["element", "action", ["exit", "teacher.testing-calendar"], [], ["loc", [null, [6, 11], [6, 55]]]], ["block", "unless", [["get", "isReviewing", ["loc", [null, [7, 18], [7, 29]]]]], [], 2, null, ["loc", [null, [7, 8], [9, 19]]]], ["block", "if", [["subexpr", "or", [["get", "requiresTools", ["loc", [null, [15, 14], [15, 27]]]], ["get", "showCalc", ["loc", [null, [15, 28], [15, 36]]]], ["get", "showSciCalc", ["loc", [null, [15, 37], [15, 48]]]]], [], ["loc", [null, [15, 10], [15, 49]]]]], [], 3, null, ["loc", [null, [15, 4], [47, 11]]]], ["content", "outlet", ["loc", [null, [49, 4], [49, 14]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});
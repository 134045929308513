define('vimme/routes/school-admin/reports/growth', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    queryParams: {
      from: {
        replace: true
      },
      to: {
        replace: true
      },
      course: {
        replace: true
      },
      classroom: {
        replace: true
      }
    },

    model: function model(params) {
      var _modelFor = this.modelFor('school-admin.reports'),
          courses = _modelFor.courses,
          weeks = _modelFor.weeks;

      var _modelFor2 = this.modelFor('school-admin'),
          user = _modelFor2.user;

      var selectedFrom = params.from ? weeks.find(function (week) {
        return week.get('id') === params.from;
      }) : undefined;
      var selectedTo = params.to ? weeks.find(function (week) {
        return week.get('id') === params.to;
      }) : undefined;
      var selectedCourse = params.course ? courses.find(function (course) {
        return course.get('id') === params.course;
      }) : undefined;
      var classrooms = selectedCourse ? this.store.query('classroom', { course: selectedCourse.id }) : undefined;
      var selectedClassroom = params.classroom && selectedCourse ? classrooms.then(function (cs) {
        return cs.find(function (classroom) {
          return classroom.get('id') === params.classroom;
        });
      }) : undefined;
      var students = params.classroom && selectedCourse ? selectedClassroom.then(function (c) {
        return c.get('students');
      }) : undefined;

      return Ember.RSVP.hash({
        params: params,
        weeks: weeks,
        courses: courses,
        selectedCourse: selectedCourse,
        selectedClassroom: selectedClassroom,
        selectedFrom: selectedFrom,
        selectedTo: selectedTo,
        students: students,
        classrooms: classrooms,
        user: user
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedCourse', model.selectedCourse);
      controller.set('selectedClassroom', model.selectedClassroom);
      controller.set('selectedFrom', model.selectedFrom);
      controller.set('selectedTo', model.selectedTo);
      controller.set('students', model.students);
      controller.set('classrooms', model.classrooms);
    }
  });
});
define('vimme/routes/admin/builder/resources/index', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var localCurriculums = this.store.all('curriculum');
      var localWeeks = this.store.all('week');
      var localGrades = this.store.all('grade');
      return Ember.RSVP.hash({
        curriculums: localCurriculums.get('length') > 0 ? localCurriculums : this.store.findAll('curriculum'),
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade')
      });
    }
  });
});
define('vimme/routes/teacher/builder/assignments/assignment', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model(params) {
      var assignment = this.store.find('assignment', params.assignment_id);
      var teacher = this.modelFor('teacher').user;
      var allTeachers = this.store.findAll('teacher');
      var grades = this.store.all('grade');
      var weeks = this.store.all('week');
      var teachers = allTeachers.then(function (teachers) {
        return teachers.filter(function (t) {
          return !t.get('isPlaceholder') && t.get('id') !== teacher.get('id');
        });
      });
      var questions = assignment.then(function (assignment) {
        return assignment.get('assignmentQuestions');
      });
      var scheduledAssignments = assignment.then(function (assignment) {
        return assignment.get('scheduledAssignments');
      });
      return Ember.RSVP.hash({
        assignment: assignment,
        questions: questions,
        scheduledAssignments: scheduledAssignments,
        teacher: teacher,
        teachers: teachers,
        grades: grades,
        weeks: weeks
      });
    }
  });
});
define('vimme/components/vimme-quiz-question-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-quiz-question-list'],

    categories: [],
    difficulties: [],
    grades: [],
    selected: [],
    quizQuestions: [],
    page: 1,

    isSelecting: false,

    selectedCategories: '',
    selectedDifficulties: '',
    selectedGrades: '',
    sort: null,

    localSelectedCategories: [],
    localSelectedDifficulties: null,
    localSeletedGrades: [],

    currentQuizQuestion: null,

    alignTop: 0,
    alignLeft: 0,

    localSelected: [],

    sortType: function () {
      var sort = this.get('sort');
      return this.get('sort.length') && sort[0] === '-' ? sort.slice(1) : sort;
    }.property('sort'),
    sortDir: function () {
      return this.get('sort.length') && this.get('sort')[0] === '-' ? 'desc' : 'asc';
    }.property('sort'),
    sortedByTitle: function () {
      return this.get('sortType') === 'title';
    }.property('sortType'),
    sortedByCategory: function () {
      return this.get('sortType') === 'category';
    }.property('sortType'),
    sortedByDifficulty: function () {
      return this.get('sortType') === 'difficulty';
    }.property('sortType'),
    sortedByGrade: function () {
      return this.get('sortType') === 'grade';
    }.property('sortType'),

    pagination: Ember.computed.alias('quizQuestions.meta.pagination'),
    paginationStart: function () {
      var current = this.get('pagination.current_page');
      var per = this.get('pagination.per_page');
      return current * per - per + 1;
    }.property('pagination.{per_page,current_page}'),
    paginationEnd: function () {
      return this.get('paginationStart') + this.get('pagination.count') - 1;
    }.property('paginationStart', 'pagination.count'),

    componentWillMount: function () {
      var _this = this;

      this.set('localSelected', []);

      var selectedCategories = this.get('selectedCategories') || '';
      // let categories = this.get('categories').find((category) => {
      //     return category.get('title').toLowerCase() === selectedCategories.toLowerCase();
      // });
      var categories = selectedCategories.split(',').reduce(function (prev, item) {
        var category = _this.get('categories').find(function (category) {
          return category.get('title').toLowerCase() === item.toLowerCase();
        });
        if (category) {
          prev.push(category);
        }
        return prev;
      }, []);
      var selectedDifficulties = this.get('selectedDifficulties') || '';
      var difficulties = this.get('difficulties').find(function (difficulty) {
        return difficulty.get('title').toLowerCase() === selectedDifficulties.toLowerCase();
      });
      // let difficulties = this.get('selectedDifficulties').split(',').reduce((prev, item) => {
      //     let difficulty = this.get('difficulties').find(difficulty => difficulty.get('title').toLowerCase() === item.toLowerCase());
      //     if (difficulty) {
      //         prev.push(difficulty);
      //     }
      //     return prev;
      // }, []);
      var selectedGrades = this.get('selectedGrades') || '';
      var grades = selectedGrades.split(',').reduce(function (prev, item) {
        var grade = _this.get('grades').find(function (grade) {
          return grade.get('id').toLowerCase() === item.toLowerCase();
        });
        if (grade) {
          prev.push(grade);
        }
        return prev;
      }, []);
      this.set('localSelectedCategories', categories);
      this.set('localSelectedDifficulties', difficulties);
      this.set('localSelectedGrades', grades);
    }.on('willInsertElement'),

    actions: {
      difficultyChanged: function difficultyChanged() {
        // let difficulties = this.get('localSelectedDifficulties').reduce((prev, item) => {
        //     return prev ? prev + ',' + item.get('title') : item.get('title');
        // }, '');
        var difficulties = this.get('localSelectedDifficulties.title');
        difficulties = difficulties ? difficulties.toLowerCase() : null;
        this.set('page', 1);
        this.set('selectedDifficulties', difficulties);
      },
      categoryChanged: function categoryChanged() {
        var categories = this.get('localSelectedCategories').reduce(function (prev, item) {
          return prev ? prev + ',' + item.get('title') : item.get('title');
        }, '');
        // let categories = this.get('localSelectedCategories.title');
        // categories = categories ? categories.toLowerCase() : null;
        this.set('page', 1);
        this.set('selectedCategories', categories.toLowerCase());
      },
      gradeChanged: function gradeChanged() {
        var grades = this.get('localSelectedGrades').reduce(function (prev, item) {
          return prev ? prev + ',' + item.get('id') : item.get('id');
        }, '');
        this.set('page', 1);
        this.set('selectedGrades', grades.toLowerCase());
      },
      togglePreview: function togglePreview(quizQuestion, element) {
        var currentQuizQuestion = this.get('currentQuizQuestion');
        if (currentQuizQuestion && currentQuizQuestion.get('id') === quizQuestion.get('id')) {
          this.set('currentQuizQuestion', null);
        } else {
          var rect = Ember.$(element).parent().get(0).getBoundingClientRect();
          this.set('alignLeft', rect.right);
          this.set('alignTop', rect.top - 1);
          this.set('currentQuizQuestion', quizQuestion);
        }
      },
      add: function add(quizQuestion) {
        this.get('localSelected').addObject(quizQuestion);
      },
      remove: function remove(quizQuestion) {
        this.get('localSelected').removeObject(quizQuestion);
      },
      addSelected: function addSelected() {
        this.get('selected').addObjects(this.get('localSelected'));
        this.set('localSelected', []);
        this.sendAction('hide');
      },
      cancel: function cancel() {
        this.set('localSelected', []);
        this.sendAction('hide');
      },
      prev: function prev() {
        this.set('page', this.get('page') - 1);
      },
      next: function next() {
        this.set('page', this.get('page') + 1);
      },
      sortBy: function sortBy(sort) {
        if (sort === this.get('sortType')) {
          sort = this.get('sortDir') === 'desc' ? sort : '-' + sort;
        }
        this.set('sort', sort);
      }
    }
  });
});
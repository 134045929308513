define('vimme/components/vimme-calculator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-calculator'],

    didInsertElement: function didInsertElement() {
      this.calculator = window.Desmos.FourFunctionCalculator(this.element);
    },
    willDestroyElement: function willDestroyElement() {
      this.calculator.destroy();
    },


    x: 0,
    y: 0,

    tempX: 0,
    tempY: 0,

    gestures: {
      drag: function drag(event) {
        event.gesture.preventDefault();

        var _getProperties = this.getProperties('x', 'y'),
            x = _getProperties.x,
            y = _getProperties.y;

        var _event$gesture = event.gesture,
            deltaX = _event$gesture.deltaX,
            deltaY = _event$gesture.deltaY;


        var tempX = x + deltaX;
        var tempY = y + deltaY;

        this.$().css({
          '-webkit-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-moz-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-o-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-ms-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          transform: 'translate(' + tempX + 'px, ' + tempY + 'px)'
        });

        this.setProperties({
          tempX: tempX,
          tempY: tempY
        });
      },
      dragend: function dragend() {
        this.setProperties({
          x: this.tempX,
          y: this.tempY
        });
      }
    }
  });
});
define('vimme/controllers/password/reset', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),

    isResetSuccess: false,
    isResetError: false,

    passwordConfirmation: null,
    password: null,
    email: null,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this,
            hash = {
          type: 'POST',
          data: {
            email: this.get('email'),
            password: this.get('password'),
            password_confirmation: this.get('passwordConfirmation'),
            token: this.get('model.token')
          }
        };

        this.set('isResetError', false);
        this.set('isResetSuccess', false);

        this.get('ajax').request(_environment.default.API.host + '/password/reset', hash).then(function (data) {
          _this.set('message', data.message);

          _this.set('email', null);
          _this.set('password', null);
          _this.set('passwordConfirmation', null);

          _this.set('isResetError', false);
          _this.set('isResetSuccess', true);
        }).catch(function (xhr) {
          _this.set('message', xhr.responseJSON.message);

          _this.set('isResetError', true);
          _this.set('isResetSuccess', false);
        });
      }
    }
  });
});
define('vimme/models/per-standard-average', ['exports', 'ember-data', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _emberData, _round, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    total: _emberData.default.attr('number'),
    scores: _emberData.default.attr('string'),

    standard: _emberData.default.belongsTo('standard', { async: true }),
    test: _emberData.default.belongsTo('test', { async: true }),

    score: function () {
      var scores = this.get('scores').split(',');

      return scores.reduce(function (prev, curr) {
        return parseInt(prev, 10) + parseInt(curr, 10);
      }) / scores.length;
    }.property('scores'),

    percent: function () {
      var score = this.get('score'),
          total = this.get('total');

      return score / total * 100;
    }.property('score', 'total'),

    roundedPercent: function () {
      return (0, _round.default)(this.get('percent'));
    }.property('percent'),

    formattedPercent: function () {
      return this.get('roundedPercent') + '%';
    }.property('roundedPercent'),

    color: function () {
      var percent = (0, _round.default)(this.get('percent'));
      var grade = this.get('test.grade.id');

      return (0, _color.default)(percent, grade);
    }.property('percent', 'test.grade.id')
  });
});
define("vimme/templates/admin/reports/classroom", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/admin/reports/classroom.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "app-pages/admin/reports/classroom", [], ["courses", ["subexpr", "@mut", [["get", "model.courses", ["loc", [null, [2, 12], [2, 25]]]]], [], []], "course", ["subexpr", "@mut", [["get", "course", ["loc", [null, [3, 11], [3, 17]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [4, 10], [4, 21]]]]], [], []], "week", ["subexpr", "@mut", [["get", "week", ["loc", [null, [5, 9], [5, 13]]]]], [], []], "setCourse", ["subexpr", "action", ["setCourse"], [], ["loc", [null, [6, 14], [6, 34]]]], "setWeek", ["subexpr", "action", ["setWeek"], [], ["loc", [null, [7, 12], [7, 30]]]], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [8, 10], [8, 15]]]]], [], []]], ["loc", [null, [1, 0], [9, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
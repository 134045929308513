define('vimme/components/vimme-multi-audio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    classNames: ['vimme-multi-audio'],

    attributeBindings: ['style'],

    // passed in
    item: null,
    student: null,

    audioEnabled: Ember.computed('student.enableAudio', function () {
      if (!this.get('student')) return true;
      return this.get('student.enableAudio');
    }),
    spanishEnabled: Ember.computed('student.enableAudioEs', function () {
      if (!this.get('student')) return true;
      return this.get('student.enableAudioEs');
    })
  });
});
define('vimme/controllers/admin/test-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      filterTests: function filterTests(autocomplete, term) {
        this.set('searchTerm', term);
      },

      selectTest: function selectTest(test) {
        this.transitionToRoute('admin.test-data.test', test);
      }
    },

    unsorted: function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var tests = this.get('model'),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i');

      if (!term) {
        return null;
      }

      return tests.filter(function (test) {
        return filter.test(test.get('titleWithGrade'));
      });
    }.property('searchTerm'),
    sortProps: ['titleWithGrade'],
    filteredTests: Ember.computed.sort('unsorted', 'sortProps')
  });
});
define('vimme/routes/admin/builder/paper-assignment-questions/index', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade')
      });
    }
  });
});
define('vimme/controllers/teacher/assignment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['studentAssignmentId'],

    studentAssignmentId: null
  });
});
define('vimme/routes/guardian/questions/question', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    model: function model(params) {
      var questions = this.modelFor('guardian.questions').questions,
          length = questions.get('length');

      if (params.question_id > 0 && params.question_id <= length) {
        return questions.objectAt(params.question_id - 1);
      }

      this.replaceWith('guardian.questions.question', 1);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      this.controllerFor('guardian.questions').set('currentQuestion', model);
    }
  });
});
define('vimme/components/app-pages/teacher/reports/week/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    classroomId: '',
    grade: '',
    classrooms: Ember.computed(function () {
      return [];
    }),
    isPrint: false,

    attempt: 'first',

    attempts: Ember.computed(function () {
      return [{ id: 'first', name: 'First Attempt' }, { id: 'all', name: 'All Attempts' }, { id: 'average', name: 'Average Attempts' }, { id: 'retries', name: 'Retry Attempts' }];
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('classroomId')) {
        setTimeout(function () {
          _this.set('classroomId', _this.get('classrooms.firstObject.id') || null);
        });
      }
    },

    classroom: Ember.computed('classrooms.@each.id', 'classroomId', function () {
      var _this2 = this;

      return this.get('classrooms').find(function (classroom) {
        return classroom.get('id') === _this2.get('classroomId');
      });
    }),

    actions: {
      printView: function printView() {
        this.toggleProperty('isPrint');
      }
    }
  });
});
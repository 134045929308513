define("vimme/templates/teacher/builder/assignments", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/teacher/builder/assignments.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/teacher/assignment-builder", [], ["assignments", ["subexpr", "@mut", [["get", "this.model.assignments", ["loc", [null, [2, 16], [2, 38]]]]], [], []], "publicAssignments", ["subexpr", "@mut", [["get", "this.model.publicAssignments", ["loc", [null, [3, 22], [3, 50]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "this.model.grades", ["loc", [null, [4, 11], [4, 28]]]]], [], []], "teacher", ["subexpr", "@mut", [["get", "this.model.teacher", ["loc", [null, [5, 12], [5, 30]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "this.model.weeks", ["loc", [null, [6, 10], [6, 26]]]]], [], []]], ["loc", [null, [1, 0], [7, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
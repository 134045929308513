define('vimme/components/vimme-resource-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tr', 'vimme-resource-row'],
    resource: null
  });
});
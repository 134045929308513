define('vimme/components/app-pages/school-admin/students/student/scores/component', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    // passed in
    studentTests: Ember.computed(function () {
      return [];
    }),

    isDeletingScore: false,

    selectedScore: null,
    noSelectedScore: Ember.computed.not('selectedScore'),

    sortedStudentTests: Ember.computed.sort('studentTests', function (a, b) {
      return parseInt(a.get('test.week.id')) - parseInt(b.get('test.week.id'));
    }),

    actions: {
      setSelected: function setSelected(score) {
        this.set('selectedScore', score);
      },
      showDialogScore: function showDialogScore() {
        this.set('isDeletingScore', true);
      },
      cancelDialogScore: function cancelDialogScore() {
        this.set('isDeletingScore', false);
      },

      deleteScore: function deleteScore(selectedTest) {
        var _this = this;

        selectedTest.destroyRecord().then(function () {
          _this.set('selectedScore', null);
          _this.set('isDeletingScore', false);
        }).catch(function () {
          selectedTest.rollback();
        });
      }
    }
  });
});
define('vimme/routes/student/assignment-calendar', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        assignments: this.modelFor('student').assignments,
        scheduledAssignments: this.modelFor('student').scheduledAssignments,
        studentAssignments: this.modelFor('student').studentAssignments
      });
    }
  });
});
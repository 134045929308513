define('vimme/controllers/application', ['exports', 'npm:jwt-decode', 'vimme/config/environment'], function (exports, _npmJwtDecode, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    hideHeader: false,

    date: new Date().getFullYear(),

    user: { eula: true }, // set by route

    config: _environment.default,

    showBanner: function () {
      return _environment.default.environment !== 'production';
    }.property(),

    token: Ember.computed('session.data.authenticated.token', function () {
      return this.get('session.data.authenticated.token');
    }),

    tokenParam: Ember.computed('token', function () {
      var token = this.get('token');
      if (!token) return '';

      return '?token=' + token;
    }),

    referenceSheet: function () {
      var token = this.get('token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          grade = _ref.grade;

      if (grade && grade >= 4 && grade <= 9) {
        return this.assets.resolve('assets/reference-sheets/istep-grade-' + grade + '-reference-sheet.pdf');
      }
    }.property('token'),

    showEula: function () {
      var role = this.get('role');
      return role && role !== 'Student' && !this.get('user.eula');
    }.property('role', 'user.eula'),

    role: function () {
      var token = this.get('token');

      var _ref2 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref2.role;

      return role;
    }.property('token'),

    name: function () {
      var token = this.get('token');

      var _ref3 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref3.role,
          firstName = _ref3.firstName,
          lastName = _ref3.lastName;

      return role === 'Admin' ? '' : (firstName + '').trim() + ' ' + (lastName + '').trim();
    }.property('token'),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },

      agree: function agree() {
        var user = this.get('user');
        user.set('eula', true);
        user.save();
      }
    }
  });
});
define('vimme/routes/admin/management/school-admins/school-admin', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        schoolAdmin: this.store.find('schoolAdmin', params.school_admin_id),
        schools: this.modelFor('admin.management').schools
      });
    }
  });
});
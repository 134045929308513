define('vimme/controllers/teacher/management/index', ['exports', 'npm:jwt-decode'], function (exports, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    lastName: '',
    middleInitial: '',
    firstName: '',
    username: '',
    password: '',
    schoolStudentId: '',
    classroom: null,

    isSaving: false,

    resetVals: function resetVals() {
      this.set('lastName', '');
      this.set('middleInitial', '');
      this.set('firstName', '');
      this.set('teacher', null);
      this.set('username', '');
      this.set('password', '');
      this.set('schoolStudentId', '');
      this.set('classroom', null);
    },


    isValid: function () {
      var lastName = this.get('lastName');
      var firstName = this.get('firstName');
      var password = this.get('password');
      var classroom = this.get('classroom');

      return !!classroom && !!lastName && !!firstName && !!password && password.length >= 5;
    }.property('classroom', 'lastName', 'firstName', 'password'),

    isUnsavable: function () {
      return !this.get('isValid');
    }.property('isValid'),

    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('isSaving')) {
          this.set('isSaving', true);

          var student = this.store.createRecord('student', {
            lastName: this.get('lastName'),
            middleInitial: this.get('middleInitial'),
            firstName: this.get('firstName'),
            grade: this.get('classroom.grade'),
            teacher: this.get('model.teacher'),
            password: this.get('password'),
            schoolStudentId: this.get('schoolStudentId'),
            classroom: this.get('classroom')
          });

          student.save().then(function (student) {
            _this.resetVals();

            _this.set('isSaving', false);

            _this.transitionToRoute('teacher.management.student', student.get('id'));
            _this.send('refresh');
          }).catch(function () {
            student.deleteRecord();

            alert('Something went wrong creating your student!');

            _this.set('isSaving', false);
          });
        }
      }
    }
  });
});
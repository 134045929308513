define('vimme/routes/school-admin/schedule-tests/day', ['exports', 'vimme/routes/school-admin', 'vimme/utils/date-in-range'], function (exports, _schoolAdmin, _dateInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var scheduledTests = this.modelFor('school-admin.schedule-tests').scheduledTests,
          length = scheduledTests.get('length'),
          date = moment(params.day, 'MM/DD/YYYY').toDate(),
          model = null;

      if (length && moment(date).isValid()) {
        model = scheduledTests.filter(function (scheduledTest) {
          return (0, _dateInRange.default)(date, scheduledTest.get('startDate'), scheduledTest.get('endDate'));
        });

        if (model && model.get('length')) {
          return {
            scheduledTests: model,
            date: date
          };
        }
      }

      this.replaceWith('school-admin.schedule-tests');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('selected', []);
    }
  });
});
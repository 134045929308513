define('vimme/models/classroom', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    isEmpty: _emberData.default.attr('boolean'),
    hasScheduledTests: _emberData.default.attr('boolean'),

    course: _emberData.default.belongsTo('course', { async: true }),
    grade: _emberData.default.belongsTo('grade', { async: true }),
    school: _emberData.default.belongsTo('school', { async: true }),
    teacher: _emberData.default.belongsTo('teacher', { async: true }),

    students: _emberData.default.hasMany('student', { async: true }),

    titleWithBoldSchool: Ember.computed('title', 'school.title', function () {
      return Ember.String.htmlSafe(this.get('title') + ' <strong>(' + this.get('school.title') + ')</strong>');
    }),
    titleWithExtraBoldSchool: Ember.computed('title', 'school.title', function () {
      return Ember.String.htmlSafe(this.get('title') + ' <span class="font-900">(' + this.get('school.title') + ')</span>');
    })
  });
});
define('vimme/routes/school-admin/management/teachers', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        teachers: this.store.findAll('teacher'),
        grades: this.store.findAll('grade'),
        classrooms: this.store.findAll('classroom'),
        courses: this.store.findAll('course')
      });
    }
  });
});
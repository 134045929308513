define("vimme/templates/sub-admin/questions/question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/sub-admin/questions/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "content", ["loc", [null, [2, 13], [2, 20]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "studentQuestion", ["loc", [null, [3, 20], [3, 35]]]]], [], []], "isReviewing", ["subexpr", "@mut", [["get", "isReviewing", ["loc", [null, [4, 16], [4, 27]]]]], [], []]], ["loc", [null, [1, 0], [5, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('vimme/routes/admin/reports/classroom', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _admin.default.extend({
    model: function model() {
      return Ember.RSVP.hash(_extends({}, this.modelFor('admin.reports'), {
        courses: this.store.find('course')
      }));
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedSchoolYear', null);
    }
  });
});
define('vimme/components/growth-classrooms-many-report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    tagName: '',

    authAjax: Ember.inject.service(),

    isLoading: true,

    currentSort: 'school.title',

    averagesSorting: Ember.computed('currentSort', function () {
      if (this.get('currentSort').includes('classroom.title')) {
        return [this.get('currentSort')];
      }
      return [this.get('currentSort'), 'classroom.title'];
    }),
    sortedAverages: Ember.computed.sort('averages', 'averagesSorting'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var course = this.get('course.id');
      var schools = this.get('schools');
      var schoolIds = schools.map(function (s) {
        return s.id;
      }).join(',');
      var from = this.get('from.id');
      var to = this.get('to.id');
      var classrooms = this.get('classrooms');

      this.set('isLoading', true);

      this.get('authAjax').request('/api/growth-classrooms-many-report?course=' + course + '&schools=' + schoolIds + '&from=' + from + '&to=' + to).then(function (resp) {
        var averages = Object.keys(resp).map(function (k) {
          var ks = k.split('.');
          var results = resp[k];
          var school = schools.find(function (s) {
            return s.id === ks[0];
          });
          var classroom = classrooms.find(function (t) {
            return t.id === ks[1];
          });
          return {
            school: school,
            classroom: classroom,
            diff: results.diff,
            from: results.from,
            to: results.to
          };
        });
        _this.set('averages', averages);
        _this.set('isLoading', false);
      });
    },

    actions: {
      sort: function sort(type) {
        var base = 'school.title';
        var currentSort = this.get('currentSort');

        var _currentSort$split = currentSort.split(':'),
            _currentSort$split2 = _slicedToArray(_currentSort$split, 2),
            key = _currentSort$split2[0],
            dir = _currentSort$split2[1];

        if (key === type) {
          if (dir === 'desc') {
            this.set('currentSort', base);
          } else {
            this.set('currentSort', currentSort + ':desc');
          }
        } else {
          this.set('currentSort', type);
        }
      }
    }
  });
});
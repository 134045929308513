define('vimme/routes/school-admin/management/students/student', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var _modelFor = this.modelFor('school-admin.management.students'),
          classrooms = _modelFor.classrooms,
          grades = _modelFor.grades,
          teachers = _modelFor.teachers;

      var _modelFor2 = this.modelFor('school-admin'),
          user = _modelFor2.user;

      return Ember.RSVP.hash({
        student: this.store.find('student', params.student_id),
        classrooms: classrooms,
        grades: grades,
        teachers: teachers,
        school: user.get('school')
      });
    }
  });
});
define('vimme/helpers/get', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.get = get;
  function get(params /*, hash*/) {
    if (!params) return;
    if (!params[0]) return;
    if (!params[1]) return;
    return Ember.get(params[0], params[1]);
  }

  exports.default = Ember.Helper.helper(get);
});
define('vimme/components/vimme-test-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    showAudio: true,

    isUnanswered: computed('studentQuestion.{answers,textAnswer}', 'question.isTextQuestion', function () {
      var isTextQuestion = this.get('question.isTextQuestion');
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.answers');
      return !isTextQuestion && !answers || typeof textAnswer === 'undefined';
    }),

    missingAnswers: computed('studentQuestion.answers', 'question.{answers,isMultipleQuestion}', function () {
      if (!this.get('question.isMultipleQuestion')) {
        return [];
      }
      var correctStudentAnswerIds = this.get('studentQuestion.answers').filterBy('isCorrect').mapBy('id');
      var correctAnswers = this.get('question.answers').filterBy('isCorrect');

      return correctAnswers.filter(function (answer) {
        return !correctStudentAnswerIds.contains(answer.get('id'));
      });
    })
  });
});
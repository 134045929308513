define('vimme/controllers/sub-admin/resources', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['grades', 'weeks', 'page', 'sort'],
    weeks: null,
    grades: null,
    userTypes: 'admin',
    page: 1,
    sort: 'grade',

    resourcesCache: [],
    resources: function (key, value) {
      var _this = this;

      if (arguments.length > 1) {
        this.set('resourcesCache', value);
        return value;
      }
      this.store.find('resource', {
        userTypes: this.get('userTypes'),
        grades: this.get('grades'),
        weeks: this.get('weeks'),
        page: this.get('page'),
        sort: this.get('sort')
      }).then(function (resources) {
        _this.set('resources', resources);
      });
      return this.get('resourcesCache');
    }.property('grades', 'weeks', 'page', 'sort', 'userTypes')
  });
});
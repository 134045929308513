define('vimme/components/vimme-progress-reports-student-test/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // external
    studentTest: null,

    firstMissed: 1,

    scheduledTest: Ember.computed.alias('studentTest.scheduledTest'),

    isSubmitted: Ember.computed.alias('studentTest.isSubmitted'),

    isReviewable: function () {
      return this.get('scheduledTest.isReviewable') && this.get('scheduledTest.isViewable') && this.get('isSubmitted');
    }.property('scheduledTest.{isReviewable,isViewable}', 'isSubmitted'),

    findFirstMissed: function () {
      var isReviewable = this.get('isReviewable'),
          studentQuestions,
          firstMissed = 1,
          _this = this;

      if (isReviewable) {
        studentQuestions = this.get('studentTest.studentQuestions');
      }

      if (studentQuestions) {
        studentQuestions.then(function (studentQuestions) {
          studentQuestions.sortBy('question.id').find(function (question) {
            if (!question.get('isCorrect')) {
              return true;
            }

            firstMissed++;

            return false;
          });

          if (firstMissed > studentQuestions.get('length')) {
            _this.set('firstMissed', 1);
          } else {
            _this.set('firstMissed', firstMissed);
          }
        });
      }
    }.on('init').observes('studentTest', 'isReviewable'),

    actions: {
      gotoReview: function gotoReview(id, queryParam, model) {
        this.send('gotoTestReview', id, this.get('firstMissed'), queryParam, model);
      }
    }
  });
});
define('vimme/routes/student/tests', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('student.testing-calendar');
    }
  });
});
define('vimme/components/vimme-printable-quiz-break', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-printable-quiz-break'],
    classNameBindings: ['break'],

    index: 0,
    total: 0,

    break: function () {
      var index = this.get('index') + 1;
      return index !== this.get('total') && !(index % 6);
    }.property('index')
  });
});
define('vimme/components/app-pages/student/assignment-calendar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    selectedDate: undefined,

    assignments: Ember.computed(function () {
      return [];
    }),
    scheduledAssignments: Ember.computed(function () {
      return [];
    }),
    studentAssignments: Ember.computed(function () {
      return [];
    }),

    filteredScheduledAssignments: Ember.computed('scheduledAssignments.@each.{id,isInPast,isTakable}', 'studentAssignments.@each.scheduledAssignment.id', function () {
      var studentAssignments = this.get('studentAssignments');
      return this.get('scheduledAssignments').filter(function (scheduledAssignment) {
        if (scheduledAssignment.get('isTakable')) return true;
        if (!scheduledAssignment.get('isInPast')) return true;

        return !!studentAssignments.find(function (studentAssignment) {
          return studentAssignment.get('scheduledAssignment.id') === scheduledAssignment.get('id');
        });
      }).sort(function (a, b) {
        if (a.get('isTakable') && !b.get('isTakable')) return -1;
        if (b.get('isTakable') && !a.get('isTakable')) return 1;
        return 0;
      });
    }),

    actions: {
      select: function select(day) {
        this.set('selectedDate', day);
      },
      selectToday: function selectToday() {
        this.set('selectedDate', moment().startOf('day').toDate());
      }
    }
  });
});
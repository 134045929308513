define('vimme/controllers/admin/builder/tests/test/questions/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      saveQuestion: function saveQuestion(question) {
        return question.save().then(function (question) {
          question.get('answers').filterBy('id', null).invoke('deleteRecord');
          return question;
        });
      },
      cancel: function cancel() {
        this.transitionTo('admin.builder.tests.test.questions');
      }
    }
  });
});
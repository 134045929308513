define("vimme/components/vimme-testing-calendar-student-test/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-testing-calendar-student-test/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-testing-calendar-student-test/student-test", [], ["studentTests", ["subexpr", "@mut", [["get", "this.studentTests", ["loc", [null, [6, 21], [6, 38]]]]], [], []], "studentTest", ["subexpr", "@mut", [["get", "studentTest", ["loc", [null, [7, 20], [7, 31]]]]], [], []], "scheduledTest", ["subexpr", "@mut", [["get", "scheduledTest", ["loc", [null, [8, 22], [8, 35]]]]], [], []], "gotoTest", ["subexpr", "@mut", [["get", "this.gotoTest", ["loc", [null, [9, 17], [9, 30]]]]], [], []], "gotoTestReview", ["subexpr", "@mut", [["get", "this.gotoTestReview", ["loc", [null, [10, 23], [10, 42]]]]], [], []]], ["loc", [null, [5, 4], [11, 6]]]]],
        locals: ["studentTest"],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "vimme/components/vimme-testing-calendar-student-test/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "testing-calendar-test-link");
            var el2 = dom.createTextNode("Take Test Now");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["gotoTest", ["get", "this.scheduledTest.test.id", ["loc", [null, [14, 66], [14, 92]]]]], [], ["loc", [null, [14, 46], [14, 94]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-testing-calendar-student-test/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "this.scheduledTest.isActive", ["loc", [null, [13, 15], [13, 42]]]], ["subexpr", "not", [["get", "this.scheduledTest.isPaused", ["loc", [null, [13, 48], [13, 75]]]]], [], ["loc", [null, [13, 43], [13, 76]]]]], [], ["loc", [null, [13, 10], [13, 77]]]]], [], 0, null, ["loc", [null, [13, 4], [15, 11]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 9
          }
        },
        "moduleName": "vimme/components/vimme-testing-calendar-student-test/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "testing-calendar-test-title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "testing-calendar-test-date");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "this.scheduledTest.test.title", ["loc", [null, [2, 40], [2, 73]]]], ["inline", "date-formatter", [], ["date", ["subexpr", "@mut", [["get", "this.scheduledTest.startDate", ["loc", [null, [3, 62], [3, 90]]]]], [], []]], ["loc", [null, [3, 40], [3, 92]]]], ["block", "each", [["get", "this.studentTests", ["loc", [null, [4, 8], [4, 25]]]]], [], 0, 1, ["loc", [null, [4, 0], [16, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
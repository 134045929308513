define('vimme/components/vimme-resource/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['vimme-resource'],

    resource: null,

    videoId: computed('resource.{isVideo,url}', function () {
      var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      if (this.get('resource.isVideo')) {
        var parseUrl = regExp.exec(this.get('resource.url'));
        return parseUrl[5];
      }
    }),

    iframe: computed('videoId', function () {
      return 'https://player.vimeo.com/video/' + this.get('videoId') + '?title=0&byline=0&portrait=0';
    })
  });
});
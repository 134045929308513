define("vimme/templates/teacher/assignment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/assignment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/teacher/assignment/assignment-review", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.model.studentQuestions", ["loc", [null, [3, 25], [3, 52]]]]], [], []], "assignment", ["subexpr", "@mut", [["get", "this.model.assignment", ["loc", [null, [4, 19], [4, 40]]]]], [], []], "studentAssignment", ["subexpr", "@mut", [["get", "this.model.studentAssignment", ["loc", [null, [5, 26], [5, 54]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.model.question", ["loc", [null, [6, 17], [6, 36]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.model.questions", ["loc", [null, [7, 18], [7, 38]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.model.number", ["loc", [null, [8, 15], [8, 32]]]]], [], []]], ["loc", [null, [2, 4], [9, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/assignment.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/teacher/assignments/assignment", [], ["assignment", ["subexpr", "@mut", [["get", "this.model.assignment", ["loc", [null, [12, 19], [12, 40]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.model.question", ["loc", [null, [13, 17], [13, 36]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.model.questions", ["loc", [null, [14, 18], [14, 38]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.model.number", ["loc", [null, [15, 15], [15, 32]]]]], [], []]], ["loc", [null, [11, 4], [16, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 7
          }
        },
        "moduleName": "vimme/templates/teacher/assignment.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "this.model.studentAssignment", ["loc", [null, [1, 6], [1, 34]]]]], [], 0, 1, ["loc", [null, [1, 0], [17, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
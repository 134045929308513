define("vimme/components/mathbots-classroom-reporting/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 13,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sixth");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "this.grades", ["loc", [null, [5, 24], [5, 35]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.title", "selection", ["subexpr", "@mut", [["get", "this.grade", ["loc", [null, [8, 26], [8, 36]]]]], [], []], "placeholder", "Select Grade...", "select-item", ["subexpr", "action", [["get", "setGrade", ["loc", [null, [10, 36], [10, 44]]]]], [], ["loc", [null, [10, 28], [10, 45]]]]], ["loc", [null, [4, 12], [11, 14]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fourth");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "this.schools", ["loc", [null, [17, 24], [17, 36]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.title", "selection", ["subexpr", "@mut", [["get", "this.school", ["loc", [null, [20, 26], [20, 37]]]]], [], []], "placeholder", "Select School...", "select-item", ["subexpr", "action", [["get", "setSchool", ["loc", [null, [22, 36], [22, 45]]]]], [], ["loc", [null, [22, 28], [22, 46]]]]], ["loc", [null, [16, 12], [23, 14]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 4
            },
            "end": {
              "line": 37,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "three-eighths");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "this.classrooms", ["loc", [null, [29, 24], [29, 39]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.title", "selection", ["subexpr", "@mut", [["get", "this.classroom", ["loc", [null, [32, 26], [32, 40]]]]], [], []], "placeholder", "Select Classroom...", "select-item", ["subexpr", "action", [["get", "setClassroom", ["loc", [null, [34, 36], [34, 48]]]]], [], ["loc", [null, [34, 28], [34, 49]]]]], ["loc", [null, [28, 12], [35, 14]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 4
            },
            "end": {
              "line": 63,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "whole");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "mathbots-key");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__left");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "mathbots-key__item");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__box benchmark");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__text");
          var el6 = dom.createTextNode("Passed");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "mathbots-key__item");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__box intensive");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__text");
          var el6 = dom.createTextNode("Failed");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__right");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "mathbots-key__item");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__box");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__text");
          var el6 = dom.createTextNode("Unattempted");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "mathbots-key__item");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__box");
          var el6 = dom.createTextNode("1+");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "mathbots-key__text");
          var el6 = dom.createTextNode("Attempts");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 20
                },
                "end": {
                  "line": 80,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "cell cell-link");
              dom.setAttribute(el1, "style", "width: 80%");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("/");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7, 0, 0);
              morphs[2] = dom.createMorphAt(element7, 2, 2);
              return morphs;
            },
            statements: [["element", "action", ["setFilters", ["get", "classroomScore.classroomId", ["loc", [null, [79, 91], [79, 117]]]], ["get", "classroomScore.schoolId", ["loc", [null, [79, 118], [79, 141]]]]], [], ["loc", [null, [79, 69], [79, 143]]]], ["content", "classroomScore.classroomTitle", ["loc", [null, [79, 144], [79, 177]]]], ["content", "classroomScore.schoolName", ["loc", [null, [79, 178], [79, 207]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 20
                },
                "end": {
                  "line": 82,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              dom.setAttribute(el1, "style", "width: 80%");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("/");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element6, 0, 0);
              morphs[1] = dom.createMorphAt(element6, 2, 2);
              return morphs;
            },
            statements: [["content", "classroomScore.classroomTitle", ["loc", [null, [81, 61], [81, 94]]]], ["content", "classroomScore.schoolName", ["loc", [null, [81, 95], [81, 124]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 12
              },
              "end": {
                "line": 84,
                "column": 12
              }
            },
            "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            dom.setAttribute(el2, "style", "width: 10%; text-align: center;");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            dom.setAttribute(el2, "style", "width: 10%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n             ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(element8, 5, 5);
            return morphs;
          },
          statements: [["inline", "add", [["get", "index", ["loc", [null, [76, 84], [76, 89]]]], 1], [], ["loc", [null, [76, 78], [76, 93]]]], ["content", "classroomScore.score", ["loc", [null, [77, 57], [77, 81]]]], ["block", "if", [["subexpr", "or", [["subexpr", "includes", [["get", "classroomIds", ["loc", [null, [78, 40], [78, 52]]]], ["get", "classroomScore.classroomId", ["loc", [null, [78, 53], [78, 79]]]]], [], ["loc", [null, [78, 30], [78, 80]]]], ["subexpr", "includes", [["get", "schoolIds", ["loc", [null, [78, 91], [78, 100]]]], ["get", "classroomScore.schoolId", ["loc", [null, [78, 101], [78, 124]]]]], [], ["loc", [null, [78, 81], [78, 125]]]]], [], ["loc", [null, [78, 26], [78, 126]]]]], [], 0, 1, ["loc", [null, [78, 20], [82, 27]]]]],
          locals: ["classroomScore", "index"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 0
            },
            "end": {
              "line": 87,
              "column": 0
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-general column");
          dom.setAttribute(el1, "style", "width: 100%");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-general-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 10%; text-align: center;");
          var el5 = dom.createTextNode("Rank");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 10%");
          var el5 = dom.createTextNode("Score");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 80%");
          var el5 = dom.createTextNode("Classroom");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 6, 6);
          return morphs;
        },
        statements: [["block", "each", [["get", "leaderboards.topClassroomScores", ["loc", [null, [74, 20], [74, 51]]]]], [], 0, null, ["loc", [null, [74, 12], [84, 21]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child5 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 16
                },
                "end": {
                  "line": 94,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell-header student-column no-hover");
              var el2 = dom.createTextNode("Sign Up");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n             ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 16
                },
                "end": {
                  "line": 100,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("%");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n             ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "signupAvg", ["loc", [null, [99, 38], [99, 51]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 24
                    },
                    "end": {
                      "line": 105,
                      "column": 124
                    }
                  },
                  "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "student.lastThenFirst", ["loc", [null, [105, 99], [105, 124]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 20
                  },
                  "end": {
                    "line": 106,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "link-to", [["get", "routeName", ["loc", [null, [105, 35], [105, 44]]]], ["get", "student.userId", ["loc", [null, [105, 45], [105, 59]]]]], ["class", "cell student-column cell-link"], 0, null, ["loc", [null, [105, 24], [105, 136]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 20
                  },
                  "end": {
                    "line": 108,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "cell student-column");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["content", "student.lastThenFirst", ["loc", [null, [107, 57], [107, 82]]]]],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 24
                    },
                    "end": {
                      "line": 112,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                         ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createElement("span");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                     ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(element1, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element1, 'class');
                  morphs[1] = dom.createAttrMorph(element2, 'class');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell average-column ", ["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [111, 65], [111, 85]]]], "benchmark", "intensive"], [], ["loc", [null, [111, 60], [111, 111]]]]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [111, 131], [111, 151]]]], "icon-check", "icon-delete"], [], ["loc", [null, [111, 126], [111, 180]]]]]]]],
                locals: ["attempt"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 20
                  },
                  "end": {
                    "line": 113,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [110, 50], [110, 58]]]], ["get", "student.userId", ["loc", [null, [110, 59], [110, 73]]]]], [], ["loc", [null, [110, 32], [110, 74]]]]], [], 0, null, ["loc", [null, [110, 24], [112, 33]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 12
                },
                "end": {
                  "line": 115,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createComment("\n                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n             ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["block", "if", [["get", "routeName", ["loc", [null, [104, 26], [104, 35]]]]], [], 0, 1, ["loc", [null, [104, 20], [108, 27]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [109, 26], [109, 34]]]]], [], 2, null, ["loc", [null, [109, 20], [113, 27]]]]],
            locals: ["student"],
            templates: [child0, child1, child2]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 19
                },
                "end": {
                  "line": 123,
                  "column": 19
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell-header no-hover");
              var el2 = dom.createTextNode("L");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "level.order", ["loc", [null, [122, 58], [122, 73]]]]],
            locals: ["level"],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 19
                },
                "end": {
                  "line": 128,
                  "column": 19
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("%");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "average", ["loc", [null, [127, 41], [127, 52]]]]],
            locals: ["average"],
            templates: []
          };
        }();
        var child5 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 135,
                          "column": 81
                        },
                        "end": {
                          "line": 135,
                          "column": 129
                        }
                      },
                      "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "userLevel.attempts", ["loc", [null, [135, 107], [135, 129]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 134,
                        "column": 31
                      },
                      "end": {
                        "line": 136,
                        "column": 31
                      }
                    },
                    "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element0, 'class');
                    morphs[1] = dom.createMorphAt(element0, 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "attempt-color", [["get", "userLevel", ["loc", [null, [135, 68], [135, 77]]]]], [], ["loc", [null, [135, 52], [135, 79]]]]]]], ["block", "if", [["get", "userLevel.attempts", ["loc", [null, [135, 87], [135, 105]]]]], [], 0, null, ["loc", [null, [135, 81], [135, 136]]]]],
                  locals: ["userLevel"],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 133,
                      "column": 27
                    },
                    "end": {
                      "line": 137,
                      "column": 27
                    }
                  },
                  "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempt", ["loc", [null, [134, 57], [134, 64]]]], ["get", "level.id", ["loc", [null, [134, 65], [134, 73]]]]], [], ["loc", [null, [134, 39], [134, 74]]]]], [], 0, null, ["loc", [null, [134, 31], [136, 40]]]]],
                locals: ["level"],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 23
                  },
                  "end": {
                    "line": 138,
                    "column": 19
                  }
                },
                "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "each", [["get", "levels", ["loc", [null, [133, 35], [133, 41]]]]], [], 0, null, ["loc", [null, [133, 27], [137, 36]]]]],
              locals: ["attempt"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 130,
                  "column": 12
                },
                "end": {
                  "line": 140,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createComment("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [132, 49], [132, 57]]]], ["get", "student.userId", ["loc", [null, [132, 58], [132, 72]]]]], [], ["loc", [null, [132, 31], [132, 73]]]]], [], 0, null, ["loc", [null, [132, 23], [138, 28]]]]],
            locals: ["student"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 0
              },
              "end": {
                "line": 143,
                "column": 0
              }
            },
            "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column no-hover");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n         ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n         ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createComment("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createComment("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
            morphs[2] = dom.createMorphAt(element4, 5, 5);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
            morphs[5] = dom.createMorphAt(element5, 5, 5);
            return morphs;
          },
          statements: [["block", "if", [["get", "signedUp", ["loc", [null, [92, 22], [92, 30]]]]], [], 0, null, ["loc", [null, [92, 16], [94, 23]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [98, 22], [98, 30]]]]], [], 1, null, ["loc", [null, [98, 16], [100, 23]]]], ["block", "each", [["get", "students", ["loc", [null, [102, 20], [102, 28]]]]], [], 2, null, ["loc", [null, [102, 12], [115, 21]]]], ["block", "each", [["get", "levels", ["loc", [null, [121, 27], [121, 33]]]]], [], 3, null, ["loc", [null, [121, 19], [123, 28]]]], ["block", "each", [["get", "averages", ["loc", [null, [126, 27], [126, 35]]]]], [], 4, null, ["loc", [null, [126, 19], [128, 28]]]], ["block", "each", [["get", "students", ["loc", [null, [130, 20], [130, 28]]]]], [], 5, null, ["loc", [null, [130, 12], [140, 21]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 87,
              "column": 0
            },
            "end": {
              "line": 143,
              "column": 0
            }
          },
          "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "students", ["loc", [null, [87, 10], [87, 18]]]]], [], 0, null, ["loc", [null, [87, 0], [143, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 143,
            "column": 7
          }
        },
        "moduleName": "vimme/components/mathbots-classroom-reporting/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "filter-bar");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element9, 1, 1);
        morphs[1] = dom.createMorphAt(element9, 3, 3);
        morphs[2] = dom.createMorphAt(element9, 5, 5);
        morphs[3] = dom.createMorphAt(element9, 7, 7);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "grades.length", ["loc", [null, [2, 10], [2, 23]]]]], [], 0, null, ["loc", [null, [2, 4], [13, 11]]]], ["block", "if", [["get", "schools.length", ["loc", [null, [14, 10], [14, 24]]]]], [], 1, null, ["loc", [null, [14, 4], [25, 11]]]], ["block", "if", [["get", "classrooms.length", ["loc", [null, [26, 10], [26, 27]]]]], [], 2, null, ["loc", [null, [26, 4], [37, 11]]]], ["block", "if", [["get", "students", ["loc", [null, [38, 10], [38, 18]]]]], [], 3, null, ["loc", [null, [38, 4], [63, 11]]]], ["block", "if", [["get", "leaderboards", ["loc", [null, [65, 6], [65, 18]]]]], [], 4, 5, ["loc", [null, [65, 0], [143, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});
define("vimme/components/app-pages/teacher/reports/assignment/student-row/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 8
              },
              "end": {
                "line": 6,
                "column": 8
              }
            },
            "moduleName": "vimme/components/app-pages/teacher/reports/assignment/student-row/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("/");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "this.studentAssignment.score", ["loc", [null, [5, 12], [5, 44]]]], ["content", "this.assignment.total", ["loc", [null, [5, 45], [5, 70]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 8
              },
              "end": {
                "line": 9,
                "column": 8
              }
            },
            "moduleName": "vimme/components/app-pages/teacher/reports/assignment/student-row/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "p.formattedPercent", ["loc", [null, [8, 12], [8, 34]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/reports/assignment/student-row/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "cell");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          morphs[2] = dom.createMorphAt(element0, 4, 4);
          return morphs;
        },
        statements: [["content", "this.studentAssignment.student.lastThenFirst", ["loc", [null, [3, 26], [3, 74]]]], ["block", "link-to", ["teacher.assignment", ["get", "this.assignment.id", ["loc", [null, [4, 40], [4, 58]]]], 1, ["subexpr", "query-params", [], ["studentAssignmentId", ["get", "this.studentAssignment.id", ["loc", [null, [4, 95], [4, 120]]]]], ["loc", [null, [4, 61], [4, 121]]]]], ["class", ["subexpr", "concat", ["cell cell-link ", ["subexpr", "color", [["get", "p.roundedPercent", ["loc", [null, [4, 161], [4, 177]]]]], [], ["loc", [null, [4, 154], [4, 178]]]]], [], ["loc", [null, [4, 128], [4, 179]]]]], 0, null, ["loc", [null, [4, 8], [6, 20]]]], ["block", "link-to", ["teacher.assignment", ["get", "this.assignment.id", ["loc", [null, [7, 40], [7, 58]]]], 1, ["subexpr", "query-params", [], ["studentAssignmentId", ["get", "this.studentAssignment.id", ["loc", [null, [7, 95], [7, 120]]]]], ["loc", [null, [7, 61], [7, 121]]]]], ["class", ["subexpr", "concat", ["cell cell-link ", ["subexpr", "color", [["get", "p.roundedPercent", ["loc", [null, [7, 161], [7, 177]]]]], [], ["loc", [null, [7, 154], [7, 178]]]]], [], ["loc", [null, [7, 128], [7, 179]]]]], 1, null, ["loc", [null, [7, 8], [9, 20]]]]],
        locals: ["p"],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 18
          }
        },
        "moduleName": "vimme/components/app-pages/teacher/reports/assignment/student-row/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "vimme-percent", [], ["total", ["subexpr", "@mut", [["get", "this.assignment.total", ["loc", [null, [1, 23], [1, 44]]]]], [], []], "score", ["subexpr", "@mut", [["get", "this.studentAssignment.score", ["loc", [null, [1, 51], [1, 79]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [11, 18]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});
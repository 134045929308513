define('vimme/controllers/sub-admin/reports/mathbots/competition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'grade', 'school'],

    classroom: null,
    grade: null,
    school: null,

    actions: {
      setGrade: function setGrade(grade) {
        this.setProperties({
          classroom: null,
          grade: grade ? grade.get('id') : null
        });
      },
      setClassroom: function setClassroom(classroom) {
        this.set('classroom', classroom ? Ember.get(classroom, 'id') : null);
      },
      setSchool: function setSchool(school) {
        this.setProperties({
          classroom: null,
          school: school ? Ember.get(school, 'id') : null
        });
      }
    }
  });
});
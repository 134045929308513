define('vimme/routes/student/test/questions/question', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var RSVP = Ember.RSVP;
  exports.default = _student.default.extend({
    model: function model(params) {
      var parentModel = this.modelFor('student.test.questions');

      var question = parentModel.questions.objectAt(parseInt(params.question_id, 10) - 1);
      var scheduledTest = parentModel.scheduledTest.reload();
      var studentQuestion = question ? parentModel.studentQuestions.find(function (studentQuestion) {
        return studentQuestion.get('question.id') === question.get('id');
      }) : null;
      var textAnswer = studentQuestion ? studentQuestion.get('textAnswer') : null;

      return RSVP.hash(_extends({}, parentModel, {
        question: question,
        scheduledTest: scheduledTest,
        studentQuestion: studentQuestion,
        textAnswer: textAnswer
      }));
    },
    afterModel: function afterModel(model) {
      if (!model.question) {
        this.replaceWith('student.test.questions.question', 1);
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('attempt', model.attempt);
      controller.set('model', model.question);
      controller.set('studentQuestions', model.studentQuestions);
      controller.set('studentQuestion', model.studentQuestion);
      controller.set('studentTest', model.studentTest);
      controller.set('textAnswer', model.textAnswer);
      controller.set('test', model.test);
      controller.set('student', model.student);
      controller.set('school', model.school);
      controller.set('showError', false);

      this.controllerFor('student.test.questions').set('currentQuestion', model.question);
    }
  });
});
define('vimme/routes/guardian/reports/week', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    model: function model() {
      return this.modelFor('guardian.reports');
    }
  });
});
define('vimme/routes/teacher/builder/assignments/assignment/question', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model(params) {
      var question = this.store.find('assignment-question', params.question_id);
      return Ember.RSVP.hash({
        question: question
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('assignment-question-answer').filterBy('id', null).invoke('deleteRecord');
        controller.get('model.question.assignmentQuestionAnswers').invoke('rollback');
      }
    }
  });
});
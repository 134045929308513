define('vimme/components/mathbots-teacher-reporting/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['content', 'reports'],
    classNameBindings: ['signedUp:reports-question:reports-mathbots'],

    teacherIds: Ember.computed('teachers.@each.id', function () {
      return this.get('teachers').map(function (teacher) {
        return teacher.get('id');
      });
    }),

    signupAvg: Ember.computed('signedUp', 'attempts', 'students', function () {
      var _this = this;

      var signedUp = this.get('signedUp');
      if (signedUp) {
        var tracker = this.get('students').reduce(function (prev, student) {
          var signedUp = _this.get('attempts.' + student.get('userId') + '.is_signed_up');
          return {
            signedUp: prev.signedUp + (signedUp ? 1 : 0),
            total: prev.total + 1
          };
        }, { signedUp: 0, total: 0 });
        return tracker.total ? Math.round(tracker.signedUp / tracker.total * 100) : 0;
      }
      return 0;
    })
  });
});
define('vimme/utils/date-in-range', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (date, start, end) {
    return moment(start).startOf('day').diff(date) <= 0 && moment(end).endOf('day').diff(date) >= 0;
  };
});
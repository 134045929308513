define("vimme/templates/sub-admin/reports/mathbots/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/sub-admin/reports/mathbots/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "mathbots-classroom-reporting", [], ["students", ["subexpr", "@mut", [["get", "model.students", ["loc", [null, [2, 13], [2, 27]]]]], [], []], "levels", ["subexpr", "@mut", [["get", "model.levels", ["loc", [null, [3, 11], [3, 23]]]]], [], []], "attempts", ["subexpr", "@mut", [["get", "model.attempts", ["loc", [null, [4, 13], [4, 27]]]]], [], []], "averages", ["subexpr", "@mut", [["get", "model.averages", ["loc", [null, [5, 13], [5, 27]]]]], [], []], "routeName", "sub-admin.reports.mathbots.student", "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [7, 11], [7, 23]]]]], [], []], "grade", ["subexpr", "@mut", [["get", "model.grade", ["loc", [null, [8, 10], [8, 21]]]]], [], []], "classrooms", ["subexpr", "@mut", [["get", "model.classrooms", ["loc", [null, [9, 15], [9, 31]]]]], [], []], "classroom", ["subexpr", "@mut", [["get", "model.classroom", ["loc", [null, [10, 14], [10, 29]]]]], [], []], "schools", ["subexpr", "@mut", [["get", "model.schools", ["loc", [null, [11, 12], [11, 25]]]]], [], []], "school", ["subexpr", "@mut", [["get", "model.school", ["loc", [null, [12, 11], [12, 23]]]]], [], []], "setSchool", ["subexpr", "action", ["setSchool"], [], ["loc", [null, [13, 14], [13, 34]]]], "setGrade", ["subexpr", "action", ["setGrade"], [], ["loc", [null, [14, 13], [14, 32]]]], "setClassroom", ["subexpr", "action", ["setClassroom"], [], ["loc", [null, [15, 17], [15, 40]]]]], ["loc", [null, [1, 0], [16, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
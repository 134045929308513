define('vimme/transforms/isodate', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return moment(serialized).toDate();
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized === null) return null;
      if (deserialized) {
        return moment(deserialized).toISOString();
      }
    }
  });
});
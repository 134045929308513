define('vimme/routes/school-admin/management/classrooms/classroom', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var _modelFor = this.modelFor('school-admin.management.classrooms'),
          courses = _modelFor.courses,
          teachers = _modelFor.teachers;

      return Ember.RSVP.hash({
        classroom: this.store.find('classroom', params.classroom_id),
        courses: courses,
        teachers: teachers
      });
    }
  });
});
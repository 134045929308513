define("vimme/utils/time-in-range", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (date, start, end) {
    return moment(start).diff(date) <= 0 && moment(end).diff(date) >= 0;
  };
});
define('vimme/controllers/admin/test-data/test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isClean: Ember.computed.not('model.isDirty'),

    actions: {
      save: function save() {
        var test = this.get('model');

        test.save();
      }
    }
  });
});
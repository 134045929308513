define('vimme/utils/pad-weekly-averages', ['exports', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _round, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (weeks, averages, grade) {
    var percentageSum = averages.reduce(function (prev, avg) {
      return prev + Ember.get(avg, 'percent');
    }, 0);

    var percent = (0, _round.default)(percentageSum / Ember.get(averages, 'length'));

    return {
      color: (0, _color.default)(percent, grade),
      percent: percent,
      paddedWeeklyAverages: paddedWeeklyAverages(weeks, averages, grade)
    };
  };

  function paddedWeeklyAverages(weeks, averages, grade) {
    return weeks.map(function (week, num) {
      var weeklyAverage = averages.find(function (average) {
        var number = Ember.get(week, 'number');

        if (Ember.get(average, 'test')) {
          return Ember.get(average, 'test.week.number') === number;
        }
      });

      var scoreColor = weeklyAverage ? (0, _color.default)(Ember.get(weeklyAverage, 'roundedPercent'), grade) : null;

      return { average: weeklyAverage || null, num: num + 1, color: scoreColor };
    });
  }
});
define("vimme/templates/sub-admin/reports/question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/sub-admin/reports/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "app-pages/sub-admin/reports/question", [], ["classroomId", ["subexpr", "@mut", [["get", "this.classroom", ["loc", [null, [2, 16], [2, 30]]]]], [], []], "courseId", ["subexpr", "@mut", [["get", "this.course", ["loc", [null, [3, 13], [3, 24]]]]], [], []], "schoolId", ["subexpr", "@mut", [["get", "this.school", ["loc", [null, [4, 13], [4, 24]]]]], [], []], "weekId", ["subexpr", "@mut", [["get", "this.week", ["loc", [null, [5, 11], [5, 20]]]]], [], []], "isPrint", ["subexpr", "@mut", [["get", "this.print", ["loc", [null, [6, 12], [6, 22]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "this.model.grades", ["loc", [null, [7, 11], [7, 28]]]]], [], []], "schools", ["subexpr", "@mut", [["get", "this.model.schools", ["loc", [null, [8, 12], [8, 30]]]]], [], []], "tests", ["subexpr", "@mut", [["get", "this.model.tests", ["loc", [null, [9, 10], [9, 26]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "this.model.weeks", ["loc", [null, [10, 10], [10, 26]]]]], [], []], "gotoTestReview", ["subexpr", "action", ["gotoTestReviewC"], [], ["loc", [null, [11, 19], [11, 45]]]]], ["loc", [null, [1, 0], [12, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
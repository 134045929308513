define('vimme/controllers/school-admin/management/sub-admins', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    indexController: Ember.inject.controller('school-admin/management/sub-admins/index'),

    actions: {
      newSubAdmin: function newSubAdmin() {
        if (this.get('applicationController.currentRouteName') !== 'school-admin.management.sub-admins.index') {
          this.transitionToRoute('school-admin.management.sub-admins');
        }

        var index = this.get('indexController');

        index.set('lastName', '');
        index.set('firstName', '');
        index.set('email', '');
        index.set('password', '');
      }
    },

    unsorted: function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var subAdmins = this.get('model') || [],
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = subAdmins.filter(function (subAdmin) {
          return filter.test(subAdmin.get('fullName'));
        });
      } else {
        unsorted = subAdmins;
      }

      return unsorted;
    }.property('searchTerm', 'model.[]'),
    sortProps: ['lastName'],
    filteredSubAdmins: Ember.computed.sort('unsorted', 'sortProps')
  });
});
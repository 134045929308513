define('vimme/routes/guardian', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode'], function (exports, _authenticatedRouteMixin, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'index',
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref.role;

      this._super(transition);

      if (role !== 'Guardian') {
        this.replaceWith('index');
      } else if (transition.targetName === 'guardian.index') {
        if (transition.sequence === 0) {
          this.replaceWith('guardian.profile');
        } else {
          this.transitionTo('guardian.profile');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref2.role,
          id = _ref2.id;

      var user = this.store.find(role, id);

      return Ember.RSVP.hash({
        user: user,
        classrooms: user.then(function (user) {
          return user.get('classrooms');
        })
      });
    }
  });
});
define('vimme/controllers/sub-admin/reports/mathbots/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['school', 'grade', 'classroom'],

    school: null,
    grade: null,
    classroom: null,

    actions: {
      setSchool: function setSchool(school) {
        this.setProperties({
          school: school ? school.get('id') : null,
          classroom: null
        });
      },
      setGrade: function setGrade(grade) {
        this.setProperties({
          grade: grade ? grade.get('id') : null,
          classroom: null
        });
      },
      setClassroom: function setClassroom(classroom) {
        this.set('classroom', classroom ? classroom.get('id') : null);
      }
    }
  });
});
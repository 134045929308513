define('vimme/routes/teacher/reports/standard', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var RSVP = Ember.RSVP;
  exports.default = _teacher.default.extend({
    model: function model() {
      var _modelFor = this.modelFor('teacher.reports'),
          weeks = _modelFor.weeks;

      return RSVP.hash(_extends({
        weeks: weeks
      }, this.modelFor('teacher')));
    }
  });
});
define('vimme/routes/school-admin/test/print', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        answers: _this.modelFor('school-admin.test').get('answers'),
        questions: _this.modelFor('school-admin.test').get('questions')
      });
    }
  });
});
define('vimme/components/vimme-calendar-week-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ol',

    classNames: ['vimme-calendar-week-header'],

    labelType: 'abbr',

    daysArray: [{
      title: 'Sunday',
      abbr: 'Sun',
      single: 'S'
    }, {
      title: 'Monday',
      abbr: 'Mon',
      single: 'M'
    }, {
      title: 'Tuesday',
      abbr: 'Tue',
      single: 'T'
    }, {
      title: 'Wednesday',
      abbr: 'Wed',
      single: 'W'
    }, {
      title: 'Thursday',
      abbr: 'Thu',
      single: 'T'
    }, {
      title: 'Friday',
      abbr: 'Fri',
      single: 'F'
    }, {
      title: 'Saturday',
      abbr: 'Sat',
      single: 'S'
    }],

    days: function () {
      var type = this.get('labelType'),
          days = this.get('daysArray');

      return days.mapBy(type);
    }.property('labelType')
  });
});
define('vimme/routes/teacher/reports/week', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      return Ember.RSVP.hash({
        classrooms: teacher.get('classrooms'),
        teacher: teacher,
        weeks: this.modelFor('teacher.reports').weeks
      });
    }
  });
});
define('vimme/initializers/asset-map', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  function advance(container, application, AssetMap) {
    container.register('assetMap:main', AssetMap, { singleton: true });
    application.inject('controller', 'assets', 'assetMap:main');
    application.inject('component', 'assets', 'assetMap:main');
    application.inject('model', 'assets', 'assetMap:main');
    application.advanceReadiness();
  }

  function initialize(container, application) {
    application.deferReadiness();

    var AssetMap = Ember.Object.extend();

    if (['development', 'testing'].includes(_environment.default.environment)) {
      AssetMap.reopen({
        resolve: function resolve(name) {
          return name;
        }
      });
      advance(container, application, AssetMap);
      return;
    }

    var promise = new Ember.RSVP.Promise(function (resolve, reject) {
      Ember.$.ajax({
        cache: false,
        url: '/assets/assetMap.json',
        dataType: 'json',
        success: resolve
      }).fail(reject);
    });

    promise.then(function (assetMap) {
      AssetMap.reopen({
        assetMap: assetMap,
        resolve: function resolve(name) {
          var prepend = assetMap.prepend ? assetMap.prepend : '';
          var asset = assetMap.assets[name] ? assetMap.assets[name] : name;

          return prepend + asset;
        }
      });
    }, function () {
      AssetMap.reopen({
        resolve: function resolve(name) {
          return name;
        }
      });
    }).then(function () {
      advance(container, application, AssetMap);
    });
  }

  exports.default = {
    name: 'asset-map',
    initialize: initialize
  };
});
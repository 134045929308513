define('vimme/routes/sub-admin/paper-assignment-builder/paper-assignment', ['exports', 'vimme/routes/sub-admin'], function (exports, _subAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        categories: this.modelFor('sub-admin.paper-assignment-builder').categories,
        difficulties: this.modelFor('sub-admin.paper-assignment-builder').difficulties,
        grades: this.modelFor('sub-admin.paper-assignment-builder').grades,
        quiz: this.store.find('quiz', params.paper_assignment_id),
        subAdmin: this.modelFor('sub-admin.paper-assignment-builder').subAdmin
      });
    },
    resetController: function resetController(controller) {
      var quiz = controller.get('model.quiz');
      var quizQuestions = quiz.get('quizQuestions');
      quiz.rollback();
      quizQuestions.reload();
    }
  });
});
define('vimme/routes/admin/builder/resources/resource', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      var localCurriculums = this.store.all('curriculum');
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      return Ember.RSVP.hash({
        resource: this.store.find('resource', params.resource_id),
        curriculums: localCurriculums.get('length') > 0 ? localCurriculums : this.store.findAll('curriculum'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week')
      });
    }
  });
});
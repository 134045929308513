define('vimme/serializers/student-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      answers: { serialize: 'ids', deserialize: 'ids' }
    }
  });
});
define("vimme/templates/student/test/questions/question", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/student/test/questions/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 17], [3, 22]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "studentQuestion", ["loc", [null, [4, 24], [4, 39]]]]], [], []], "isReviewing", ["subexpr", "@mut", [["get", "isReviewing", ["loc", [null, [5, 20], [5, 31]]]]], [], []], "showAudio", ["subexpr", "not", [["get", "this.school.teacherCanScheduleTests", ["loc", [null, [6, 23], [6, 58]]]]], [], ["loc", [null, [6, 18], [6, 59]]]], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [7, 16], [7, 28]]]]], [], []]], ["loc", [null, [2, 4], [8, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "position: absolute; top: -40px; left: 0; width: 100%; background: #41c9a5; color: white;");
            var el2 = dom.createTextNode("Correct!");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 8
              },
              "end": {
                "line": 23,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "vimme-multi-audio", [], ["style", "position: absolute; top: -32px; left: 50%; margin-left: -14px;", "item", ["subexpr", "@mut", [["get", "this.model", ["loc", [null, [20, 21], [20, 31]]]]], [], []], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [21, 24], [21, 36]]]]], [], []]], ["loc", [null, [18, 12], [22, 14]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 20
                },
                "end": {
                  "line": 40,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/student/test/questions/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "vimme-answer", [], ["isMutedSelection", ["subexpr", "and", [["subexpr", "not", [["get", "this.isFirstAttempt", ["loc", [null, [32, 55], [32, 74]]]]], [], ["loc", [null, [32, 50], [32, 75]]]], ["subexpr", "not", [["get", "this.studentQuestion.isPreviouslyCorrect", ["loc", [null, [32, 81], [32, 121]]]]], [], ["loc", [null, [32, 76], [32, 122]]]]], [], ["loc", [null, [32, 45], [32, 123]]]], "showAudio", ["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [33, 43], [33, 57]]]], ["get", "this.model.answersAudio", ["loc", [null, [33, 58], [33, 81]]]]], [], ["loc", [null, [33, 38], [33, 82]]]], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [34, 36], [34, 48]]]]], [], []], "answer", ["subexpr", "@mut", [["get", "questionAnswer", ["loc", [null, [35, 35], [35, 49]]]]], [], []], "studentAnswers", ["subexpr", "@mut", [["get", "studentAnswers", ["loc", [null, [36, 43], [36, 57]]]]], [], []], "isSavable", ["subexpr", "@mut", [["get", "isSavable", ["loc", [null, [37, 38], [37, 47]]]]], [], []], "action", "saveAnswer"], ["loc", [null, [31, 24], [39, 26]]]]],
            locals: ["questionAnswer"],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 12
              },
              "end": {
                "line": 42,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            dom.setAttribute(el1, "class", "answer-header");
            var el2 = dom.createTextNode("Answer choices:");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ol");
            dom.setAttribute(el1, "class", "question-answers");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.answers", ["loc", [null, [30, 28], [30, 41]]]]], [], 0, null, ["loc", [null, [30, 20], [40, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 12
              },
              "end": {
                "line": 46,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-text-answer");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn btn-submit-answer");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "input-inliner");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'disabled');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2, 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "isUnsavable", ["loc", [null, [44, 69], [44, 80]]]]], ["element", "action", ["saveTextAnswer"], [], ["loc", [null, [44, 83], [44, 110]]]], ["content", "submitText", ["loc", [null, [44, 111], [44, 125]]]], ["inline", "answer-input", [], ["grade", ["subexpr", "@mut", [["get", "test.grade.id", ["loc", [null, [44, 182], [44, 195]]]]], [], []], "value", ["subexpr", "@mut", [["get", "textAnswer", ["loc", [null, [44, 202], [44, 212]]]]], [], []], "placeholder", "Enter answer here...", "class", "input input-submit-answer"], ["loc", [null, [44, 161], [44, 283]]]]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 16
                },
                "end": {
                  "line": 50,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/student/test/questions/question.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-error");
              var el2 = dom.createTextNode("Oops! Your answer wasn't saved.");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Please click submit again or refresh the page.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 16
                },
                "end": {
                  "line": 52,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/student/test/questions/question.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-error");
              var el2 = dom.createTextNode("Oops! Your answer wasn't saved.");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Please select your answer again or refresh the page.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 12
              },
              "end": {
                "line": 53,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.isTextQuestion", ["loc", [null, [48, 22], [48, 42]]]]], [], 0, 1, ["loc", [null, [48, 16], [52, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child5 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 8
              },
              "end": {
                "line": 59,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/student/test/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-image");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "alt", "");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "model.src", ["loc", [null, [57, 28], [57, 37]]]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 62,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/student/test/questions/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n     ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [3]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createAttrMorph(element3, 'style');
          morphs[2] = dom.createMorphAt(element3, 1, 1);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createUnsafeMorphAt(element4, 1, 1);
          morphs[5] = dom.createMorphAt(element4, 3, 3);
          morphs[6] = dom.createMorphAt(element4, 4, 4);
          morphs[7] = dom.createMorphAt(element3, 5, 5);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.studentQuestion.isPreviouslyCorrect", ["loc", [null, [11, 10], [11, 50]]]]], [], 0, null, ["loc", [null, [11, 4], [13, 11]]]], ["attribute", "style", ["concat", ["position: relative; ", ["subexpr", "if", [["get", "this.studentQuestion.isPreviouslyCorrect", ["loc", [null, [15, 41], [15, 81]]]], "opacity: 0.7; pointer-events: none;"], [], ["loc", [null, [15, 36], [15, 121]]]]]]], ["block", "if", [["get", "this.showAudio", ["loc", [null, [17, 14], [17, 28]]]]], [], 1, null, ["loc", [null, [17, 8], [23, 15]]]], ["attribute", "class", ["concat", ["question-text ", ["subexpr", "if", [["get", "model.isVertical", ["loc", [null, [25, 39], [25, 55]]]], "is-vertical"], [], ["loc", [null, [25, 34], [25, 71]]]]]]], ["content", "model.title", ["loc", [null, [26, 12], [26, 29]]]], ["block", "unless", [["get", "model.isTextQuestion", ["loc", [null, [27, 22], [27, 42]]]]], [], 2, 3, ["loc", [null, [27, 12], [46, 23]]]], ["block", "if", [["get", "showError", ["loc", [null, [47, 18], [47, 27]]]]], [], 4, null, ["loc", [null, [47, 12], [53, 19]]]], ["block", "if", [["get", "model.hasImage", ["loc", [null, [55, 14], [55, 28]]]]], [], 5, null, ["loc", [null, [55, 8], [59, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 7
          }
        },
        "moduleName": "vimme/templates/student/test/questions/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [1, 6], [1, 17]]]]], [], 0, 1, ["loc", [null, [1, 0], [62, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define('vimme/controllers/index', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    authAjax: Ember.inject.service(),

    loginError: false,
    showForgotDialog: false,

    queryParams: ['success'],

    success: '',

    identification: Ember.computed(function () {
      return this.get('success');
    }),

    isCore: function () {
      return _environment.default.environment === 'core';
    }.property(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:jwt', credentials).then(function () {
          _this.set('loginError', false);
        }, function (error) {
          // TODO: add unconfirmed check for both yearly and trial and show a re-send confirmation dialog
          if (error.error && error.code && error.error === 'yearly_submission_unpaid') {
            location.assign('/api/stripe/checkout/session?code=' + error.code);
          } else {
            _this.set('loginError', true);
          }
        });
      },
      closeErrorDialog: function closeErrorDialog() {
        this.set('loginError', false);
      },
      showForgotDialog: function showForgotDialog() {
        this.set('showForgotDialog', true);
      },
      cancelForgotDialog: function cancelForgotDialog() {
        this.set('forgotEmail', null);
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        this.set('showForgotDialog', false);
      },
      sendResetLink: function sendResetLink(forgotEmail) {
        var _this2 = this;

        var hash = {
          type: 'POST',
          data: {
            email: forgotEmail
          }
        };
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        this.get('authAjax').request(_environment.default.API.host + '/password/reset-link', hash).then(function (data) {
          _this2.set('message', data.message);
          _this2.set('isResetError', false);
          _this2.set('isResetSuccess', true);
        }).catch(function (xhr) {
          _this2.set('message', xhr.responseJSON.message);
          _this2.set('isResetError', true);
          _this2.set('isResetSuccess', false);
        });
      }
    }
  });
});
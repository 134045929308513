define('vimme/routes/admin/management', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        schoolAdmins: this.store.findAll('schoolAdmin'),
        schools: this.store.findAll('school')
      });
    }
  });
});
define('vimme/routes/student/reports/progress', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _student.default.extend({
    model: function model() {
      return Ember.RSVP.hash(_extends({
        scheduledTests: this.store.findAll('scheduledTest'),
        studentTests: this.store.query('studentTest', { attempt: 'all' })
      }, this.modelFor('student')));
    }
  });
});
define('vimme/routes/teacher/resources', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;

      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');

      var week0 = Ember.Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var grade0 = Ember.Object.create({
        id: 'none',
        title: 'General',
        shortTitle: 'General'
      });

      var weeks = [week0].concat(localWeeks.toArray());
      var grades = [grade0].concat(localGrades.toArray());

      return Ember.RSVP.hash({
        grade: teacher.get('grade.id'),
        grades: grades,
        weeks: weeks
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!controller.get('grades')) {
        controller.set('grades', model.grade);
      }
    }
  });
});
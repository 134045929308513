define('vimme/controllers/sub-admin/paper-assignment-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: [{ addQuestions: 'add-questions' }],
    addQuestions: false,

    application: Ember.inject.controller(),
    index: Ember.inject.controller('sub-admin.paper-assignment-builder.index'),

    actions: {
      newPaperAssignment: function newPaperAssignment() {
        this.set('addQuestions', false);

        if (this.get('application.currentRouteName') !== 'sub-admin.paper-assignment-builder.index') {
          this.transitionToRoute('sub-admin.paper-assignment-builder.index');
        } else {
          this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
          this.set('index.model.quiz', this.store.createRecord('quiz', {
            title: null,
            subAdmin: this.get('model.subAdmin')
          }));
        }
      }
    },

    filteredQuizzes: function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var quizzes = this.get('model.quizzes').filterBy('isNew', false).filterBy('isDeleted', false),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = quizzes.filter(function (quiz) {
          return filter.test(quiz.get('title'));
        });
      } else {
        unsorted = quizzes;
      }

      return unsorted;
    }.property('searchTerm', 'model.quizzes.@each.title'),
    sortProps: ['title'],
    sortedQuizzes: Ember.computed.sort('filteredQuizzes', 'sortProps')
  });
});
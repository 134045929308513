define('vimme/routes/guardian/resources', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    model: function model() {
      var week0 = Ember.Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var grade0 = Ember.Object.create({
        id: 'none',
        title: 'General',
        shortTitle: 'General'
      });
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      var weeks = [week0];
      var grades = [grade0];
      if (localWeeks.get('length')) {
        weeks = weeks.concat(localWeeks.toArray());
      } else {
        weeks = this.store.findAll('week').then(function (moreWeeks) {
          return weeks.concat(moreWeeks.toArray());
        });
      }
      if (localGrades.get('length')) {
        grades = grades.concat(localGrades.toArray());
      } else {
        grades = this.store.findAll('grade').then(function (moreGrades) {
          return grades.concat(moreGrades.toArray());
        });
      }
      return Ember.RSVP.hash({
        grades: grades,
        weeks: weeks
      });
    }
  });
});
define('vimme/models/school-year-weekly-report', ['exports', 'ember-data', 'vimme/utils/round'], function (exports, _emberData, _round) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    average: _emberData.default.attr('number'),

    week: _emberData.default.belongsTo('week', { async: true }),
    schoolYearCourseReport: _emberData.default.belongsTo('schoolYearCourseReport', { async: true }),

    roundedPercent: function () {
      var percent = this.get('average');
      if (percent) {
        return (0, _round.default)(percent);
      }
    }.property('average'),

    formattedPercent: function () {
      var percent = this.get('roundedPercent');
      if (percent) {
        return percent + '%';
      }
    }.property('roundedPercent')
  });
});
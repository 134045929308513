define('vimme/routes/teacher/builder/assignments/assignment/question-bank', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: this.store.findAll('difficulty'),
        grades: this.store.all('grade')
      });
    }
  });
});
define('vimme/routes/sub-admin/reports/mathbots/index', ['exports', 'vimme/routes/sub-admin', 'vimme/config/environment'], function (exports, _subAdmin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    queryParams: {
      classroom: {
        refreshModel: true
      },
      grade: {
        refreshModel: true
      },
      school: {
        refreshModel: true
      }
    },

    authAjax: Ember.inject.service(),
    model: function model(_ref) {
      var classroomId = _ref.classroom,
          gradeId = _ref.grade,
          schoolId = _ref.school;

      var schools = this.modelFor('sub-admin.reports').schools;
      var grades = this.modelFor('sub-admin.reports').grades;
      var classrooms = gradeId ? this.store.find('classroom', { grade: gradeId, school: schoolId }) : [];
      var classroom = classroomId ? this.store.find('classroom', classroomId) : null;
      var grade = gradeId ? this.store.find('grade', gradeId) : null;
      var school = schoolId ? this.store.find('school', schoolId) : null;
      if (!classroom) {
        return Ember.RSVP.hash({
          grades: grades,
          grade: grade,
          schools: schools,
          school: school,
          classrooms: classrooms,
          classroom: classroom
        });
      }
      var levels = this.store.findAll('level');
      var students = classroom.then(function (classroom) {
        return classroom.get('students');
      });
      return this.get('authAjax').request(_environment.default.API.gameHost + '/reports/classroom?classroom=' + classroomId).then(function (_ref2) {
        var attempts = _ref2.attempts;

        return Ember.RSVP.hash({
          levels: levels,
          students: students,
          attempts: attempts
        }).then(function (_ref3) {
          var levels = _ref3.levels,
              students = _ref3.students,
              attempts = _ref3.attempts;

          students = students.sortBy('lastThenFirst');
          var averages = levels.map(function (level) {
            var passed = 0;
            var total = 0;
            students.forEach(function (student) {
              var studentAttempts = attempts[student.get('userId')];
              var studentLevel = studentAttempts && studentAttempts[level.get('id')];
              if (studentLevel && studentLevel.passed) {
                passed++;
              }
              total++;
            });
            return Math.round(passed / total * 100);
          });
          return Ember.RSVP.hash({
            levels: levels,
            students: students,
            attempts: attempts,
            averages: averages,
            classrooms: classrooms,
            classroom: classroom,
            grades: grades,
            grade: grade,
            schools: schools,
            school: school
          });
        });
      });
    }
  });
});
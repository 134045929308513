define('vimme/routes/admin/builder/tests/test', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      var test = this.store.find('test', params.test_id);
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      return Ember.RSVP.hash({
        test: test,
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week')
      });
    }
  });
});
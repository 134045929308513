define('vimme/controllers/sub-admin/questions/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    questionsController: Ember.inject.controller('sub-admin/questions'),

    studentQuestions: Ember.computed.alias('questionsController.studentQuestions'),

    isReviewing: Ember.computed.alias('questionsController.isReviewing'),

    studentQuestion: function () {
      var studentQuestions = this.get('studentQuestions');

      if (studentQuestions && studentQuestions.get('length') > 0) {
        return studentQuestions.findBy('question.id', this.get('content.id'));
      }
    }.property('studentQuestions.[]', 'content')
  });
});
define('vimme/routes/teacher/paper-assignment', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = _teacher.default.extend({
    model: function model(params) {
      return RSVP.hash({
        quizQuestions: this.store.find('quizQuestion', { quiz_question_ids: params.paper_assignment_question_ids }),
        quiz: params.paper_assignment_id === 'new' ? undefined : this.store.find('quiz', params.paper_assignment_id)
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (window.zE) {
        window.zE(function () {
          window.zE.hide();
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideHeader', true);
    },
    resetController: function resetController() {
      this.controllerFor('application').set('hideHeader', false);
    }
  });
});
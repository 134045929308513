define('vimme/models/student-test', ['exports', 'ember-data', 'vimme/utils/round'], function (exports, _emberData, _round) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    attempt: _emberData.default.attr('number'),
    score: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),

    startedAt: _emberData.default.attr('isodate'),
    submittedAt: _emberData.default.attr('isodate'),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate'),

    answers: _emberData.default.hasMany('answer', { async: true }),
    student: _emberData.default.belongsTo('student', { async: true }),
    test: _emberData.default.belongsTo('test', { async: true }),
    scheduledTest: _emberData.default.belongsTo('scheduledTest', { async: true }),
    studentQuestions: _emberData.default.hasMany('studentQuestion', { async: true }),

    isSubmitted: Ember.computed('submittedAt', function () {
      return !!this.get('submittedAt');
    }),

    percent: function () {
      var percent = this.get('score') / this.get('total') * 100;

      return percent;
    }.property('total', 'score'),

    roundedPercent: function () {
      return (0, _round.default)(this.get('percent'));
    }.property('percent'),

    formattedPercent: function () {
      return this.get('roundedPercent') + '%';
    }.property('roundedPercent')
  });
});
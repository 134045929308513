define("vimme/components/app-pages/student/assignments/assignment/answer/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "vimme/components/app-pages/student/assignments/assignment/answer/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["question-answer is-selectable ", ["subexpr", "if", [["get", "this.isLoading", ["loc", [null, [2, 46], [2, 60]]]], "is-unsavable"], [], ["loc", [null, [2, 41], [2, 77]]]], " ", ["subexpr", "if", [["get", "this.isSelected", ["loc", [null, [2, 83], [2, 98]]]], "is-selected"], [], ["loc", [null, [2, 78], [2, 114]]]]]]], ["element", "action", [["get", "this.toggleAnswer", ["loc", [null, [3, 13], [3, 30]]]], ["get", "this.answer", ["loc", [null, [3, 31], [3, 42]]]]], [], ["loc", [null, [3, 4], [3, 44]]]], ["content", "this.answer.title", ["loc", [null, [5, 4], [5, 25]]]]],
      locals: [],
      templates: []
    };
  }());
});
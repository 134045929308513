define('vimme/controllers/school-admin/management/sub-admins/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    schoolAdminController: Ember.inject.controller('schoolAdmin'),
    school: Ember.computed.alias('schoolAdminController.model.user.school'),

    lastName: '',
    firstName: '',
    email: '',
    password: '',

    username: Ember.computed.alias('email'),

    resetVals: function () {
      this.set('lastName', '');
      this.set('firstName', '');
      this.set('email', '');
      this.set('password', '');
    }.on('init'),

    isValid: function () {
      var lastName = this.get('lastName'),
          firstName = this.get('firstName'),
          email = this.get('email'),
          password = this.get('password');

      return !!lastName && !!firstName && !!email && !!password && password.length >= 5;
    }.property('lastName', 'firstName', 'email', 'password'),

    isUnsavable: Ember.computed.not('isValid'),

    actions: {
      save: function save() {
        var _this = this;

        this.get('school').then(function (school) {
          var subAdmin = _this.store.createRecord('subAdmin', {
            lastName: _this.get('lastName'),
            firstName: _this.get('firstName'),
            school: school,
            email: _this.get('email'),
            username: _this.get('username'),
            password: _this.get('password')
          });

          subAdmin.save().then(function () {
            _this.set('lastName', '');
            _this.set('firstName', '');
            _this.set('email', '');
            _this.set('password', '');
          }).catch(function () {
            subAdmin.deleteRecord();
          });
        });
      }
    }
  });
});
define('vimme/components/app-pages/school-admin/teachers/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    searchTerm: '',

    classrooms: Ember.computed(function () {
      return [];
    }),
    courses: Ember.computed(function () {
      return [];
    }),
    grades: Ember.computed(function () {
      return [];
    }),

    filteredTeachers: Ember.computed('teachers.@each.{lastThenFirst,isNew,isDeleted,isPlaceholder}', 'searchTerm', function () {
      var teachers = this.get('teachers').filterBy('isNew', false).filterBy('isDeleted', false).filterBy('isPlaceholder', false);

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');

      return teachers.filter(function (teacher) {
        if (!term) return true;
        return filter.test(teacher.get('lastThenFirst'));
      });
    }),
    teacherSortParams: ['lastThenFirst'],
    sortedTeachers: Ember.computed.sort('filteredTeachers', 'teacherSortParams')
  });
});
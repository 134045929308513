define('vimme/routes/admin/builder/tests/test/questions/question', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      var question = this.store.find('question', params.question_id);
      var standards = this.store.peekAll('standard');

      var _modelFor = this.modelFor('admin'),
          tests = _modelFor.tests;

      var _modelFor2 = this.modelFor('admin.builder.tests.test.questions'),
          grade = _modelFor2.grade,
          test = _modelFor2.test;

      return Ember.RSVP.hash({
        grade: grade,
        question: question,
        standards: standards,
        test: test,
        tests: tests.filter(function (t) {
          return t.get('grade.id') === grade.get('id') && t.get('id') !== test.get('id');
        })
      });
    }
  });
});
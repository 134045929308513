define('vimme/components/date-formatter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    date: moment(),

    format: 'l',

    result: function () {
      if (this.get('date')) {
        return moment(this.get('date')).format(this.get('format'));
      }

      return '';
    }.property('date', 'format')
  });
});
define('vimme/controllers/school-admin/reports/standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['course', 'classroom'],

    course: null,
    classroom: null
  });
});
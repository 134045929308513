define('vimme/components/vimme-testing-calendar-student-test/component', ['exports', 'vimme/utils/date-in-range'], function (exports, _dateInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['testing-calendar-test'],
    classNameBindings: ['isSelected'],

    // external
    scheduledTest: null,
    selectedDate: null,

    firstMissed: 1,

    studentTests: Ember.computed.alias('scheduledTest.studentTests'),

    isSelected: function () {
      var selected = this.get('selectedDate'),
          startDate = moment(this.get('scheduledTest.startDate')),
          endDate = moment(this.get('scheduledTest.endDate'));

      return !!selected && (0, _dateInRange.default)(selected, startDate, endDate);
    }.property('selectedDate', 'scheduledTest.{startDate,endDate}'),

    studentTest: function () {
      return this.get('scheduledTest.studentTests.firstObject');
    }.property('scheduledTest.studentTests.[]'),

    isSubmitted: Ember.computed.alias('studentTest.isSubmitted'),

    isTakable: function () {
      var isSubmitted = this.get('isSubmitted');
      var isActive = this.get('scheduledTest.isActive');
      var isPaused = this.get('scheduledTest.isPaused');

      return isActive && !isSubmitted && !isPaused;
    }.property('scheduledTest.{isActive,isPaused}', 'isSubmitted'),

    isRetakable: function () {
      var isSubmitted = this.get('isSubmitted');
      var isActive = this.get('scheduledTest.isActive');
      var isPaused = this.get('scheduledTest.isPaused');
      var maxAttempts = this.get('scheduledTest.maxAttempts');
      var attempt = this.get('studentTest.attempt');

      return isActive && isSubmitted && !isPaused && maxAttempts > attempt;
    }.property('scheduledTest.{isActive,isPaused,maxAttempts}', 'studentTest.attempt', 'isSubmitted'),

    isReviewable: function () {
      return this.get('scheduledTest.isReviewable') && this.get('scheduledTest.isViewable') && this.get('isSubmitted');
    }.property('scheduledTest.{isReviewable,isViewable}', 'isSubmitted'),

    findFirstMissed: function () {
      var isReviewable = this.get('isReviewable'),
          studentQuestions,
          firstMissed = 1,
          _this = this;

      if (isReviewable) {
        studentQuestions = this.get('studentTest.studentQuestions');
      }

      if (studentQuestions) {
        studentQuestions.then(function (studentQuestions) {
          var questionsPromises = studentQuestions.map(function (sq) {
            return sq.get('question');
          });

          Ember.RSVP.all(questionsPromises).then(function () {
            studentQuestions.toArray().sort(function (prev, next) {
              var prevOrder = prev.get('question.order');
              var nextOrder = next.get('question.order');

              if (prevOrder && nextOrder) {
                return parseInt(prevOrder, 10) - parseInt(nextOrder, 10);
              }
              if (prevOrder) {
                return -1;
              }
              if (nextOrder) {
                return 1;
              }

              return parseInt(prev.get('question.id'), 10) - parseInt(next.get('question.id'), 10);
            }).find(function (question) {
              if (!question.get('isCorrect')) {
                return true;
              }

              firstMissed++;

              return false;
            });

            if (firstMissed > studentQuestions.get('length')) {
              _this.set('firstMissed', 1);
            } else {
              _this.set('firstMissed', firstMissed);
            }
          });
        });
      }
    }.on('init').observes('isReviewable', 'studentTest'),

    scrollTo: function () {
      var isSelected = this.get('isSelected');

      if (isSelected) {
        Ember.run.later(this, function () {
          var el = Ember.$('.testing-calendar-test.is-selected'),
              parent = el.parent(),
              offset = el.position().top;

          if (offset < 0 || offset > parent.height()) {
            offset = parent.scrollTop() + offset;

            parent.animate({ scrollTop: offset - 8 }, 300);
          }
        });
      }
    }.observes('isSelected'),

    actions: {
      gotoTest: function gotoTest() {
        this.sendAction.apply(this, ['gotoTest'].concat(Array.prototype.slice.call(arguments)));
      },
      gotoTestReview: function gotoTestReview() {
        this.sendAction.apply(this, ['gotoTestReview'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});
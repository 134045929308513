define('vimme/routes/guardian/questions', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'guardian.questions.index') {
        if (transition.queryParams && transition.queryParams['test-review-id']) {
          this.replaceWith('guardian.questions.question', 1, {
            queryParams: { testReviewID: transition.queryParams['test-review-id'] }
          });
        } else {
          this.replaceWith('guardian.questions.question', 1);
        }
      }
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        studentTests: _this.modelFor('guardian.test').get('studentTests'),
        questions: _this.modelFor('guardian.test').get('questions'),
        answers: _this.modelFor('guardian.test').get('answers')
      });
    }
  });
});
define('vimme/controllers/sub-admin/reports/growth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['course', 'classroom', 'from', 'to'],

    course: '',
    classroom: '',
    from: '',
    to: '',

    selectedCourse: null,
    selectedClassroom: null,
    selectedFrom: null,
    selectedTo: null,

    actions: {
      setCourse: function setCourse(course) {
        var _this = this;

        if (course) {
          this.store.query('classroom', { course: course.id }).then(function (classrooms) {
            _this.set('classrooms', classrooms);
            _this.set('course', course.id);
            _this.set('classroom', '');
            _this.set('selectedCourse', course);
            _this.set('selectedClassroom', null);
          });
        } else {
          this.set('students', null);
          this.set('classrooms', null);
          this.set('course', '');
          this.set('classroom', '');
          this.set('selectedCourse', course);
          this.set('selectedClassroom', null);
        }
      },
      setClassroom: function setClassroom(classroom) {
        var _this2 = this;

        if (classroom) {
          classroom.get('students').then(function (students) {
            _this2.set('students', students);
            _this2.set('classroom', classroom.id);
            _this2.set('selectedClassroom', classroom);
          });
        } else {
          this.set('students', null);
          this.set('classroom', '');
          this.set('selectedClassroom', null);
        }
      },
      setFrom: function setFrom(from) {
        this.set('from', from.id);
        this.set('selectedFrom', from);
      },
      setTo: function setTo(to) {
        this.set('to', to.id);
        this.set('selectedTo', to);
      }
    }
  });
});
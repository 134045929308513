define('vimme/routes/school-admin/reports/week', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      var schoolAdmin = this.modelFor('school-admin').user;
      return Ember.RSVP.hash({
        courses: this.modelFor('school-admin.reports').courses,
        schoolAdmin: schoolAdmin,
        school: schoolAdmin.get('school'),
        schoolYears: this.modelFor('school-admin.reports').schoolYears,
        weeks: this.modelFor('school-admin.reports').weeks
      });
    }
  });
});
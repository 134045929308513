define("vimme/components/app-pages/teacher/assignment-builder/list-item/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/assignment-builder/list-item/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/assignment-builder/list-item/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/teacher/assignment-builder/question-preview", [], ["question", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [27, 17], [27, 30]]]]], [], []], "editModel", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [28, 18], [28, 31]]]]], [], []], "close", ["subexpr", "action", ["hidePreview"], [], ["loc", [null, [29, 14], [29, 36]]]]], ["loc", [null, [26, 4], [30, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 7
          }
        },
        "moduleName": "vimme/components/app-pages/teacher/assignment-builder/list-item/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        dom.setAttribute(el1, "class", "quiz-builder__question no-swipe sortable-item");
        dom.setAttribute(el1, "style", "padding-right: 120px;");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "quiz-builder__question-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(". ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-icon-only btn-purple icon-eye");
        dom.setAttribute(el2, "style", "right: 80px;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "style", "right: 0;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element0, [9]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(element2, 0, 0);
        morphs[2] = dom.createMorphAt(element2, 2, 2);
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createMorphAt(element0, 7, 7);
        morphs[5] = dom.createAttrMorph(element4, 'class');
        morphs[6] = dom.createAttrMorph(element4, 'disabled');
        morphs[7] = dom.createElementMorph(element4);
        morphs[8] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["quiz-builder__question-reorder btn btn-icon-only instant icon-reorder ", ["subexpr", "if", [["get", "this.isReadOnly", ["loc", [null, [2, 89], [2, 104]]]], "disabled"], [], ["loc", [null, [2, 84], [2, 117]]]]]]], ["content", "this.number", ["loc", [null, [3, 46], [3, 61]]]], ["content", "this.question.title", ["loc", [null, [3, 63], [3, 86]]]], ["element", "action", ["showPreview"], [], ["loc", [null, [7, 8], [7, 32]]]], ["block", "link-to", ["teacher.builder.assignments.assignment.question", ["get", "this.question.id", ["loc", [null, [11, 8], [11, 24]]]]], ["class", ["subexpr", "concat", ["vimme-hoverable-icon btn btn-icon-only btn-orange icon-edit", ["subexpr", "if", [["get", "this.isReadOnly", ["loc", [null, [14, 22], [14, 37]]]], " disabled"], [], ["loc", [null, [14, 18], [14, 50]]]]], [], ["loc", [null, [12, 14], [15, 15]]]]], 0, null, ["loc", [null, [9, 4], [16, 18]]]], ["attribute", "class", ["concat", ["btn btn-icon-only btn-cancel icon-delete ", ["subexpr", "if", [["get", "this.isReadOnly", ["loc", [null, [18, 61], [18, 76]]]], "disabled"], [], ["loc", [null, [18, 56], [18, 89]]]]]]], ["attribute", "disabled", ["get", "this.isLoading", ["loc", [null, [20, 19], [20, 33]]]]], ["element", "action", [["get", "this.delete", ["loc", [null, [21, 17], [21, 28]]]]], [], ["loc", [null, [21, 8], [21, 30]]]], ["block", "if", [["get", "this.isPreviewVisible", ["loc", [null, [25, 6], [25, 27]]]]], [], 1, null, ["loc", [null, [25, 0], [31, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
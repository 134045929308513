define('vimme/controllers/sub-admin/reports/week', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['course', 'teacher', 'print'],

    course: null,
    teacher: null,
    print: false
  });
});
define('vimme/routes/teacher/builder/paper-assignments/index', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      var teacher = this.modelFor('teacher.builder.paper-assignments').teacher;
      return Ember.RSVP.hash({
        categories: this.modelFor('teacher.builder.paper-assignments').categories,
        difficulties: this.modelFor('teacher.builder.paper-assignments').difficulties,
        grades: this.modelFor('teacher.builder.paper-assignments').grades,
        quiz: this.store.createRecord('quiz', {
          title: null,
          teacher: teacher
        }),
        teacher: teacher
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
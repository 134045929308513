define('vimme/routes/application', ['exports', 'vimme/utils/embed-zendesk', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/configuration', 'vimme/config/environment', 'npm:jwt-decode'], function (exports, _embedZendesk, _applicationRouteMixin, _configuration, _environment, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),

    routeAfterAuthentication: Ember.computed('session.data.authenticated.token', function () {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref.role;

      switch (role) {
        case 'Admin':
          return 'admin.management.schools';
        case 'SchoolAdmin':
          return 'school-admin.schedule-tests';
        case 'SubAdmin':
          return 'sub-admin.reports.week';
        case 'Teacher':
          return 'teacher.testing-calendar';
        case 'Student':
          return 'student.testing-calendar';
        case 'Guardian':
          return 'guardian.profile';
      }
      return _configuration.default.routeAfterAuthentication;
    }),

    trackUserForStonly: function trackUserForStonly() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode.default)(token) : {},
          isSubscription = _ref2.isSubscription,
          isTrial = _ref2.isTrial,
          role = _ref2.role,
          sub = _ref2.sub;

      var appId = void 0;
      switch (_environment.default.environment) {
        case 'core':
          appId = 'core';
          break;
        case 'production':
          appId = 'app';
          break;
        case 'demo':
          appId = 'demo';
          break;
        case 'classrooms':
          appId = 'classrooms';
          break;
        default:
          appId = 'local';
          break;
      }
      stonlyTrack('identify', appId + '-' + sub, {
        'app-id': appId,
        trial: !!isTrial,
        subscription: !!isSubscription,
        role: role
      });
    },
    _subscribeToSessionEvents: function _subscribeToSessionEvents() {
      var _this = this;

      Ember.A([['authenticationSucceeded', 'sessionAuthenticated'], ['invalidationSucceeded', 'sessionInvalidated']]).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            event = _ref4[0],
            method = _ref4[1];

        _this.get('session').on(event, function () {
          return _this[method].apply(_this, arguments);
        });
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      this.preloadsInit().then(function () {
        _this2.trackUserForStonly();

        var attemptedTransition = _this2.get('session.attemptedTransition');
        var cookies = _this2.get('cookies');
        var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

        if (attemptedTransition) {
          attemptedTransition.retry();
          _this2.set('session.attemptedTransition', null);
        } else if (redirectTarget) {
          _this2.transitionTo(redirectTarget);
          cookies.clear('ember_simple_auth-redirectTarget');
        } else {
          _this2.transitionTo(_this2.get('routeAfterAuthentication'));
        }
      });
    },
    getPostAuthPreloads: function getPostAuthPreloads() {
      var token = this.get('session.data.authenticated.token');

      var _ref5 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref5.role,
          id = _ref5.id;

      var grades = this.store.findAll('grade');
      var weeks = this.store.findAll('week');
      var user = this.store.find(role, id);

      var controller = this.controllerFor('application');

      user.then(function (u) {
        return controller.set('user', u);
      });

      return Ember.RSVP.all([grades, user, weeks, role]);
    },
    preloadsInit: function preloadsInit() {
      return this.getPostAuthPreloads().then(function (_ref6) {
        var _ref7 = _slicedToArray(_ref6, 4),
            grades = _ref7[0],
            user = _ref7[1],
            weeks = _ref7[2],
            role = _ref7[3];

        if (!['Student', 'Admin'].includes(role)) {
          (0, _embedZendesk.default)(function () {
            window.zE(function () {
              window.zE.identify({
                name: user.get('fullName'),
                email: user.get('email')
              });
            });
          });
        }

        return [grades, user, weeks, role];
      });
    },
    beforeModel: function beforeModel(transition) {
      var _this3 = this;

      this._super(transition);

      var _ref8 = transition.queryParams || {},
          token = _ref8.token;

      if (token) {
        var decoded = (0, _npmJwtDecode.default)(token);
        var authenticator = 'authenticator:jwt';

        localStorage.setItem('ember_simple_auth-session', JSON.stringify({ authenticated: { authenticator: authenticator, token: token, exp: decoded.exp } }));
        window.location.href = window.location.href.replace(window.location.search, '');
        return new Promise(function () {});
      }

      return this.get('ajax').request(_environment.default.API.host + '/preloads').then(function (data) {
        _this3.store.pushPayload(data);
      }).then(function () {
        if (_this3.get('session.isAuthenticated')) {
          _this3.trackUserForStonly();
          return _this3.preloadsInit();
        }
      });
    },


    actions: {
      // error: function() {
      //     this.transitionTo('index');
      // },
      gotoTest: function gotoTest(id, model, queryParams) {
        var token = this.get('session.data.authenticated.token');

        var _decode = (0, _npmJwtDecode.default)(token),
            role = _decode.role;

        this.controller.set('savedRoute', this.controller.get('currentRouteName'));

        if (model) {
          this.controller.set('savedModel', model);
        }

        var dasherizedRole = role.dasherize();
        var routePrefix = dasherizedRole === 'student' ? 'student.test' : dasherizedRole;
        var route = routePrefix + '.questions';
        this.transitionTo(route, id, { queryParams: _extends({}, queryParams, { testReviewID: 'resetme' }) });
      },
      gotoTestReview: function gotoTestReview(id, questionNum, queryParam, model, queryParams) {
        var token = this.get('session.data.authenticated.token');

        var _decode2 = (0, _npmJwtDecode.default)(token),
            role = _decode2.role;

        if (!questionNum) {
          questionNum = 1;
        }

        this.controller.set('savedRoute', this.controller.get('currentRouteName'));

        if (model) {
          this.controller.set('savedModel', model);
        }

        var dasherizedRole = role.dasherize();
        var routePrefix = dasherizedRole === 'student' ? 'student.test' : dasherizedRole;
        var route = routePrefix + '.questions.question';
        this.transitionTo(route, id, questionNum, {
          queryParams: _extends({}, queryParams, { testReviewID: queryParam })
        });
      }
    }
  });
});
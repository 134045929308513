define("vimme/templates/teacher/testing-calendar", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/teacher/testing-calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/teacher/testing-calendar", [], ["classrooms", ["subexpr", "@mut", [["get", "this.model.classrooms", ["loc", [null, [2, 15], [2, 36]]]]], [], []], "pacingGuides", ["subexpr", "@mut", [["get", "this.pacingGuides", ["loc", [null, [3, 17], [3, 34]]]]], [], []], "scheduledTests", ["subexpr", "@mut", [["get", "this.sortedScheduledTests", ["loc", [null, [4, 19], [4, 44]]]]], [], []], "tests", ["subexpr", "@mut", [["get", "this.sortedTests", ["loc", [null, [5, 10], [5, 26]]]]], [], []], "school", ["subexpr", "@mut", [["get", "this.model.school", ["loc", [null, [6, 11], [6, 28]]]]], [], []], "gotoTest", "gotoTest", "store", ["subexpr", "@mut", [["get", "this.store", ["loc", [null, [8, 10], [8, 20]]]]], [], []]], ["loc", [null, [1, 0], [9, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('vimme/components/vimme-question-view-row/component', ['exports', 'vimme/utils/pad-student-questions'], function (exports, _padStudentQuestions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    questions: null,
    studentQuestions: null,

    paddedStudentQuestions: function () {
      var studentQuestions = this.get('studentQuestions');
      var questions = this.get('questions');

      if (!questions) return;

      return (0, _padStudentQuestions.default)(studentQuestions, questions);
    }.property('studentQuestions')
  });
});
define('vimme/components/vimme-calendar/component', ['exports', 'vimme/utils/date-in-range'], function (exports, _dateInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-calendar'],

    // external
    selected: null,
    scheduled: null,

    // internal
    i_selected: null,

    updateSelected: function () {
      var selected = this.get('selected'),
          i_selected = this.get('i_selected');

      function shouldUpdate() {
        return !i_selected || !selected && i_selected || !!moment(selected).diff(i_selected);
      }

      if (shouldUpdate()) {
        this.set('i_selected', selected);
      }
    }.on('init').observes('selected'),

    i_scheduled: [],

    updateScheduled: function () {
      var scheduled = this.get('scheduled');

      if (scheduled) {
        this.set('i_scheduled', scheduled);
      } else {
        this.set('i_scheduled', []);
      }
    }.on('init').observes('scheduled.@each.{startDate,startAt,endDate,endAt}'),

    today: function () {
      return moment().startOf('day').toDate();
    }.property().volatile(),

    thisMonth: function () {
      return moment().startOf('month').toDate();
    }.property().volatile(),

    calendarMonth: null,

    initCalendarMonth: function () {
      this.set('calendarMonth', this.get('thisMonth'));
    }.on('init'),

    startDay: function () {
      var startDayNum = moment(this.get('calendarMonth')).day();

      return moment(this.get('calendarMonth')).subtract(startDayNum, 'days').toDate();
    }.property('calendarMonth'),

    endDay: function () {
      return moment(this.get('startDay')).add(41, 'days').toDate();
    }.property('startDay'),

    days: function () {
      var startDay = this.get('startDay'),
          scheduled = this.get('i_scheduled'),
          days = [];

      for (var i = 0; i < 42; i++) {
        days.push({
          date: moment(startDay).add(i, 'days').toDate(),
          isScheduled: !!scheduled.find(function (item) {
            return (0, _dateInRange.default)(moment(startDay).add(i, 'days'), Ember.get(item, 'startDate') || Ember.get(item, 'startAt'), Ember.get(item, 'endDate') || Ember.get(item, 'endAt'));
          })
        });
      }

      return days;
    }.property('startDay', 'i_scheduled.@each.{startDate,startAt,endDate,endAt}'),

    updateCalendarMonthFromSelected: function () {
      var selected = this.get('i_selected'),
          startDay = this.get('startDay'),
          endDay = this.get('endDay');

      if (!!selected && (0, _dateInRange.default)(selected, startDay, endDay)) {
        this.set('calendarMonth', moment(selected).startOf('month').toDate());
      }
    }.observes('i_selected'),

    incrementCalendarMonth: function incrementCalendarMonth() {
      var months = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      this.set('calendarMonth', moment(this.get('calendarMonth')).add(months, 'months').toDate());
    },
    decrementCalendarMonth: function decrementCalendarMonth() {
      var months = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      this.set('calendarMonth', moment(this.get('calendarMonth')).subtract(months, 'month').toDate());
    },

    prevMonth: function prevMonth() {
      this.decrementCalendarMonth();
    },
    nextMonth: function nextMonth() {
      this.incrementCalendarMonth();
    }
  });
});
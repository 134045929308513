define('vimme/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'vimme/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    authenticationRoute: "index",
    refreshAccessTokens: false,
    serverTokenEndpoint: _environment.default.API.tokenEndpoint,

    makeRequest: function makeRequest(url, data) {
      data.client_id = _environment.default.API.clientID;
      data.client_secret = _environment.default.API.clientSecret;
      return this._super(url, data);
    }
  });
});
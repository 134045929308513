define('vimme/routes/teacher/reports/growth', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    queryParams: {
      classroom: {
        replace: true
      },
      from: {
        replace: true
      },
      to: {
        replace: true
      }
    },

    model: function model(params) {
      var _modelFor = this.modelFor('teacher.reports'),
          classrooms = _modelFor.classrooms,
          user = _modelFor.user,
          weeks = _modelFor.weeks;

      var selectedFrom = params.from ? weeks.find(function (week) {
        return week.get('id') === params.from;
      }) : undefined;
      var selectedTo = params.to ? weeks.find(function (week) {
        return week.get('id') === params.to;
      }) : undefined;
      var selectedClassroom = params.classroom ? classrooms.find(function (c) {
        return c.get('id') === params.classroom;
      }) : undefined;
      var students = selectedClassroom ? selectedClassroom.get('students') : [];
      var grade = selectedClassroom && selectedClassroom.get('grade');

      return Ember.RSVP.hash({
        classrooms: classrooms,
        grade: grade,
        params: params,
        selectedClassroom: selectedClassroom,
        selectedFrom: selectedFrom,
        selectedTo: selectedTo,
        students: students,
        user: user,
        weeks: weeks
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('grade', model.grade && model.grade.get('id'));
      controller.set('selectedClassroom', model.selectedClassroom);
      controller.set('selectedFrom', model.selectedFrom);
      controller.set('selectedTo', model.selectedTo);
      controller.set('students', model.students);
    }
  });
});
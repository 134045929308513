define("vimme/templates/sub-admin/reports/mathbots/competition", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/sub-admin/reports/mathbots/competition.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "mathbots-classroom-reporting", [], ["attempts", ["subexpr", "@mut", [["get", "model.attempts", ["loc", [null, [2, 13], [2, 27]]]]], [], []], "averages", ["subexpr", "@mut", [["get", "model.averages", ["loc", [null, [3, 13], [3, 27]]]]], [], []], "classroom", ["subexpr", "@mut", [["get", "model.classroom", ["loc", [null, [4, 14], [4, 29]]]]], [], []], "classrooms", ["subexpr", "@mut", [["get", "model.classrooms", ["loc", [null, [5, 15], [5, 31]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [6, 11], [6, 23]]]]], [], []], "grade", ["subexpr", "@mut", [["get", "model.grade", ["loc", [null, [7, 10], [7, 21]]]]], [], []], "students", ["subexpr", "@mut", [["get", "model.students", ["loc", [null, [8, 13], [8, 27]]]]], [], []], "leaderboards", ["subexpr", "@mut", [["get", "model.leaderboards", ["loc", [null, [9, 17], [9, 35]]]]], [], []], "levels", ["subexpr", "@mut", [["get", "model.levels", ["loc", [null, [10, 11], [10, 23]]]]], [], []], "school", ["subexpr", "@mut", [["get", "model.school", ["loc", [null, [11, 11], [11, 23]]]]], [], []], "schools", ["subexpr", "@mut", [["get", "model.schools", ["loc", [null, [12, 12], [12, 25]]]]], [], []], "setClassroom", ["subexpr", "action", ["setClassroom"], [], ["loc", [null, [13, 17], [13, 40]]]], "setGrade", ["subexpr", "action", ["setGrade"], [], ["loc", [null, [14, 13], [14, 32]]]], "setSchool", ["subexpr", "action", ["setSchool"], [], ["loc", [null, [15, 14], [15, 34]]]], "signedUp", true], ["loc", [null, [1, 0], [17, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('vimme/components/app-pages/teacher/assignments/assignment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    assignment: undefined,
    question: undefined,
    questions: Ember.computed(function () {
      return [];
    }),

    number: 1,
    total: Ember.computed.alias('questions.length'),

    hideAnswers: true,
    showAnswers: Ember.computed.not('hideAnswers'),

    prev: Ember.computed('number', function () {
      return this.get('number') - 1;
    }),
    next: Ember.computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: Ember.computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: Ember.computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('tool', undefined);
    },


    actions: {
      toggleAnswers: function toggleAnswers() {
        this.toggleProperty('hideAnswers');
      },
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      }
    }
  });
});
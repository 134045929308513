define('vimme/controllers/admin/builder/paper-assignment-questions/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['categories', 'difficulties', 'grades', 'page', 'sort'],
    categories: '',
    difficulties: null,
    grades: '',
    page: 1,
    sort: 'grade',

    visibleCategoriesCache: [],
    visibleCategories: function (key, value) {
      var _this = this;

      if (arguments.length > 1) {
        this.set('visibleCategoriesCache', value);
        return value;
      }
      this.store.find('category', {
        grades: this.get('grades')
      }).then(function (categories) {
        _this.set('visibleCategories', categories);
      });
      return this.get('visibleCategoriesCache');
    }.property('grades'),

    quizQuestionsCache: [],
    quizQuestions: function (key, value) {
      var _this2 = this;

      if (arguments.length > 1) {
        this.set('quizQuestionsCache', value);
        return value;
      }
      this.store.find('quiz-question', {
        categories: this.get('categories'),
        difficulties: this.get('difficulties'),
        grades: this.get('grades'),
        page: this.get('page'),
        sort: this.get('sort')
      }).then(function (quizQuestions) {
        _this2.set('quizQuestions', quizQuestions);
      });
      return this.get('quizQuestionsCache');
    }.property('categories', 'difficulties', 'grades', 'page', 'sort')
  });
});
define('vimme/controllers/admin/builder/tests/test/questions/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allowedTests: Ember.computed('model.tests.@each.isDraft', 'model.user.canEditLiveTests', function () {
      if (this.get('model.user.canEditLiveTests')) {
        return this.get('model.tests');
      }
      return this.get('model.tests').filterBy('isDraft', true);
    }),

    unsorted: Ember.computed('allowedTests.@each.{isNew,isDeleted}', function () {
      var tests = this.get('allowedTests').filterBy('isNew', false).filterBy('isDeleted', false);
      return tests;
    }),

    filteredTests: Ember.computed('model.test', 'unsorted', function () {
      var raw = this.get('unsorted');
      var currentTest = this.get('model.test');
      var filtered = raw.filter(function (test) {
        var isCurrentTest = currentTest.get('id') == test.get('id');
        var isSameGrade = currentTest.get('gradeSuffix') == test.get('gradeSuffix');
        return !isCurrentTest && isSameGrade;
      });

      return filtered;
    }),

    sortProps: ['week.number', 'grade.id'],
    filteredAndSortedTests: Ember.computed.sort('filteredTests', 'sortProps'),

    actions: {
      viewTest: function viewTest(id, model) {
        this.send('gotoTest', id, model);
      },
      saveTest: function saveTest(test) {
        var _this = this;

        test.save().then(function (test) {
          return _this.transitionTo('admin.builder.tests.test', test.get('id'));
        }).catch(function (error) {
          return alert(error.message);
        });
      },
      deleteTest: function deleteTest(test) {
        var _this2 = this;

        test.destroyRecord().then(function () {
          return _this2.transitionTo('admin.builder.tests');
        }).catch(function (error) {
          return alert(error.message);
        });
      }
    }
  });
});
define('vimme/routes/school-admin/reports/yearly', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(_ref) {
      var school_year_id = _ref.school_year_id;

      var _modelFor = this.modelFor('school-admin.reports'),
          weeks = _modelFor.weeks,
          grades = _modelFor.grades,
          schoolYears = _modelFor.schoolYears;

      var schoolYear = schoolYears.findBy('id', school_year_id);
      return Ember.RSVP.hash({
        weeks: weeks,
        grades: grades,
        schoolYear: schoolYear,
        schoolYears: schoolYears,
        schoolYearCourseReports: schoolYear.get('schoolYearCourseReports')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controllerFor('school-admin.reports').set('selectedSchoolYear', model.schoolYear);
      controller.set('averages', model.schoolYearCourseReports);
      controller.set('currentSortDirection', 'desc');
      controller.set('currentSortColumn', 'grade-column');
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        this.controllerFor('school-admin.reports').set('selectedSchoolYear', null);
      }
    }
  });
});
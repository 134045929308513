define('vimme/controllers/guardian/pacing-guides', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selectedCourse: null,

    pacingGuide: function () {
      var course = this.get('selectedCourse');

      if (!course) {
        return null;
      }

      var guideType = this.getGuideType(course.get('curriculum'));

      return this.assets.resolve('assets/pacing-guides/grade-' + course.get('grade.id') + '-pacing-guide-' + guideType + '.pdf');
    }.property('selectedCourse'),

    getGuideType: function getGuideType(curriculum) {
      switch (curriculum.get('value')) {
        case 'vimme':
          return 'VL';
        case 'core':
          return 'VCL';
        case 'early':
          return 'free-early';
        case 'late':
          return 'free-late';
        case '15':
          return '15';
        default:
          return 'VL';
      }
    }
  });
});
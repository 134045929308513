define('vimme/components/app-pages/teacher/reports/assignment/student-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    assignment: undefined,
    studentAssignment: undefined
  });
});
define('vimme/serializers/quiz', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.ActiveModelSerializer.extend({
    serialize: function serialize(record) {
      var json = {};

      json.id = record.id ? record.id : undefined;
      json.title = record.attr('title');

      json.teacher_id = record.belongsTo('teacher', { id: true });
      json.guardian_id = record.belongsTo('guardian', { id: true });
      json.sub_admin_id = record.belongsTo('subAdmin', { id: true });

      json.quiz_question_ids = record.hasMany('quizQuestions', { ids: true });

      return json;
    }
  });
});
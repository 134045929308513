define('vimme/components/vimme-testing-calendar-student-test/student-test/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,

    // external
    scheduledTest: null,
    studentTest: null,
    studentTests: Ember.computed(function () {
      return [];
    }),

    isSubmitted: Ember.computed.alias('studentTest.isSubmitted'),

    isFirstAttempt: Ember.computed.equal('studentTest.attempt', 1),
    isLastAttempt: Ember.computed('studentTest.attempt', 'studentTests.length', function () {
      return this.get('studentTest.attempt') === this.get('studentTests.length');
    }),

    isRendered: Ember.computed('isRetakeable', 'isTakeable', 'isSubmitted', function () {
      return this.get('isRetakeable') || this.get('isTakeable') || this.get('isSubmitted');
    }),

    isTakeable: function () {
      var attempt = this.get('studentTest.attempt');
      var maxAttempts = this.get('scheduledTest.maxAttempts');

      if (attempt > maxAttempts) return false;

      var isSubmitted = this.get('isSubmitted');
      var isActive = this.get('scheduledTest.isActive');
      var isPaused = this.get('scheduledTest.isPaused');

      return isActive && !isSubmitted && !isPaused;
    }.property('scheduledTest.{isActive,isPaused,maxAttempts}', 'studentTest.attempt', 'isSubmitted'),

    isRetakeable: function () {
      var isLastAttempt = this.get('isLastAttempt');

      if (!isLastAttempt) return false;

      var isSubmitted = this.get('isSubmitted');
      var isActive = this.get('scheduledTest.isActive');
      var isPaused = this.get('scheduledTest.isPaused');
      var maxAttempts = this.get('scheduledTest.maxAttempts');
      var attempt = this.get('studentTest.attempt');

      return isActive && isSubmitted && !isPaused && maxAttempts > attempt;
    }.property('scheduledTest.{isActive,isPaused,maxAttempts}', 'studentTest.attempt', 'isSubmitted'),

    isReviewable: function () {
      return this.get('scheduledTest.isReviewable') && this.get('scheduledTest.isViewable') && this.get('isSubmitted');
    }.property('scheduledTest.{isReviewable,isViewable}', 'isSubmitted'),

    findFirstMissed: function () {
      var isReviewable = this.get('isReviewable'),
          studentQuestions,
          firstMissed = 1,
          _this = this;

      if (isReviewable) {
        studentQuestions = this.get('studentTest.studentQuestions');
      }

      if (studentQuestions) {
        studentQuestions.then(function (studentQuestions) {
          var questionsPromises = studentQuestions.map(function (sq) {
            return sq.get('question');
          });

          Ember.RSVP.all(questionsPromises).then(function () {
            studentQuestions.toArray().sort(function (prev, next) {
              var prevOrder = prev.get('question.order');
              var nextOrder = next.get('question.order');

              if (prevOrder && nextOrder) {
                return parseInt(prevOrder, 10) - parseInt(nextOrder, 10);
              }
              if (prevOrder) {
                return -1;
              }
              if (nextOrder) {
                return 1;
              }

              return parseInt(prev.get('question.id'), 10) - parseInt(next.get('question.id'), 10);
            }).find(function (question) {
              if (!question.get('isCorrect')) {
                return true;
              }

              firstMissed++;

              return false;
            });

            if (firstMissed > studentQuestions.get('length')) {
              _this.set('firstMissed', 1);
            } else {
              _this.set('firstMissed', firstMissed);
            }
          });
        });
      }
    }.on('init').observes('isReviewable', 'studentTest'),

    actions: {
      gotoTest: function gotoTest(studentTest) {
        var attempt = studentTest.get('attempt');
        this.sendAction('gotoTest', studentTest.get('test.id'), undefined, { attempt: attempt });
      },
      gotoTestReview: function gotoTestReview(studentTest, firstMissed) {
        var attempt = studentTest.get('attempt');
        this.sendAction('gotoTestReview', studentTest.get('test.id'), firstMissed, undefined, undefined, { attempt: attempt });
      },
      retakeTest: function retakeTest(studentTest) {
        var attempt = studentTest.get('attempt') + 1;
        this.sendAction('gotoTest', studentTest.get('test.id'), undefined, { attempt: attempt });
      }
    }
  });
});
define('vimme/routes/teacher/builder/assignments', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      var grades = this.store.findAll('grade');
      var weeks = this.store.findAll('week');
      return Ember.RSVP.hash({
        assignments: teacher.get('assignments'),
        publicAssignments: this.store.find('assignment', { public: true }),
        teacher: teacher,
        grades: grades,
        weeks: weeks
      });
    }
  });
});
define('vimme/routes/admin/reports/yearly', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      var schoolYears = this.modelFor('admin.reports').schoolYears;
      var schoolYear = schoolYears.findBy('id', params.school_year_id);
      return Ember.RSVP.hash({
        weeks: this.modelFor('admin.reports').weeks,
        grades: this.modelFor('admin.reports').grades,
        schoolYears: schoolYears,
        schoolYear: schoolYear,
        schoolYearCourseReports: schoolYear.get('schoolYearCourseReports')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controllerFor('admin.reports').set('selectedSchoolYear', model.schoolYear);
      controller.set('selectedSchoolYear', model.schoolYear);
      controller.set('averages', model.schoolYearCourseReports);
      controller.set('currentSortDirection', 'desc');
      controller.set('currentSortColumn', 'grade-column');
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controllerFor('admin.reports').set('selectedSchoolYear', null);
      }
    }
  });
});
define('vimme/components/vimme-search-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',

    classNames: ['vimme-search-list'],

    search: null,

    isVisible: function () {
      return this.get('search.isOpen');
    }.property('search.isOpen')
  });
});
define('vimme/components/app-pages/school-admin/students/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    print: false,
    searchTerm: '',
    selectedClassroom: null,
    selectedGrade: null,

    classrooms: Ember.computed(function () {
      return [];
    }),
    grades: Ember.computed(function () {
      return [];
    }),
    students: Ember.computed(function () {
      return [];
    }),

    filteredStudents: Ember.computed('students.@each.{lastThenFirst,isNew,isDeleted}', 'searchTerm', 'selectedClassroom.id', 'selectedGrade.id', function () {
      var students = this.get('students').filterBy('isNew', false).filterBy('isDeleted', false);

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');
      var classroomId = this.get('selectedClassroom.id');
      var gradeId = this.get('selectedGrade.id');

      return students.filter(function (student) {
        if (classroomId && student.get('classroom.id') !== classroomId) return false;
        if (gradeId && student.get('grade.id') !== gradeId) return false;
        if (!term) return true;
        return filter.test(student.get('lastThenFirst'));
      });
    }),
    studentSortParams: ['lastThenFirst'],
    sortedStudents: Ember.computed.sort('filteredStudents', 'studentSortParams'),

    filteredClassrooms: Ember.computed('classrooms.@each.grade.id', 'selectedGrade.id', function () {
      var classrooms = this.get('classrooms').filterBy('isNew', false).filterBy('isDeleted', false);

      var gradeId = this.get('selectedGrade.id');

      return classrooms.filter(function (classroom) {
        if (gradeId && classroom.get('grade.id') !== gradeId) return false;
        return true;
      });
    }),
    classroomSortParams: ['grade.id', 'title'],
    sortedClassrooms: Ember.computed.sort('filteredClassrooms', 'classroomSortParams'),

    actions: {
      selectClassroom: function selectClassroom(classroom) {
        this.set('selectedClassroom', classroom);
      },
      selectGrade: function selectGrade(grade) {
        this.set('selectedGrade', grade);
      }
    }
  });
});
define('vimme/controllers/teacher/reports/mathbots/competition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom'],

    classroom: null,

    actions: {
      setClassroom: function setClassroom(classroom) {
        this.set('classroom', classroom ? classroom.get('id') : null);
      }
    }
  });
});
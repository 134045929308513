define('vimme/controllers/teacher/testing-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortScheduledTestProps: ['startDate'],
    sortedScheduledTests: Ember.computed.sort('model.scheduledTests', 'sortScheduledTestProps'),

    sortedTests: Ember.computed.sort('model.tests', function (a, b) {
      return Number(a.get('week.id')) - Number(b.get('week.id'));
    }),

    pacingGuides: Ember.computed('model.courses.[]', function () {
      var _this = this;

      return this.get('model.courses').map(function (course) {
        var guideType = _this.getGuideType(course.get('curriculum'));
        return {
          name: course.get('title'),
          url: _this.assets.resolve('assets/pacing-guides/grade-' + course.get('grade.id') + '-pacing-guide-' + guideType + '.pdf')
        };
      });
    }),

    getGuideType: function getGuideType(curriculum) {
      switch (curriculum.get('value')) {
        case 'vimme':
          return 'VL';
        case 'core':
          return 'VCL';
        case 'early':
          return 'free-early';
        case 'late':
          return 'free-late';
        case '15':
          return '15';
        default:
          return 'VL';
      }
    }
  });
});
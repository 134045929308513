define('vimme/components/vimme-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['vimme-button'],

    attributeBindings: ['disabled'],

    click: function click() {
      this.sendAction('action', this.get('model'));
    }
  });
});
define('vimme/controllers/student/test/questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    totalQuestions: Ember.computed.alias('model.questions.length'),

    rulerIN: false,
    rulerCM: false,
    protractor: false,
    calc: false,

    showDialog: false,

    isSaving: false,

    // set in question route
    currentQuestion: null,

    showProtractor: function () {
      return parseInt(this.get('model.test.grade.id'), 10) !== 2;
    }.property('model.test.grade.id'),

    showCalc: function () {
      if (this.get('model.scheduledTest.isCalculatorPaused')) return false;
      return this.get('requiresCalc') && parseInt(this.get('model.test.grade.id'), 10) < 7;
    }.property('requiresCalc', 'model.test.grade.id', 'model.scheduledTest.isCalculatorPaused'),

    showSciCalc: function () {
      if (this.get('model.scheduledTest.isCalculatorPaused')) return false;
      return parseInt(this.get('model.test.grade.id'), 10) >= 7;
    }.property('model.test.grade.id', 'model.scheduledTest.isCalculatorPaused'),

    isReviewing: function () {
      return this.get('model.scheduledTest.isReviewable') && this.get('model.studentTest.isSubmitted');
    }.property('model.{scheduledTest.isReviewable,studentTest.isSubmitted}'),

    answered: Ember.computed('model.studentQuestions.@each.{answers.length,question.isTextQuestion,textAnswer}', function () {
      var studentQuestions = this.get('model.studentQuestions');
      if (!studentQuestions) {
        return 0;
      }
      return studentQuestions.reduce(function (answered, studentQuestion) {
        var isTextQuestion = studentQuestion.get('question.isTextQuestion');

        if (isTextQuestion) {
          if (!!studentQuestion.get('textAnswer')) {
            answered += 1;
          }
        } else {
          if (studentQuestion.get('answers') && studentQuestion.get('answers.length') > 0) {
            answered += 1;
          }
        }
        return answered;
      }, 0);
    }),

    unanswered: function () {
      return this.get('totalQuestions') - this.get('answered');
    }.property('answered'),

    isUnfinished: function () {
      return this.get('unanswered') > 0 || this.get('isSaving');
    }.property('unanswered', 'isSaving'),

    finishText: function () {
      var unanswered = this.get('unanswered');

      if (unanswered > 0) {
        return unanswered + ' left';
      }

      return 'Finish';
    }.property('unanswered'),

    currentIndex: function () {
      return this.get('model.questions').indexOf(this.get('currentQuestion')) + 1;
    }.property('currentQuestion'),

    previousIndex: function () {
      var currentIndex = this.get('currentIndex');

      if (currentIndex <= 1) {
        return this.get('totalQuestions');
      }

      return --currentIndex;
    }.property('currentIndex'),

    nextIndex: function () {
      var currentIndex = this.get('currentIndex');

      if (currentIndex >= this.get('totalQuestions')) {
        return 1;
      }

      return ++currentIndex;
    }.property('currentIndex'),

    requiresTools: function () {
      this.set('rulerIN', false);
      this.set('rulerCM', false);
      this.set('protractor', false);

      return this.get('currentQuestion.requiresTools');
    }.property('currentQuestion'),

    requiresCalc: function () {
      // console.log(parseInt(this.get('model.test.grade.id'), 10));
      if (parseInt(this.get('model.test.grade.id'), 10) < 7) {
        this.set('calc', false);
      }

      return this.get('currentQuestion.requiresCalculator');
    }.property('currentQuestion'),

    actions: {
      showDialog: function showDialog() {
        if (this.get('isSaving')) {
          return;
        }

        this.set('showDialog', true);
      },
      cancel: function cancel() {
        this.set('showDialog', false);
      },
      finish: function finish(route) {
        var _this = this;

        if (this.get('isSaving')) {
          return;
        }

        var studentTest = this.get('model.studentTest');

        studentTest.set('submittedAt', moment().startOf('second').toDate());

        studentTest.save().then(function () {
          _this.set('showDialog', false);
          if (studentTest.get('scheduledTest.test.isPractice')) {
            _this.transitionToRoute('student.index');
          } else {
            _this.transitionToRoute(route, { queryParams: { complete: true } });
          }
        }).catch(function () {
          studentTest.set('submittedAt', null);
        });
      },
      exit: function exit(route) {
        if (this.get('isSaving')) {
          return;
        }

        var oldRoute = this.get('application.savedRoute'),
            oldModel = this.get('application.savedModel');

        if (oldRoute && oldModel) {
          this.transitionToRoute(oldRoute, oldModel);
          this.set('application.savedRoute', null);
          this.set('application.savedModel', null);
        } else if (oldRoute) {
          this.transitionToRoute(oldRoute);
          this.set('application.savedRoute', null);
        } else {
          this.transitionToRoute(route);
        }
      },
      toggleRulerIN: function toggleRulerIN() {
        this.set('rulerCM', false);
        this.set('protractor', false);
        this.set('calc', false);
        this.toggleProperty('rulerIN');
      },
      toggleRulerCM: function toggleRulerCM() {
        this.set('rulerIN', false);
        this.set('protractor', false);
        this.set('calc', false);
        this.toggleProperty('rulerCM');
      },
      toggleProtractor: function toggleProtractor() {
        this.set('rulerCM', false);
        this.set('rulerIN', false);
        this.set('calc', false);
        this.toggleProperty('protractor');
      },
      toggleCalc: function toggleCalc() {
        this.set('rulerCM', false);
        this.set('rulerIN', false);
        this.set('protractor', false);
        this.toggleProperty('calc');
      }
    }
  });
});
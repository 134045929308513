define('vimme/models/scheduled-test', ['exports', 'ember-data', 'vimme/config/environment', 'vimme/utils/time-in-range'], function (exports, _emberData, _environment, _timeInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startDate: _emberData.default.attr('isodate'),
    endDate: _emberData.default.attr('isodate'),

    isCalculatorPaused: _emberData.default.attr('boolean'),
    isPaused: _emberData.default.attr('boolean'),
    isReviewable: _emberData.default.attr('boolean'),

    maxAttempts: _emberData.default.attr('number'),

    classroom: _emberData.default.belongsTo('classroom', { async: true }),
    test: _emberData.default.belongsTo('test', { async: true }),
    teacher: _emberData.default.belongsTo('teacher', { async: true }),
    studentTests: _emberData.default.hasMany('studentTest', { async: true }),

    isActive: function () {
      var now = moment();

      return (0, _timeInRange.default)(now, this.get('startDate'), this.get('endDate'));
    }.property().volatile(),

    isViewable: function () {
      var now = moment();
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var farDate = moment(endDate).add(_environment.default.viewableWindow, 'days');

      return now.diff(startDate) >= 0 && now.diff(farDate) < 0;
    }.property().volatile()
  });
});
define('vimme/models/student-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isCorrect: _emberData.default.attr('boolean', { defaultValue: false }),
    isPreviouslyCorrect: _emberData.default.attr('boolean', { defaultValue: false }),

    textAnswer: _emberData.default.attr('string'),

    answers: _emberData.default.hasMany('answer', { async: true }),
    student: _emberData.default.belongsTo('student', { async: true }),
    question: _emberData.default.belongsTo('question', { async: true }),
    studentTest: _emberData.default.belongsTo('studentTest', { async: true }),

    correctIcon: function () {
      return this.get('isCorrect') ? 'icon-check' : 'icon-delete';
    }.property('isCorrect')
  });
});
define('vimme/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),

    quizQuestions: _emberData.default.hasMany('quizQuestion', { async: true }),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate'),

    lowercaseTitle: function () {
      return this.get('title').toLowerCase();
    }.property('title')
  });
});
define('vimme/components/app-pages/teacher/assignments/assignment-review/question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    studentQuestion: null,
    question: null,

    missingAnswers: Ember.computed('studentQuestion.assignmentQuestionAnswers.[]', 'question.assignmentQuestionAnswers.[]', function () {
      var _this = this;

      return this.get('question.assignmentQuestionAnswers').filter(function (answer) {
        return answer.get('isCorrect') && !_this.get('studentQuestion.assignmentQuestionAnswers').find(function (a) {
          return a.get('id') === answer.get('id');
        });
      });
    }),

    showMissingAnswers: Ember.computed('studentQuestion.isCorrect', 'question.isMultipleQuestion', 'missingAnswers.length', function () {
      if (!this.get('question.isMultipleQuestion')) return false;
      if (this.get('studentQuestion.isCorrect')) return false;
      return !!this.get('missingAnswers.length');
    }),

    isUnanswered: Ember.computed('studentQuestion.{assignmentQuestionAnswers.length,textAnswer}', 'isTextType', function () {
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.assignmentQuestionAnswers.length');
      return !this.get('isTextType') && !answers || typeof textAnswer === 'undefined';
    }),

    isTextType: Ember.computed('question.questionType', function () {
      return this.get('question.questionType') === 'text';
    })
  });
});
define('vimme/controllers/teacher/builder/assignments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['public'],

    assignmentBuilder: Ember.inject.service(),

    public: Ember.computed.alias('assignmentBuilder.isPublicFilter')
  });
});
define('vimme/components/app-pages/teacher/assignments/assignment-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    store: Ember.inject.service(),

    assignment: undefined,
    studentAssignment: undefined,
    question: undefined,
    questions: Ember.computed(function () {
      return [];
    }),
    studentQuestions: Ember.computed(function () {
      return [];
    }),
    studentQuestion: undefined,

    number: 1,
    total: Ember.computed.alias('questions.length'),

    prev: Ember.computed('number', function () {
      return this.get('number') - 1;
    }),
    next: Ember.computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: Ember.computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: Ember.computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    setup: function setup() {
      var _this = this;

      var studentQuestion = this.get('studentQuestions').find(function (sq) {
        return Ember.get(sq, 'assignmentQuestion.id') === _this.get('question.id');
      });

      if (!studentQuestion) {
        studentQuestion = this.get('store').createRecord('student-assignment-question', {
          assignmentQuestion: this.get('question'),
          studentAssignment: this.get('studentAssignment')
        });
      }

      this.setProperties({
        studentQuestion: studentQuestion,
        tool: undefined
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setup();
    },


    actions: {
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      }
    }
  });
});
define('vimme/routes/student/reports/mathbots', ['exports', 'vimme/routes/student', 'npm:jwt-decode'], function (exports, _student, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    session: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var games = this.store.findAll('game');
      var levels = this.store.findAll('level');
      var userGames = this.store.findAll('userGame');
      var addSubUserLevels = userGames.then(function (userGames) {
        var userGame = userGames.find(function (userGame) {
          return userGame.get('game.id') === '1';
        });
        return userGame ? _this.store.query('userLevel', { 'user-game': userGame.get('id') }) : [];
      });
      var multDivUserLevels = userGames.then(function (userGames) {
        var userGame = userGames.find(function (userGame) {
          return userGame.get('game.id') === '2';
        });
        return userGame ? _this.store.query('userLevel', { 'user-game': userGame.get('id') }) : [];
      });
      var addSubLevels = levels.then(function (levels) {
        return levels.filter(function (level) {
          return level.get('order') <= 15;
        }).reverse();
      });
      var multDivLevels = levels.then(function (levels) {
        return levels.filter(function (level) {
          return level.get('order') > 15;
        }).reverse();
      });

      return Ember.RSVP.hash({
        games: games,
        userGames: userGames,
        addSubLevels: addSubLevels,
        multDivLevels: multDivLevels,
        addSubUserLevels: addSubUserLevels,
        multDivUserLevels: multDivUserLevels
      });
    }
  });
});
define('vimme/controllers/admin/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    selectedSchoolYear: null,

    actions: {
      setSchoolYear: function setSchoolYear(schoolYear) {
        if (schoolYear) {
          this.transitionToRoute('admin.reports.yearly', schoolYear.get('id'));
        } else {
          this.transitionToRoute('admin.reports.week');
        }
      }
    }
  });
});
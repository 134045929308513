define('vimme/utils/pad-student-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (studentQuestions, questions) {
    return questions.map(function (question, num) {
      var studentQuestion = studentQuestions.find(function (studentQuestion) {
        return Ember.get(studentQuestion, 'question.id') === Ember.get(question, 'id');
      });

      return { studentQuestion: studentQuestion, num: num + 1 };
    });
  };
});
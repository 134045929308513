define('vimme/controllers/teacher/management', ['exports', 'npm:jwt-decode'], function (exports, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    indexController: Ember.inject.controller('index'),

    queryParams: ['classroom', 'course', 'print', 'unassigned'],
    classroom: null,
    course: null,
    print: false,
    unassigned: false,

    sortAlphabetically: ['lastThenFirst:asc'],
    sortedStudents: Ember.computed.sort('model.students', 'sortAlphabetically'),

    actions: {
      selectClassroom: function selectClassroom(selectedClassroom) {
        this.set('classroom', selectedClassroom || null);
      },
      selectCourse: function selectCourse(selectedCourse) {
        this.set('course', selectedCourse || null);
      },

      newStudent: function newStudent() {
        var token = this.get('session.data.authenticated.token');

        var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
            id = _ref.id;

        var teacher = this.store.find('teacher', id),
            _this = this;

        if (this.get('applicationController.currentRouteName') !== 'teacher.management.index' || this.get('unassigned') || this.get('print')) {
          this.transitionToRoute('teacher.management', { queryParams: { unassigned: false, print: false } });
        }

        teacher.then(function (teacher) {
          var index = _this.get('indexController');

          index.set('lastName', '');
          index.set('middleInitial', '');
          index.set('firstName', '');
          index.set('grade', teacher.get('grade'));
          index.set('gradeNum', teacher.get('grade.id'));
          index.set('teacher', null);
          index.set('username', '');
          index.set('password', '');
        });
      }
    },

    filteredStudents: function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');

      var students = this.get('sortedStudents');

      if (term) {
        students = students.filter(function (student) {
          return filter.test(student.get('fullName'));
        });
      }

      return students;
    }.property('searchTerm', 'sortedStudents.[]')
  });
});
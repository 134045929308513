define('vimme/helpers/get-with-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getWithDefault = getWithDefault;
  function getWithDefault(params /*, hash*/) {
    return params[0][params[1]] || params[2] || {};
  }

  exports.default = Ember.Helper.helper(getWithDefault);
});
define("vimme/components/vimme-reports/standard/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-frame");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "loader-text");
          var el4 = dom.createTextNode("Loading...");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 12
                },
                "end": {
                  "line": 27,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1]);
              var element4 = dom.childAt(element2, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'title');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              morphs[2] = dom.createAttrMorph(element4, 'class');
              morphs[3] = dom.createMorphAt(element4, 0, 0);
              return morphs;
            },
            statements: [["attribute", "title", ["get", "average.description", ["loc", [null, [24, 46], [24, 65]]]]], ["content", "average.id", ["loc", [null, [24, 68], [24, 82]]]], ["attribute", "class", ["concat", ["cell ", ["subexpr", "color", [["get", "average.percent", ["loc", [null, [25, 45], [25, 60]]]], ["get", "this.grade", ["loc", [null, [25, 61], [25, 71]]]]], [], ["loc", [null, [25, 37], [25, 73]]]]]]], ["inline", "append-percent", [["get", "average.percent", ["loc", [null, [25, 92], [25, 107]]]]], [], ["loc", [null, [25, 75], [25, 109]]]]],
            locals: ["average"],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 16
                },
                "end": {
                  "line": 40,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header  ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [35, 53], [35, 74]]]], ["get", "index", ["loc", [null, [35, 75], [35, 80]]]]], [], ["loc", [null, [35, 49], [35, 81]]]], "selected"], [], ["loc", [null, [35, 44], [35, 94]]]]]]], ["element", "action", ["setSort", ["get", "index", ["loc", [null, [36, 43], [36, 48]]]]], [], ["loc", [null, [36, 24], [36, 50]]]], ["content", "week.shortName", ["loc", [null, [38, 24], [38, 42]]]]],
            locals: ["week", "index"],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 20
                  },
                  "end": {
                    "line": 46,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "if", [["get", "week.total", ["loc", [null, [45, 46], [45, 56]]]], ["subexpr", "color", [["get", "week.percent", ["loc", [null, [45, 64], [45, 76]]]], ["get", "this.grade", ["loc", [null, [45, 77], [45, 87]]]]], [], ["loc", [null, [45, 57], [45, 88]]]]], [], ["loc", [null, [45, 41], [45, 90]]]]]]], ["inline", "if", [["get", "week.total", ["loc", [null, [45, 97], [45, 107]]]], ["subexpr", "append-percent", [["get", "week.percent", ["loc", [null, [45, 124], [45, 136]]]]], [], ["loc", [null, [45, 108], [45, 137]]]]], [], ["loc", [null, [45, 92], [45, 139]]]]],
              locals: ["week"],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 12
                },
                "end": {
                  "line": 48,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "average.weeks", ["loc", [null, [44, 28], [44, 41]]]]], [], 0, null, ["loc", [null, [44, 20], [46, 29]]]]],
            locals: ["average"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 0
              },
              "end": {
                "line": 51,
                "column": 0
              }
            },
            "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n                    Standard\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n                    Average\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1, 1]);
            var element6 = dom.childAt(element5, [1]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element6, [3]);
            var element9 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createElementMorph(element7);
            morphs[2] = dom.createAttrMorph(element8, 'class');
            morphs[3] = dom.createElementMorph(element8);
            morphs[4] = dom.createMorphAt(element5, 3, 3);
            morphs[5] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
            morphs[6] = dom.createMorphAt(element9, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["cell-header student-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [10, 63], [10, 84]]]], "student"], [], ["loc", [null, [10, 59], [10, 95]]]], "selected"], [], ["loc", [null, [10, 54], [10, 108]]]]]]], ["element", "action", ["setSort", "standard"], [], ["loc", [null, [11, 20], [11, 51]]]], ["attribute", "class", ["concat", ["cell-header average-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [16, 63], [16, 84]]]], "average"], [], ["loc", [null, [16, 59], [16, 95]]]], "selected"], [], ["loc", [null, [16, 54], [16, 108]]]]]]], ["element", "action", ["setSort", "average"], [], ["loc", [null, [17, 20], [17, 50]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [22, 20], [22, 39]]]]], [], 0, null, ["loc", [null, [22, 12], [27, 21]]]], ["block", "each", [["get", "this.weeks", ["loc", [null, [33, 24], [33, 34]]]]], [], 1, null, ["loc", [null, [33, 16], [40, 25]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [42, 20], [42, 39]]]]], [], 2, null, ["loc", [null, [42, 12], [48, 21]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.results", ["loc", [null, [5, 10], [5, 22]]]]], [], 0, null, ["loc", [null, [5, 0], [51, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-reports/standard/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "this.isLoading", ["loc", [null, [1, 6], [1, 20]]]]], [], 0, 1, ["loc", [null, [1, 0], [51, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
define('vimme/routes/guardian/pacing-guides', ['exports', 'vimme/routes/guardian'], function (exports, _guardian) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _guardian.default.extend({
    model: function model() {
      var grades = this.store.findAll('grade');
      var courses = this.store.findAll('course');
      var curriculums = this.store.findAll('curriculum');

      return Ember.RSVP.hash({
        courses: courses,
        curriculums: curriculums,
        grades: grades
      });
    }
  });
});
define('vimme/routes/school-admin/guardian-instructions', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var student = this.store.find('student', params.student_id);
      var accessCode = student.then(function (student) {
        return student.get('accessCode');
      });

      return Ember.RSVP.hash({
        accessCode: accessCode,
        student: student
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideHeader', true);
    },
    resetController: function resetController() {
      this.controllerFor('application').set('hideHeader', false);
    }
  });
});
define('vimme/routes/school-admin/test', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'school-admin.test.index') {
        this.replaceWith('school-admin.questions');
      }
    },
    model: function model(params) {
      return this.store.find('test', params.test_id);
    }
  });
});
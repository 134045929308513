define('vimme/controllers/school-admin/reports/question', ['exports', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _round, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'course', 'print', 'week'],

    classroom: null,
    course: null,
    print: false,
    week: null,

    actions: {
      gotoTestReviewC: function gotoTestReviewC() {
        this.send.apply(this, ['gotoTestReview'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});
define('vimme/models/student-assignment-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isCorrect: _emberData.default.attr('boolean', { defaultValue: false }),
    textAnswer: _emberData.default.attr('string'),

    studentId: _emberData.default.attr('string'),
    student: _emberData.default.belongsTo('student', { async: true }),

    assignmentQuestion: _emberData.default.belongsTo('assignmentQuestion', { async: true }),

    studentAssignment: _emberData.default.belongsTo('studentAssignment', { async: true }),

    assignmentQuestionAnswers: _emberData.default.hasMany('assignmentQuestionAnswer'),

    correctIcon: Ember.computed('isCorrect', function () {
      return this.get('isCorrect') ? 'icon-check' : 'icon-delete';
    })
  });
});
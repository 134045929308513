define("vimme/components/vimme-reports/week/guardian-students/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-frame");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "loader-text");
          var el4 = dom.createTextNode("Loading...");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 20
                    },
                    "end": {
                      "line": 16,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "print-item");
                  var el2 = dom.createTextNode("W");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(": ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element9, 1, 1);
                  morphs[1] = dom.createMorphAt(element9, 3, 3);
                  return morphs;
                },
                statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [15, 50], [15, 77]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [15, 79], [15, 127]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 8
                  },
                  "end": {
                    "line": 19,
                    "column": 8
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "print-row column");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "print-general");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "print-item print-name");
                var el4 = dom.createTextNode("Student: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "print-item print-avg");
                var el4 = dom.createTextNode("Average: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "print-details");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var element11 = dom.childAt(element10, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [10, 65], [10, 98]]]], ["inline", "append-percent", [["get", "average.percent", ["loc", [null, [11, 81], [11, 96]]]]], [], ["loc", [null, [11, 64], [11, 98]]]], ["block", "each", [["get", "average.paddedWeeklyAverages", ["loc", [null, [14, 28], [14, 56]]]]], [], 0, null, ["loc", [null, [14, 20], [16, 29]]]]],
              locals: ["average"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 4
                },
                "end": {
                  "line": 20,
                  "column": 4
                }
              },
              "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "this.results.averages", ["loc", [null, [7, 16], [7, 37]]]]], [], 0, null, ["loc", [null, [7, 8], [19, 17]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 16
                  },
                  "end": {
                    "line": 42,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
                morphs[1] = dom.createAttrMorph(element3, 'class');
                morphs[2] = dom.createMorphAt(element3, 0, 0);
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [39, 42], [39, 75]]]], ["attribute", "class", ["concat", ["cell ", ["get", "average.color", ["loc", [null, [40, 43], [40, 56]]]]]]], ["inline", "append-percent", [["get", "average.percent", ["loc", [null, [40, 77], [40, 92]]]]], [], ["loc", [null, [40, 60], [40, 94]]]]],
              locals: ["average"],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 20
                  },
                  "end": {
                    "line": 55,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element1, 'class');
                morphs[1] = dom.createElementMorph(element1);
                morphs[2] = dom.createMorphAt(element1, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell-header  ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [50, 57], [50, 78]]]], ["get", "index", ["loc", [null, [50, 79], [50, 84]]]]], [], ["loc", [null, [50, 53], [50, 85]]]], "selected"], [], ["loc", [null, [50, 48], [50, 98]]]]]]], ["element", "action", ["setSort", ["get", "index", ["loc", [null, [51, 47], [51, 52]]]]], [], ["loc", [null, [51, 28], [51, 54]]]], ["content", "week.shortName", ["loc", [null, [53, 28], [53, 46]]]]],
              locals: ["week", "index"],
              templates: []
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 24
                    },
                    "end": {
                      "line": 61,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  morphs[1] = dom.createMorphAt(element0, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.color", ["loc", [null, [60, 47], [60, 72]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [60, 76], [60, 124]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 16
                  },
                  "end": {
                    "line": 63,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "average.paddedWeeklyAverages", ["loc", [null, [59, 32], [59, 60]]]]], [], 0, null, ["loc", [null, [59, 24], [61, 33]]]]],
              locals: ["average"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 66,
                  "column": 4
                }
              },
              "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "reports-general column");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "reports-general-inner");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row-header");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createTextNode("\n                        Student\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createTextNode("\n                        Average\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "reports-detailed column");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "reports-detailed-inner");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row-header");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1, 1]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var element8 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(7);
              morphs[0] = dom.createAttrMorph(element6, 'class');
              morphs[1] = dom.createElementMorph(element6);
              morphs[2] = dom.createAttrMorph(element7, 'class');
              morphs[3] = dom.createElementMorph(element7);
              morphs[4] = dom.createMorphAt(element4, 3, 3);
              morphs[5] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
              morphs[6] = dom.createMorphAt(element8, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header student-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [25, 67], [25, 88]]]], "student"], [], ["loc", [null, [25, 63], [25, 99]]]], "selected"], [], ["loc", [null, [25, 58], [25, 112]]]]]]], ["element", "action", ["setSort", "student"], [], ["loc", [null, [26, 24], [26, 54]]]], ["attribute", "class", ["concat", ["cell-header average-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [31, 67], [31, 88]]]], "average"], [], ["loc", [null, [31, 63], [31, 99]]]], "selected"], [], ["loc", [null, [31, 58], [31, 112]]]]]]], ["element", "action", ["setSort", "average"], [], ["loc", [null, [32, 24], [32, 54]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [37, 24], [37, 43]]]]], [], 0, null, ["loc", [null, [37, 16], [42, 25]]]], ["block", "each", [["get", "this.weeks", ["loc", [null, [48, 28], [48, 38]]]]], [], 1, null, ["loc", [null, [48, 20], [55, 29]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [57, 24], [57, 43]]]]], [], 2, null, ["loc", [null, [57, 16], [63, 25]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 0
              },
              "end": {
                "line": 67,
                "column": 0
              }
            },
            "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "this.isPrint", ["loc", [null, [6, 10], [6, 22]]]]], [], 0, 1, ["loc", [null, [6, 4], [66, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 67,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.results", ["loc", [null, [5, 10], [5, 22]]]]], [], 0, null, ["loc", [null, [5, 0], [67, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-reports/week/guardian-students/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "this.isLoading", ["loc", [null, [1, 6], [1, 20]]]]], [], 0, 1, ["loc", [null, [1, 0], [67, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
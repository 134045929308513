define('vimme/routes/sub-admin/reports/week', ['exports', 'vimme/routes/sub-admin'], function (exports, _subAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        weeks: this.modelFor('sub-admin.reports').weeks,
        courses: this.modelFor('sub-admin.reports').courses,
        schools: this.modelFor('sub-admin.reports').schools
      });
    }
  });
});
define('vimme/routes/admin/builder/tests/test/questions', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var test = this.modelFor('admin.builder.tests.test').test;
      var grade = test.get('grade');
      var questions = test.get('questions');
      var answers = test.get('answers');
      return Ember.RSVP.hash({
        test: test,
        grade: grade,
        questions: questions,
        answers: answers
      });
    }
  });
});
define('vimme/components/vimme-search-no-results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    classNames: ['vimme-search-no-results'],

    attributeBindings: ['tabindex'],

    tabindex: -1
  });
});
define('vimme/models/school-year', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    processingState: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate'),

    school: _emberData.default.belongsTo('school', { async: true }),

    schoolYearCourseReports: _emberData.default.hasMany('schoolYearCourseReport', { async: true }),

    isStarted: Ember.computed.equal('processingState', 'started'),
    isQueued: Ember.computed.equal('processingState', 'queued'),
    isCompleted: Ember.computed.equal('processingState', 'completed'),

    nameSchool: Ember.computed('name', 'school.title', function () {
      return this.get('name') + ' (' + this.get('school.title') + ')';
    })
  });
});
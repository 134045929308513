define('vimme/routes/admin/builder/tests/test/index', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var test = this.modelFor('admin.builder.tests.test').test;
      var grades = this.modelFor('admin.builder.tests.test').grades;
      var weeks = this.modelFor('admin.builder.tests.test').weeks;
      return Ember.RSVP.hash({
        test: test,
        grades: grades,
        weeks: weeks,
        user: this.modelFor('admin').user
      });
    }
  });
});
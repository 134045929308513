define('vimme/models/course', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isTrial: _emberData.default.attr('boolean'),
    title: _emberData.default.attr('string'),
    trialType: _emberData.default.attr('string'),

    course: _emberData.default.belongsTo('course', { async: true }),
    curriculum: _emberData.default.belongsTo('curriculum', { async: true }),
    grade: _emberData.default.belongsTo('grade', { async: true }),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate')
  });
});
define('vimme/controllers/admin/management/school-admins/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    lastName: '',
    firstName: '',
    school: null,
    email: '',
    password: '',

    username: Ember.computed.alias('email'),

    resetVals: function () {
      this.set('lastName', '');
      this.set('firstName', '');
      this.set('school', null);
      this.set('email', '');
      this.set('password', '');
    }.on('init'),

    isValid: function () {
      var school = this.get('school'),
          lastName = this.get('lastName'),
          firstName = this.get('firstName'),
          email = this.get('email'),
          password = this.get('password');

      return !!school && !!lastName && !!firstName && !!email && !!password && password.length >= 5;
    }.property('school', 'lastName', 'firstName', 'email', 'password'),

    isUnsavable: Ember.computed.not('isValid'),

    actions: {
      save: function save() {
        var _this = this;

        var schoolAdmin = this.store.createRecord('schoolAdmin', {
          lastName: this.get('lastName'),
          firstName: this.get('firstName'),
          school: this.get('school'),
          email: this.get('email'),
          username: this.get('username'),
          password: this.get('password')
        });

        schoolAdmin.save().then(function () {
          _this.set('lastName', '');
          _this.set('firstName', '');
          _this.set('school', null);
          _this.set('email', '');
          _this.set('password', '');
        }).catch(function () {
          schoolAdmin.deleteRecord();
        });
      }
    }
  });
});
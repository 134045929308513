define('vimme/helpers/trim-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.trimText = trimText;
  function trimText(params /*, hash*/) {
    return (params[0] + '').trim();
  }

  exports.default = Ember.Helper.helper(trimText);
});
define('vimme/utils/compare-dates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (day1, day2, granularity) {
    granularity = typeof granularity !== 'undefined' ? granularity : 'day';

    return day1.isSame(day2, granularity);
  };
});
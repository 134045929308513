define('vimme/routes/school-admin/management/sub-admins', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      return this.store.findAll('subAdmin');
    }
  });
});
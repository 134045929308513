define('vimme/components/app-pages/teacher/assignment-builder/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var escape = function escape(term) {
    return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
  };

  exports.default = Ember.Component.extend({
    tagName: '',

    assignmentBuilder: Ember.inject.service(),

    searchTerm: '',
    selectedGrade: Ember.computed('grades', 'teacher.grade', function () {
      var _this = this;

      return this.get('grades').find(function (grade) {
        return grade.get('id') === _this.get('teacher.grade.id');
      });
    }),
    selectedWeek: null,

    assignments: Ember.computed(function () {
      return [];
    }),
    publicAssignments: Ember.computed(function () {
      return [];
    }),

    activeAssignments: Ember.computed('assignments', 'publicAssignments', 'assignmentBuilder.isPublicFilter', function () {
      return this.get('assignmentBuilder.isPublicFilter') ? this.get('publicAssignments') : this.get('assignments');
    }),

    filteredAssignments: Ember.computed('selectedGrade.id', 'selectedWeek.id', 'searchTerm', 'activeAssignments.@each.{title,week,grade}', function () {
      var assignments = this.get('activeAssignments').filterBy('isNew', false).filterBy('isDeleted', false);

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');
      var gradeId = this.get('selectedGrade.id');
      var weekId = this.get('selectedWeek.id');

      return assignments.filter(function (assignment) {
        if (gradeId && assignment.get('grade.id') !== gradeId) {
          return false;
        }
        if (weekId && assignment.get('week.id') !== weekId) {
          return false;
        }
        if (!term) {
          return true;
        }
        return filter.test(assignment.get('title'));
      });
    }),

    actions: {
      selectGrade: function selectGrade(grade) {
        this.set('selectedGrade', grade);
      },
      selectWeek: function selectWeek(week) {
        this.set('selectedWeek', week);
      }
    }
  });
});
define("vimme/components/vimme-reports/week/students/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-frame");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "loader-text");
          var el4 = dom.createTextNode("Loading...");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 20
                    },
                    "end": {
                      "line": 16,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "print-item");
                  var el2 = dom.createTextNode("W");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(": ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element12, 1, 1);
                  morphs[1] = dom.createMorphAt(element12, 3, 3);
                  return morphs;
                },
                statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [15, 50], [15, 77]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [15, 79], [15, 127]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 8
                  },
                  "end": {
                    "line": 19,
                    "column": 8
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "print-row column");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "print-general");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "print-item print-name");
                var el4 = dom.createTextNode("Student: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "print-item print-avg");
                var el4 = dom.createTextNode("Average: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "print-details");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1]);
                var element14 = dom.childAt(element13, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [10, 65], [10, 98]]]], ["inline", "append-percent", [["get", "average.percent", ["loc", [null, [11, 81], [11, 96]]]]], [], ["loc", [null, [11, 64], [11, 98]]]], ["block", "each", [["get", "average.paddedWeeklyAverages", ["loc", [null, [14, 28], [14, 56]]]]], [], 0, null, ["loc", [null, [14, 20], [16, 29]]]]],
              locals: ["average"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 4
                },
                "end": {
                  "line": 20,
                  "column": 4
                }
              },
              "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "this.results.averages", ["loc", [null, [7, 16], [7, 37]]]]], [], 0, null, ["loc", [null, [7, 8], [19, 17]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 28
                    },
                    "end": {
                      "line": 47,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                - Attempt ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "average.attempt", ["loc", [null, [46, 42], [46, 61]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 16
                  },
                  "end": {
                    "line": 51,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [1]);
                var element5 = dom.childAt(element3, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element4, 1, 1);
                morphs[1] = dom.createMorphAt(element4, 3, 3);
                morphs[2] = dom.createAttrMorph(element5, 'class');
                morphs[3] = dom.createMorphAt(element5, 0, 0);
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [44, 28], [44, 61]]]], ["block", "unless", [["subexpr", "eq", [["get", "average.attempt", ["loc", [null, [45, 42], [45, 57]]]], 1], [], ["loc", [null, [45, 38], [45, 60]]]]], [], 0, null, ["loc", [null, [45, 28], [47, 39]]]], ["attribute", "class", ["concat", ["cell ", ["get", "average.color", ["loc", [null, [49, 43], [49, 56]]]]]]], ["inline", "append-percent", [["get", "average.percent", ["loc", [null, [49, 77], [49, 92]]]]], [], ["loc", [null, [49, 60], [49, 94]]]]],
              locals: ["average"],
              templates: [child0]
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 20
                  },
                  "end": {
                    "line": 64,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element2, 'class');
                morphs[1] = dom.createElementMorph(element2);
                morphs[2] = dom.createMorphAt(element2, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell-header  ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [59, 57], [59, 78]]]], ["get", "index", ["loc", [null, [59, 79], [59, 84]]]]], [], ["loc", [null, [59, 53], [59, 85]]]], "selected"], [], ["loc", [null, [59, 48], [59, 98]]]]]]], ["element", "action", ["setSort", ["get", "index", ["loc", [null, [60, 47], [60, 52]]]]], [], ["loc", [null, [60, 28], [60, 54]]]], ["content", "week.shortName", ["loc", [null, [62, 28], [62, 46]]]]],
              locals: ["week", "index"],
              templates: []
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 20
                  },
                  "end": {
                    "line": 69,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element1, 'class');
                morphs[1] = dom.createMorphAt(element1, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.average.color", ["loc", [null, [68, 43], [68, 76]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [68, 80], [68, 128]]]]],
              locals: ["paddedWeeklyAverage"],
              templates: []
            };
          }();
          var child3 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 24
                    },
                    "end": {
                      "line": 75,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  morphs[1] = dom.createMorphAt(element0, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.color", ["loc", [null, [74, 47], [74, 72]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [74, 76], [74, 124]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 16
                  },
                  "end": {
                    "line": 77,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "average.paddedWeeklyAverages", ["loc", [null, [73, 32], [73, 60]]]]], [], 0, null, ["loc", [null, [73, 24], [75, 33]]]]],
              locals: ["average"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 80,
                  "column": 4
                }
              },
              "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "reports-general column");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "reports-general-inner");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row-header");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createTextNode("\n                        Student\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createTextNode("\n                        Average\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row reports-average-row");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "cell");
              var el5 = dom.createTextNode("Average");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "reports-detailed column");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "reports-detailed-inner");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row-header");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row reports-average-row");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 1]);
              var element7 = dom.childAt(element6, [1]);
              var element8 = dom.childAt(element7, [1]);
              var element9 = dom.childAt(element7, [3]);
              var element10 = dom.childAt(element6, [3, 3]);
              var element11 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(10);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createElementMorph(element8);
              morphs[2] = dom.createAttrMorph(element9, 'class');
              morphs[3] = dom.createElementMorph(element9);
              morphs[4] = dom.createAttrMorph(element10, 'class');
              morphs[5] = dom.createMorphAt(element10, 0, 0);
              morphs[6] = dom.createMorphAt(element6, 5, 5);
              morphs[7] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
              morphs[8] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
              morphs[9] = dom.createMorphAt(element11, 5, 5);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header student-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [25, 67], [25, 88]]]], "student"], [], ["loc", [null, [25, 63], [25, 99]]]], "selected"], [], ["loc", [null, [25, 58], [25, 112]]]]]]], ["element", "action", ["setSort", "student"], [], ["loc", [null, [26, 24], [26, 54]]]], ["attribute", "class", ["concat", ["cell-header average-column ", ["subexpr", "if", [["subexpr", "eq", [["get", "this.currentSortField", ["loc", [null, [31, 67], [31, 88]]]], "average"], [], ["loc", [null, [31, 63], [31, 99]]]], "selected"], [], ["loc", [null, [31, 58], [31, 112]]]]]]], ["element", "action", ["setSort", "average"], [], ["loc", [null, [32, 24], [32, 54]]]], ["attribute", "class", ["concat", ["cell ", ["get", "pca.color", ["loc", [null, [39, 39], [39, 48]]]]]]], ["inline", "if", [["get", "pca.percent", ["loc", [null, [39, 57], [39, 68]]]], ["subexpr", "append-percent", [["get", "pca.percent", ["loc", [null, [39, 85], [39, 96]]]]], [], ["loc", [null, [39, 69], [39, 97]]]]], [], ["loc", [null, [39, 52], [39, 99]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [41, 24], [41, 43]]]]], [], 0, null, ["loc", [null, [41, 16], [51, 25]]]], ["block", "each", [["get", "this.weeks", ["loc", [null, [57, 28], [57, 38]]]]], [], 1, null, ["loc", [null, [57, 20], [64, 29]]]], ["block", "each", [["get", "this.results.perClassroomAverage.paddedWeeklyAverages", ["loc", [null, [67, 28], [67, 81]]]]], [], 2, null, ["loc", [null, [67, 20], [69, 29]]]], ["block", "each", [["get", "this.sortedAverages", ["loc", [null, [71, 24], [71, 43]]]]], [], 3, null, ["loc", [null, [71, 16], [77, 25]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 0
              },
              "end": {
                "line": 81,
                "column": 0
              }
            },
            "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "this.isPrint", ["loc", [null, [6, 10], [6, 22]]]]], [], 0, 1, ["loc", [null, [6, 4], [80, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.results", ["loc", [null, [5, 10], [5, 22]]]]], [], 0, null, ["loc", [null, [5, 0], [81, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 81,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-reports/week/students/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "this.isLoading", ["loc", [null, [1, 6], [1, 20]]]]], [], 0, 1, ["loc", [null, [1, 0], [81, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});
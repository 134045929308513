define('vimme/services/auth-ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    trustedHosts: ['mathbots.vimmelearning.com'],

    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.token');
        if (token) {
          headers['Authorization'] = 'Bearer ' + token;
        }
        return headers;
      }
    })
  });
});
define('vimme/controllers/school-admin/reports/yearly', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reports: Ember.inject.controller('school-admin.reports'),
    selectedSchoolYear: Ember.computed.alias('reports.selectedSchoolYear'),
    print: false,

    currentSortDirection: 'desc',
    currentSortColumn: 'grade-column',

    schoolYears: Ember.computed('model.schoolYears.@each.isCompleted', function () {
      return this.get('model.schoolYears').filterBy('isCompleted', true);
    }),
    averages: [],

    actions: {
      sortAveragesWeek: function sortAveragesWeek(week) {
        var averages = this.get('averages'),
            weekNum = week.get('number'),
            sorted;

        if (this.get('currentSortColumn') === 'W' + weekNum) {
          if (this.get('currentSortDirection') === 'desc') {
            this.set('currentSortDirection', 'asc');
          } else {
            this.set('currentSortDirection', 'desc');
          }

          sorted = averages.toArray().reverse();
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', 'W' + weekNum);

          sorted = averages.toArray().sort(function (a, b) {
            var aItem = a.get('schoolYearWeeklyReports').find(function (weeklyReport) {
              return weeklyReport.get('week.number') === weekNum;
            });
            var bItem = b.get('schoolYearWeeklyReports').find(function (weeklyReport) {
              return weeklyReport.get('week.number') === weekNum;
            });

            return bItem.get('average') - aItem.get('average');
          });
        }

        this.set('averages', sorted);
      },
      sortAveragesOther: function sortAveragesOther(column) {
        var averages = this.get('averages'),
            currentSortColumn = this.get('currentSortColumn'),
            currentSortDirection = this.get('currentSortDirection'),
            sorted;

        if (column === currentSortColumn) {
          if (currentSortDirection === 'desc') {
            this.set('currentSortDirection', 'asc');
          } else {
            this.set('currentSortDirection', 'desc');
          }

          sorted = averages.toArray().reverse();
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', column);

          if (column === 'grade-column') {
            sorted = averages.toArray().sort(function (a, b) {
              return a.get('grade.id') - b.get('grade.id');
            });
          } else if (column === 'average-column') {
            sorted = averages.toArray().sort(function (a, b) {
              return a.get('average') - b.get('average');
            });
          } else {
            sorted = averages;
          }
        }

        this.set('averages', sorted);
      },
      printView: function printView() {
        this.toggleProperty('print');
      }
    }
  });
});
define('vimme/routes/sub-admin/paper-assignment-builder/index', ['exports', 'vimme/routes/sub-admin'], function (exports, _subAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    model: function model() {
      var subAdmin = this.modelFor('sub-admin.paper-assignment-builder').subAdmin;
      return Ember.RSVP.hash({
        categories: this.modelFor('sub-admin.paper-assignment-builder').categories,
        difficulties: this.modelFor('sub-admin.paper-assignment-builder').difficulties,
        grades: this.modelFor('sub-admin.paper-assignment-builder').grades,
        quiz: this.store.createRecord('quiz', {
          title: null,
          subAdmin: subAdmin
        }),
        subAdmin: subAdmin
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
define("vimme/templates/teacher/reports/mathbots/competition", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/reports/mathbots/competition.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "mathbots-classroom-reporting", [], ["classroom", ["subexpr", "@mut", [["get", "model.classroom", ["loc", [null, [2, 14], [2, 29]]]]], [], []], "classrooms", ["subexpr", "@mut", [["get", "model.classrooms", ["loc", [null, [3, 15], [3, 31]]]]], [], []], "students", ["subexpr", "@mut", [["get", "model.students", ["loc", [null, [4, 13], [4, 27]]]]], [], []], "levels", ["subexpr", "@mut", [["get", "model.levels", ["loc", [null, [5, 11], [5, 23]]]]], [], []], "attempts", ["subexpr", "@mut", [["get", "model.attempts", ["loc", [null, [6, 13], [6, 27]]]]], [], []], "averages", ["subexpr", "@mut", [["get", "model.averages", ["loc", [null, [7, 13], [7, 27]]]]], [], []], "setClassroom", ["subexpr", "action", ["setClassroom"], [], ["loc", [null, [8, 17], [8, 40]]]], "signedUp", true], ["loc", [null, [1, 0], [10, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
define('vimme/controllers/admin/builder/tests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    // new: Ember.inject.controller('admin.builder.tests.new'),

    allowedTests: Ember.computed('model.@each.isDraft', 'user.canEditLiveTests', function () {
      if (this.get('user.canEditLiveTests')) {
        return this.get('model');
      }
      return this.get('model').filterBy('isDraft', true);
    }),
    unsorted: Ember.computed('searchTerm', 'allowedTests.@each.{isNew,isDeleted}', function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var tests = this.get('allowedTests').filterBy('isNew', false).filterBy('isDeleted', false),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = tests.filter(function (test) {
          return filter.test(test.get('titleWithGrade'));
        });
      } else {
        unsorted = tests;
      }
      return unsorted;
    }),
    sortProps: ['week.number', 'grade.id'],
    filteredTests: Ember.computed.sort('unsorted', 'sortProps'),

    actions: {
      newTest: function newTest() {
        // if (this.get('application.currentRouteName') !== 'admin.builder.tests.new') {
        //     this.transitionToRoute('admin.builder.tests.new');
        // } else {
        //     this.store.all('tests').filterBy('id', null).invoke('deleteRecord');
        //     this.set('new.model.test', this.store.createRecord('test', {
        //         title: null
        //     }));
        // }
      }
    }
  });
});
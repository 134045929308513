define('vimme/components/vimme-testing-calendar-teacher-test/component', ['exports', 'vimme/utils/compare-dates'], function (exports, _compareDates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    classNames: ['testing-calendar-test'],
    classNameBindings: ['isSelected'],

    selectedDate: null,
    test: null,

    isSelected: function () {
      var selected = this.get('selectedDate'),
          dateAvailable = this.get('test.dateAvailable');

      if (selected) {
        return (0, _compareDates.default)(moment(selected), moment(dateAvailable));
      }

      return false;
    }.property('selectedDate', 'test.dateAvailable'),

    scheduledTest: function () {
      var scheduledTests = this.get('test.scheduledTests');

      if (scheduledTests && scheduledTests.get('length') > 0) {
        return scheduledTests.get('firstObject');
      }
    }.property('test.scheduledTests.[]'),

    hasViewableResults: function () {
      var scheduledTest = this.get('scheduledTest');

      if (scheduledTest) {
        return false;
      }

      return false;
    }.property('scheduledTest'),

    scrollTo: function () {
      var isSelected = this.get('isSelected');

      if (isSelected) {
        Ember.run.later(this, function () {
          var el = Ember.$('.testing-calendar-test.is-selected'),
              parent = el.parent(),
              offset = el.position().top;

          if (offset < 0 || offset > parent.height()) {
            offset = parent.scrollTop() + offset;

            parent.animate({ scrollTop: offset - 8 }, 300);
          }
        });
      }
    }.observes('isSelected')
  });
});
define('vimme/router', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('password.reset', { path: '/password/reset/:token' });
    this.route('contact');
    this.route('terms-privacy');
    this.route('coppa');
    this.route('guardian-signup');

    this.resource('admin', function () {
      this.resource('admin.management', { path: '/management' }, function () {
        this.route('schools', function () {
          this.route('school', { path: '/:school_id' });
        });
        this.route('school-admins', function () {
          this.route('school-admin', { path: '/:school_admin_id' });
        });
      });
      this.resource('admin.test-data', { path: '/test-data' }, function () {
        this.route('test', { path: '/:test_id' });
      });
      this.route('reports', { path: '/reports' }, function () {
        this.route('classroom', { path: '/classroom' });
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('yearly', { path: '/:school_year_id' });
      });
      this.resource('admin.builder', { path: '/builder' }, function () {
        this.route('paper-assignment-questions', { path: '/paper-assignment-questions' }, function () {
          this.route('new', { path: '/new' });
          this.route('paper-assignment-question', { path: '/:paper_assignment_question_id' });
        });
        this.route('tests', { path: '/tests' }, function () {
          this.route('test', { path: '/:test_id' }, function () {
            this.route('questions', { path: '/questions' }, function () {
              this.route('new', { path: '/new' });
              this.route('question', { path: '/:question_id' });
            });
          });
        });
        this.route('resources', { path: '/resources' }, function () {
          this.route('new', { path: '/new' });
          this.route('resource', { path: '/:resource_id' });
        });
      });
    });
    this.route('admin.tests', { path: '/admin/tests' });
    this.resource('admin.test', { path: '/admin/tests/:test_id' }, function () {
      this.route('print');
      this.resource('admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });

    this.resource('school-admin', function () {
      this.resource('school-admin.management', { path: '/management' }, function () {
        this.route('classrooms', { path: '/classrooms' }, function () {
          this.route('classroom', { path: '/:classroom_id' });
        });
        this.route('sub-admins', { path: '/sub-admins' }, function () {
          this.route('sub-admin', { path: '/:sub_admin_id' });
        });
        this.route('teachers', { path: '/teachers' }, function () {
          this.route('teacher', { path: '/:teacher_id' });
        });
        this.route('students', { path: '/students' }, function () {
          this.route('student', { path: '/:student_id' });
        });
      });
      this.route('reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('growth', { path: '/growth' });
        this.route('yearly', { path: '/:school_year_id' });
        this.route('standard', { path: '/standard' });
        this.route('mathbots', { path: '/mathbots' }, function () {
          this.route('competition', { path: '/competition' });
          this.route('student', { path: '/:student_id' });
        });
      });
      this.route('schedule-tests', { path: '/schedule-tests' }, function () {
        this.route('day', { path: '/:day' });
      });
      this.route('resources');
    });
    this.route('school-admin.tests', { path: '/school-admin/tests' });
    this.route('school-admin.guardian-instructions', { path: '/school-admin/guardian-instructions/:student_id' });
    this.resource('school-admin.test', { path: '/school-admin/tests/:test_id' }, function () {
      this.route('print');
      this.resource('school-admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });

    this.resource('sub-admin', function () {
      this.route('reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('growth', { path: '/growth' });
        this.route('yearly', { path: '/:school_year_id' });
        this.route('standard', { path: '/standard' });
        this.route('mathbots', { path: '/mathbots' }, function () {
          this.route('competition', { path: '/competition' });
          this.route('student', { path: '/:student_id' });
        });
      });
      this.resource('sub-admin.paper-assignment-builder', { path: '/paper-assignment-builder' }, function () {
        this.route('paper-assignment', { path: '/:paper_assignment_id' });
      });
      this.route('resources');
    });
    this.route('sub-admin.tests', { path: '/sub-admin/tests' });
    this.resource('sub-admin.test', { path: '/sub-admin/tests/:test_id' }, function () {
      this.resource('sub-admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
    this.route('sub-admin.paper-assignment', {
      path: '/sub-admin/paper-assignment/:paper_assignment_id/:paper_assignment_question_ids'
    });

    this.resource('teacher', function () {
      this.resource('teacher.testing-calendar', { path: '/testing-calendar' }, function () {
        this.route('test', { path: '/:test_id' });
      });
      this.resource('teacher.management', { path: '/management' }, function () {
        this.route('student', { path: '/:student_id' });
      });
      this.resource('teacher.reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('assignment', { path: '/assignment' });
        this.route('growth', { path: '/growth' });
        this.route('standard', { path: '/standard' });
        this.route('mathbots', { path: '/mathbots' }, function () {
          this.route('competition', { path: '/competition' });
          this.route('student', { path: '/:student_id' });
        });
      });

      this.resource('teacher.builder', { path: '/builder' }, function () {
        this.resource('teacher.builder.paper-assignments', { path: '/paper-assignment' }, function () {
          this.route('paper-assignment', { path: '/:paper_assignment_id' });
        });
        this.resource('teacher.builder.assignments', { path: '/assignment' }, function () {
          this.resource('teacher.builder.assignments.assignment', { path: '/:assignment_id' }, function () {
            this.route('new', { path: '/new' });
            this.route('question-bank', { path: '/question-bank' });
            this.route('question', { path: '/:question_id' });
          });
        });
      });

      this.route('resources');
    });
    this.route('teacher.guardian-instructions', { path: '/teacher/guardian-instructions/:student_id' });
    this.route('teacher.paper-assignment', {
      path: '/teacher/paper-assignment/:paper_assignment_id/:paper_assignment_question_ids'
    });
    this.route('teacher.assignment', { path: '/teacher/assignment/:assignment_id/:number' });
    this.route('teacher.tests', { path: '/teacher/tests' });
    this.resource('teacher.test', { path: '/teacher/tests/:test_id' }, function () {
      this.resource('teacher.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
    this.resource('student', function () {
      this.route('testing-calendar');
      this.route('assignment-calendar');
      this.resource('student.reports', { path: '/reports' }, function () {
        this.route('progress', { path: '/progress' });
        this.route('mathbots', { path: '/mathbots' });
      });
      this.route('resources');
    });
    this.route('student.assignment', { path: '/student/assignment/:assignment_id/:number' });
    this.route('student.tests', { path: '/student/tests' });
    this.resource('student.test', { path: '/student/tests/:test_id' }, function () {
      this.route('questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });

    this.resource('guardian', function () {
      this.route('pacing-guides');
      this.route('profile');
      this.route('reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
      });
      this.route('resources');
      this.resource('guardian.paper-assignment-builder', { path: '/paper-assignment-builder' }, function () {
        this.route('paper-assignment', { path: '/:paper_assignment_id' });
      });
    });
    this.route('guardian.assignment', {
      path: '/guardian/paper-assignment/:paper_assignment_id/:paper_assignment_question_ids'
    });
    this.route('guardian.tests', { path: '/guardian/tests' });
    this.resource('guardian.test', { path: '/guardian/tests/:test_id' }, function () {
      this.resource('guardian.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
  });

  exports.default = Router;
});
define('vimme/controllers/student/testing-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selectedDate: null,
    selectedItems: null,

    sortProps: ['startDate'],
    sortedScheduledTests: Ember.computed.sort('model.scheduledTests', 'sortProps'),

    actions: {
      select: function select(day) {
        this.set('selectedDate', day);
      },

      selectToday: function selectToday() {
        this.set('selectedDate', moment().startOf('day').toDate());
      }
    }
  });
});
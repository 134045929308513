define('vimme/routes/teacher/builder/assignments/index', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;

      var _modelFor = this.modelFor('teacher.builder.assignments'),
          grades = _modelFor.grades,
          weeks = _modelFor.weeks;

      return Ember.RSVP.hash({
        assignment: this.store.createRecord('assignment', {
          grade: teacher.get('grade'),
          teacher: teacher
        }),
        teacher: teacher,
        grades: grades,
        weeks: weeks
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('assignment').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
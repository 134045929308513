define('vimme/models/assignment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    isPublic: _emberData.default.attr('boolean'),

    assignmentQuestions: _emberData.default.hasMany('assignment-question', { async: true }),
    scheduledAssignments: _emberData.default.hasMany('scheduled-assignment', { async: true }),
    studentAssignments: _emberData.default.hasMany('student-assignment', { async: true }),
    studentAssignmentQuestions: _emberData.default.hasMany('student-assignment-question', { async: true }),

    grade: _emberData.default.belongsTo('grade', { async: true }),
    teacher: _emberData.default.belongsTo('teacher', { async: true }),
    week: _emberData.default.belongsTo('week', { async: true })
  });
});
define("vimme/components/vimme-percent/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 2
          }
        },
        "moduleName": "vimme/components/vimme-percent/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["percent", ["get", "this.percent", ["loc", [null, [3, 16], [3, 28]]]], "roundedPercent", ["get", "this.roundedPercent", ["loc", [null, [4, 23], [4, 42]]]], "formattedPercent", ["get", "this.formattedPercent", ["loc", [null, [5, 25], [5, 46]]]]], ["loc", [null, [2, 4], [6, 5]]]]], [], ["loc", [null, [1, 0], [7, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
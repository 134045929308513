define("vimme/templates/student/assignment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/student/assignment.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/student/assignments/assignment-wrapper", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.model.studentQuestions", ["loc", [null, [2, 21], [2, 48]]]]], [], []], "assignment", ["subexpr", "@mut", [["get", "this.model.assignment", ["loc", [null, [3, 15], [3, 36]]]]], [], []], "scheduledAssignment", ["subexpr", "@mut", [["get", "this.model.scheduledAssignment", ["loc", [null, [4, 24], [4, 54]]]]], [], []], "studentAssignment", ["subexpr", "@mut", [["get", "this.model.studentAssignment", ["loc", [null, [5, 22], [5, 50]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.model.question", ["loc", [null, [6, 13], [6, 32]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.model.questions", ["loc", [null, [7, 14], [7, 34]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.model.number", ["loc", [null, [8, 11], [8, 28]]]]], [], []]], ["loc", [null, [1, 0], [9, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});
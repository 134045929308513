define('vimme/routes/admin/test/print', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        answers: _this.modelFor('admin.test').get('answers'),
        questions: _this.modelFor('admin.test').get('questions')
      });
    }
  });
});
define('vimme/models/school-year-course-report', ['exports', 'ember-data', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _emberData, _round, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    average: _emberData.default.attr('number'),

    course: _emberData.default.belongsTo('course', { async: true }),
    grade: _emberData.default.belongsTo('grade', { async: true }),
    schoolYear: _emberData.default.belongsTo('schoolYear', { async: true }),

    schoolYearWeeklyReports: _emberData.default.hasMany('schoolYearWeeklyReport', { async: false }),

    roundedPercent: function () {
      var percent = this.get('average');
      if (percent) {
        return (0, _round.default)(percent);
      }
    }.property('average'),

    formattedPercent: function () {
      var percent = this.get('roundedPercent');
      if (percent) {
        return percent + '%';
      }
    }.property('roundedPercent'),

    color: function () {
      var percent = this.get('roundedPercent');
      var grade = this.get('grade.id');

      return (0, _color.default)(percent, grade);
    }.property('roundedPercent', 'grade.id')
  });
});
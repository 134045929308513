define('vimme/routes/teacher/questions', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'teacher.questions.index') {
        if (transition.queryParams && transition.queryParams['test-review-id']) {
          this.replaceWith('teacher.questions.question', 1, {
            queryParams: { testReviewID: transition.queryParams['test-review-id'] }
          });
        } else {
          this.replaceWith('teacher.questions.question', 1);
        }
      }
    },
    model: function model() {
      return this.modelFor('teacher.test');
    },

    afterModel: function afterModel(model) {
      if (model.questions.get('length') === 0) {
        this.replaceWith('teacher');
      }
    }
  });
});
define('vimme/routes/info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('hideLogin', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('hideLogin', false);
    }
  });
});
define('vimme/components/vimme-audio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    classNames: ['vimme-audio'],

    classNameBindings: ['isError:vimme-audio-error', 'isLoading:vimme-audio-loading'],

    attributeBindings: ['style'],

    audio: null,

    isError: false,
    isLoading: true,
    isReloading: false,

    text: '',

    isPlaying: false,

    click: function click() {
      this.playPause();
    },
    playPause: function playPause() {
      if (this.get('isLoading')) {
        return;
      }

      if (this.get('isPlaying')) {
        this.audio.pause();
      } else {
        if (this.get('isError')) {
          this.set('isLoading', true);
          this.audio.load();
        }

        this.audio.play();
      }
    },


    reset: Ember.observer('src', function () {
      this.set('isError', false);
      this.set('isLoading', true);
      this.set('isPlaying', false);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.audio = this.$('audio')[0];
      this.audio.onplaying = function () {
        _this.set('isPlaying', true);
      };
      this.audio.onpause = function () {
        _this.set('isPlaying', false);
      };
      this.audio.onloadedmetadata = function () {
        _this.set('isError', false);
        _this.set('isLoading', false);
      };
      this.audio.onerror = function () {
        _this.set('isError', true);
        _this.set('isLoading', false);
      };
    }
  });
});
define('vimme/routes/teacher/management', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    queryParams: {
      classroom: {
        refreshModel: true
      },
      course: {
        refreshModel: true
      },
      unassigned: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var classroom = _ref.classroom,
          course = _ref.course,
          unassigned = _ref.unassigned;

      var _modelFor = this.modelFor('teacher'),
          classrooms = _modelFor.classrooms,
          courses = _modelFor.courses,
          user = _modelFor.user;

      var students = this.store.query('student', { classroom: classroom, course: course, unassigned: unassigned });
      return Ember.RSVP.hash({
        classrooms: classrooms,
        courses: courses,
        students: students,
        teacher: user
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});
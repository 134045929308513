define('vimme/models/test', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    reviewTopics: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    isPractice: _emberData.default.attr('boolean'),
    isDraft: _emberData.default.attr('boolean'),

    answers: _emberData.default.hasMany('answer', { async: true }),
    course: _emberData.default.belongsTo('course', { async: true }),
    grade: _emberData.default.belongsTo('grade', { async: true }),
    questions: _emberData.default.hasMany('question', { async: true }),
    scheduledTests: _emberData.default.hasMany('scheduledTest', { async: true }),
    standards: _emberData.default.hasMany('standard', { async: true }),
    studentQuestions: _emberData.default.hasMany('studentQuestion', { async: true }),
    studentTests: _emberData.default.hasMany('studentTest', { async: true }),
    week: _emberData.default.belongsTo('week', { async: true }),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate'),

    gradeSuffix: function () {
      return 'Grade ' + this.get('grade.id');
    }.property('grade'),
    titleWithGrade: function () {
      return this.get('title') + (' (' + this.get('gradeSuffix') + ')');
    }.property('title', 'gradeSuffix'),

    titleWithGradeAndCourse: function () {
      var curriculum = this.get('course.curriculum.value');
      if (!curriculum || curriculum === 'vimme') {
        curriculum = '';
      } else {
        curriculum = ' - ' + curriculum.charAt(0).toUpperCase() + curriculum.slice(1);
      }
      return this.get('title') + curriculum + ' (Grade ' + this.get('grade.id') + ')';
    }.property('title', 'grade', 'course.curriculum.value')
  });
});
define('vimme/routes/index', ['exports', 'npm:jwt-decode'], function (exports, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref.role;

      if (role === 'Admin') {
        this.transitionTo('admin.management.schools');
      } else if (role === 'SchoolAdmin') {
        this.transitionTo('school-admin.schedule-tests');
      } else if (role === 'SubAdmin') {
        this.transitionTo('sub-admin.reports.week');
      } else if (role === 'Teacher') {
        this.transitionTo('teacher.testing-calendar');
      } else if (role === 'Student') {
        this.transitionTo('student.testing-calendar');
      } else if (role === 'Guardian') {
        this.transitionTo('guardian.reports');
      }
    },
    activate: function activate() {
      Ember.$('body').addClass('index-page');
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass('index-page');
    }
  });
});
define('vimme/helpers/attempt-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.color = color;
  function color(params /*, hash*/) {
    if (params[0].passed && params[0].attempts) {
      return 'benchmark';
    } else if (params[0].attempts) {
      return 'intensive';
    } else {
      return '';
    }
  }

  exports.default = Ember.Helper.helper(color);
});
define('vimme/routes/teacher/tests', ['exports', 'vimme/routes/teacher'], function (exports, _teacher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _teacher.default.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('teacher.testing-calendar');
    }
  });
});
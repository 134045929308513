define('vimme/helpers/is-odd', ['exports', 'vimme/helpers/is-even'], function (exports, _isEven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isOdd = isOdd;
  function isOdd(params /*, hash*/) {
    return !(0, _isEven.isEven)(params);
  }

  exports.default = Ember.Helper.helper(isOdd);
});
define("vimme/components/vimme-test-question/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-multi-audio", [], ["style", "position: absolute; top: -32px; left: 50%; margin-left: -14px;", "item", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [5, 13], [5, 26]]]]], [], []], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [6, 16], [6, 28]]]]], [], []]], ["loc", [null, [3, 4], [7, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 12
                },
                "end": {
                  "line": 16,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-answer is-reviewing student-answer");
              var el2 = dom.createTextNode("Unanswered");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 28
                    },
                    "end": {
                      "line": 23,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1, "alt", "");
                  dom.setAttribute(el1, "style", "max-width: 100%;");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element10, 'src');
                  return morphs;
                },
                statements: [["attribute", "src", ["get", "studentAnswer.image64", ["loc", [null, [22, 43], [22, 64]]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 23,
                        "column": 28
                      },
                      "end": {
                        "line": 25,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "alt", "");
                    dom.setAttribute(el1, "style", "max-width: 100%;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element9 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element9, 'src');
                    return morphs;
                  },
                  statements: [["attribute", "src", ["get", "studentAnswer.imagePath", ["loc", [null, [24, 43], [24, 66]]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 28
                      },
                      "end": {
                        "line": 27,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "studentAnswer.title", ["loc", [null, [26, 32], [26, 57]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 28
                    },
                    "end": {
                      "line": 27,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "studentAnswer.imagePath", ["loc", [null, [23, 38], [23, 61]]]]], [], 0, 1, ["loc", [null, [23, 28], [27, 28]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 20
                  },
                  "end": {
                    "line": 29,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element11, 'class');
                morphs[1] = dom.createMorphAt(element11, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["question-answer is-reviewing student-answer ", ["subexpr", "if", [["get", "studentAnswer.isCorrect", ["loc", [null, [20, 84], [20, 107]]]], "is-correct"], [], ["loc", [null, [20, 79], [20, 122]]]]]]], ["block", "if", [["get", "studentAnswer.image64", ["loc", [null, [21, 34], [21, 55]]]]], [], 0, 1, ["loc", [null, [21, 28], [27, 35]]]]],
              locals: ["studentAnswer"],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 36,
                        "column": 28
                      },
                      "end": {
                        "line": 38,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "alt", "");
                    dom.setAttribute(el1, "style", "max-width: 100%;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element8, 'src');
                    return morphs;
                  },
                  statements: [["attribute", "src", ["get", "missingAnswer.image64", ["loc", [null, [37, 43], [37, 64]]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 38,
                          "column": 28
                        },
                        "end": {
                          "line": 40,
                          "column": 28
                        }
                      },
                      "moduleName": "vimme/components/vimme-test-question/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "alt", "");
                      dom.setAttribute(el1, "style", "max-width: 100%;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element7, 'src');
                      return morphs;
                    },
                    statements: [["attribute", "src", ["get", "missingAnswer.imagePath", ["loc", [null, [39, 43], [39, 66]]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 40,
                          "column": 28
                        },
                        "end": {
                          "line": 42,
                          "column": 28
                        }
                      },
                      "moduleName": "vimme/components/vimme-test-question/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                            ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "missingAnswer.title", ["loc", [null, [41, 32], [41, 57]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 28
                      },
                      "end": {
                        "line": 42,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "missingAnswer.imagePath", ["loc", [null, [38, 38], [38, 61]]]]], [], 0, 1, ["loc", [null, [38, 28], [42, 28]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 20
                    },
                    "end": {
                      "line": 44,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "question-answer is-reviewing student-answer");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "missingAnswer.image64", ["loc", [null, [36, 34], [36, 55]]]]], [], 0, 1, ["loc", [null, [36, 28], [42, 35]]]]],
                locals: ["missingAnswer"],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 16
                  },
                  "end": {
                    "line": 46,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h4");
                dom.setAttribute(el1, "class", "answer-header");
                var el2 = dom.createTextNode("Missing correct answer(s):");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "student-answers");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "missingAnswers", ["loc", [null, [34, 28], [34, 42]]]]], [], 0, null, ["loc", [null, [34, 20], [44, 29]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 12
                },
                "end": {
                  "line": 47,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer(s):");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "studentQuestion.answers", ["loc", [null, [19, 28], [19, 51]]]]], [], 0, null, ["loc", [null, [19, 20], [29, 29]]]], ["block", "if", [["subexpr", "and", [["get", "this.isMultipleQuestion", ["loc", [null, [31, 27], [31, 50]]]], ["get", "missingAnswers.length", ["loc", [null, [31, 51], [31, 72]]]]], [], ["loc", [null, [31, 22], [31, 73]]]]], [], 1, null, ["loc", [null, [31, 16], [46, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 48,
                "column": 8
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isUnanswered", ["loc", [null, [13, 18], [13, 30]]]]], [], 0, 1, ["loc", [null, [13, 12], [47, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 20
                },
                "end": {
                  "line": 55,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "alt", "");
              dom.setAttribute(el1, "style", "max-width: 100%");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element5, 'src');
              return morphs;
            },
            statements: [["attribute", "src", ["get", "answer.image64", ["loc", [null, [54, 35], [54, 49]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 20
                  },
                  "end": {
                    "line": 57,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1, "alt", "");
                dom.setAttribute(el1, "style", "max-width: 100%");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element4, 'src');
                return morphs;
              },
              statements: [["attribute", "src", ["get", "answer.imagePath", ["loc", [null, [56, 35], [56, 51]]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 24
                    },
                    "end": {
                      "line": 65,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "vimme-multi-audio", [], ["style", "position: absolute; left: 100%; top: 50%; margin-top: -14px; margin-left: 3px;", "item", ["subexpr", "@mut", [["get", "answer", ["loc", [null, [62, 37], [62, 43]]]]], [], []], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [63, 40], [63, 52]]]]], [], []]], ["loc", [null, [60, 28], [64, 30]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 20
                  },
                  "end": {
                    "line": 66,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["content", "answer.title", ["loc", [null, [58, 24], [58, 42]]]], ["block", "if", [["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [59, 35], [59, 49]]]], ["get", "question.answersAudio", ["loc", [null, [59, 50], [59, 71]]]], ["subexpr", "not", [["get", "question.noAudio", ["loc", [null, [59, 77], [59, 93]]]]], [], ["loc", [null, [59, 72], [59, 94]]]]], [], ["loc", [null, [59, 30], [59, 95]]]]], [], 0, null, ["loc", [null, [59, 24], [65, 31]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 20
                },
                "end": {
                  "line": 66,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "answer.imagePath", ["loc", [null, [55, 30], [55, 46]]]]], [], 0, 1, ["loc", [null, [55, 20], [66, 20]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 12
              },
              "end": {
                "line": 68,
                "column": 12
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createMorphAt(element6, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["question-answer ", ["subexpr", "if", [["get", "answer.isCorrect", ["loc", [null, [52, 48], [52, 64]]]], "is-correct"], [], ["loc", [null, [52, 43], [52, 79]]]]]]], ["block", "if", [["get", "answer.image64", ["loc", [null, [53, 26], [53, 40]]]]], [], 0, 1, ["loc", [null, [53, 20], [66, 27]]]]],
          locals: ["answer"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 70,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "answer-header");
          var el2 = dom.createTextNode("Answer choices:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ol");
          dom.setAttribute(el1, "class", "question-answers");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [12, 14], [12, 25]]]]], [], 0, null, ["loc", [null, [12, 8], [48, 15]]]], ["block", "each", [["get", "question.answers", ["loc", [null, [51, 20], [51, 36]]]]], [], 1, null, ["loc", [null, [51, 12], [68, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 12
                },
                "end": {
                  "line": 77,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              dom.setAttribute(el2, "class", "question-answer is-reviewing student-answer");
              var el3 = dom.createTextNode("Unanswered");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 12
                },
                "end": {
                  "line": 82,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["question-answer is-reviewing student-answer ", ["subexpr", "if", [["get", "studentQuestion.isCorrect", ["loc", [null, [80, 80], [80, 105]]]], "is-correct"], [], ["loc", [null, [80, 75], [80, 120]]]]]]], ["content", "studentQuestion.textAnswer", ["loc", [null, [80, 122], [80, 152]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 8
              },
              "end": {
                "line": 83,
                "column": 8
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isUnanswered", ["loc", [null, [72, 18], [72, 30]]]]], [], 0, 1, ["loc", [null, [72, 12], [82, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 20
                },
                "end": {
                  "line": 95,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "vimme-multi-audio", [], ["style", "position: absolute; left: 100%; top: 50%; margin-top: -14px; margin-left: 3px;", "item", ["subexpr", "@mut", [["get", "answer", ["loc", [null, [92, 33], [92, 39]]]]], [], []], "student", ["subexpr", "@mut", [["get", "this.student", ["loc", [null, [93, 36], [93, 48]]]]], [], []]], ["loc", [null, [90, 24], [94, 26]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 12
              },
              "end": {
                "line": 97,
                "column": 12
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["question-answer ", ["subexpr", "if", [["get", "answer.isCorrect", ["loc", [null, [87, 48], [87, 64]]]], "is-correct"], [], ["loc", [null, [87, 43], [87, 79]]]]]]], ["content", "answer.title", ["loc", [null, [88, 20], [88, 36]]]], ["block", "if", [["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [89, 31], [89, 45]]]], ["get", "question.answersAudio", ["loc", [null, [89, 46], [89, 67]]]], ["subexpr", "not", [["get", "question.noAudio", ["loc", [null, [89, 73], [89, 89]]]]], [], ["loc", [null, [89, 68], [89, 90]]]]], [], ["loc", [null, [89, 26], [89, 91]]]]], [], 0, null, ["loc", [null, [89, 20], [95, 27]]]]],
          locals: ["answer"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 4
            },
            "end": {
              "line": 99,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "answer-header question-text-answer");
          var el2 = dom.createTextNode("Possible Answers:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "student-answers question-text-answer");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [71, 14], [71, 25]]]]], [], 0, null, ["loc", [null, [71, 8], [83, 15]]]], ["block", "each", [["get", "question.answers", ["loc", [null, [86, 20], [86, 36]]]]], [], 1, null, ["loc", [null, [86, 12], [97, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 4
            },
            "end": {
              "line": 105,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "question-image");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "alt", "");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["get", "image", ["loc", [null, [103, 23], [103, 28]]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 105,
                "column": 4
              },
              "end": {
                "line": 109,
                "column": 4
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-image");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "alt", "");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["get", "question.src", ["loc", [null, [107, 23], [107, 35]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 4
            },
            "end": {
              "line": 109,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "question.hasImage", ["loc", [null, [105, 14], [105, 31]]]]], [], 0, null, ["loc", [null, [105, 4], [109, 4]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 110,
            "column": 6
          }
        },
        "moduleName": "vimme/components/vimme-test-question/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "position: relative;");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0]);
        var element13 = dom.childAt(element12, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element12, 1, 1);
        morphs[1] = dom.createAttrMorph(element13, 'class');
        morphs[2] = dom.createUnsafeMorphAt(element13, 1, 1);
        morphs[3] = dom.createMorphAt(element13, 3, 3);
        morphs[4] = dom.createMorphAt(element12, 4, 4);
        return morphs;
      },
      statements: [["block", "unless", [["get", "this.question.noAudio", ["loc", [null, [2, 10], [2, 31]]]]], [], 0, null, ["loc", [null, [2, 0], [8, 11]]]], ["attribute", "class", ["concat", ["question-text ", ["subexpr", "if", [["get", "question.isVertical", ["loc", [null, [9, 31], [9, 50]]]], "is-vertical"], [], ["loc", [null, [9, 26], [9, 66]]]], " ", ["subexpr", "if", [["get", "isUnanswered", ["loc", [null, [9, 72], [9, 84]]]], "is-unanswered"], [], ["loc", [null, [9, 67], [9, 102]]]], " ", ["subexpr", "if", [["get", "hideAnswers", ["loc", [null, [9, 108], [9, 119]]]], "hide-answers"], [], ["loc", [null, [9, 103], [9, 136]]]]]]], ["content", "question.title", ["loc", [null, [10, 4], [10, 24]]]], ["block", "unless", [["get", "question.isTextQuestion", ["loc", [null, [11, 14], [11, 37]]]]], [], 1, 2, ["loc", [null, [11, 4], [99, 15]]]], ["block", "if", [["get", "image", ["loc", [null, [101, 10], [101, 15]]]]], [], 3, 4, ["loc", [null, [101, 4], [109, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});
define('vimme/routes/school-admin/management/students', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        classrooms: this.store.findAll('classroom'),
        courses: this.store.findAll('course'),
        grades: this.store.findAll('grade'),
        students: this.store.findAll('student'),
        teachers: this.store.findAll('teacher')
      });
    }
  });
});
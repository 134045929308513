define('vimme/components/app-pages/teacher/reports/assignment/question-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['cell'],
    classNameBindings: ['paddedStudentQuestion.studentQuestion.correctIcon'],
    tagName: 'a',

    paddedStudentQuestion: null,

    click: function click() {
      if (this.get('paddedStudentQuestion.studentQuestion')) {
        this.sendAction('clickMe', this.get('paddedStudentQuestion'));
      }
    },
    mouseEnter: function mouseEnter() {
      if (this.get('paddedStudentQuestion.studentQuestion')) {
        this.sendAction('hoverMe', this.get('paddedStudentQuestion'), this.get('element'));
      }
    },
    mouseLeave: function mouseLeave() {
      this.sendAction('hoverMe', null);
    }
  });
});
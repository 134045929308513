define('vimme/routes/school-admin/management/teachers/index', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var _modelFor = this.modelFor('school-admin.management.teachers'),
          classrooms = _modelFor.classrooms,
          courses = _modelFor.courses,
          grades = _modelFor.grades;

      var _modelFor2 = this.modelFor('school-admin'),
          user = _modelFor2.user;

      var teacher = this.store.createRecord('teacher');

      return Ember.RSVP.hash({
        classrooms: classrooms,
        courses: courses,
        grades: grades,
        school: user.get('school'),
        teacher: teacher
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('teacher').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
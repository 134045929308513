define('vimme/routes/school-admin/schedule-tests', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        tests: this.modelFor('schoolAdmin').tests,
        scheduledTests: this.store.findAll('scheduledTest').then(function (scheduledTests) {
          return scheduledTests.reduce(function (prev, scheduledTest) {
            if (!scheduledTest.get('teacher.isPlaceholder')) {
              prev.addObject(scheduledTest);
            }
            return prev;
          }, []);
        }),
        teachers: this.store.findAll('teacher').then(function (teachers) {
          return teachers.reduce(function (prev, teacher) {
            if (!teacher.get('isPlaceholder')) {
              prev.addObject(teacher);
            }
            return prev;
          }, []);
        }),
        curriculums: this.store.findAll('curriculum'),
        classrooms: this.store.findAll('classroom'),
        courses: this.store.findAll('course'),
        grades: this.store.findAll('grade')
      });
    }
  });
});
define('vimme/components/vimme-file-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'file',
    accept: null,
    attributeBindings: ['type', 'accept'],
    classNames: ['vimme-file-input'],

    file: null,

    setFile: function () {
      var el = this.$();
      if (el.length && el[0].files.length) {
        this.set('file', el[0].files[0]);
      }
    }.on('change'),

    unsetFile: function () {
      if (!this.get('file')) {
        var el = this.$().get(0);
        try {
          el.value = '';
          if (el.value) {
            el.type = 'text';
            el.type = 'file';
          }
        } catch (e) {}
      }
    }.observes('file')
  });
});
define('vimme/controllers/admin/reports/week', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'course', 'print', 'school'],

    classroom: null,
    course: null,
    print: false,
    school: null
  });
});
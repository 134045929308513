define('vimme/controllers/teacher/questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    questionController: Ember.inject.controller('teacher/questions/question'),

    queryParams: [{ testReviewID: 'test-review-id' }],

    test: Ember.computed.alias('model.test'),

    testReviewID: null,

    rulerIN: false,
    rulerCM: false,
    protractor: false,
    calc: false,

    // Set on question route
    currentQuestion: null,

    studentTest: null,
    studentQuestions: null,

    showProtractor: function () {
      return parseInt(this.get('test.grade.id'), 10) !== 2;
    }.property('test.grade.id'),

    showCalc: function () {
      return this.get('requiresCalc') && parseInt(this.get('test.grade.id'), 10) < 7;
    }.property('requiresCalc', 'test.grade.id'),

    showSciCalc: function () {
      return parseInt(this.get('test.grade.id'), 10) >= 7;
    }.property('test.grade.id'),

    isReviewing: function () {
      return !!(this.get('testReviewID') && this.get('studentTest'));
    }.property('testReviewID', 'studentTest'),

    hideAnswers: Ember.computed.alias('questionController.hideAnswers'),

    handleQueryParam: function () {
      var _this = this,
          studentTests = this.get('model.studentTests'),
          testReviewID = this.get('testReviewID'),
          studentTest;

      if (studentTests && studentTests.get('length') > 0 && testReviewID) {
        studentTest = studentTests.findBy('id', testReviewID);

        if (!studentTest) {
          this.set('testReviewID', null);

          this.replaceRoute({ queryParams: { testReviewID: null } });
        } else {
          studentTest.get('studentQuestions').then(function (studentQuestions) {
            _this.set('studentTest', studentTest);
            _this.set('studentQuestions', studentQuestions);
          });
        }
      } else {
        this.set('testReviewID', null);

        this.replaceRoute({ queryParams: { testReviewID: null } });
      }
    }.observes('model'),

    length: Ember.computed.alias('model.questions.length'),

    currentIndex: function () {
      return this.get('model.questions').indexOf(this.get('currentQuestion')) + 1;
    }.property('currentQuestion'),

    previousIndex: function () {
      var currentIndex = this.get('currentIndex'),
          length = this.get('length');

      if (currentIndex <= 1) {
        return length;
      }

      return --currentIndex;
    }.property('currentIndex'),

    nextIndex: function () {
      var currentIndex = this.get('currentIndex'),
          length = this.get('length');

      if (currentIndex > length) {
        return 1;
      }

      return ++currentIndex;
    }.property('currentIndex'),

    requiresTools: function () {
      this.set('rulerIN', false);
      this.set('rulerCM', false);
      this.set('protractor', false);

      return this.get('currentQuestion.requiresTools');
    }.property('currentQuestion'),

    requiresCalc: function () {
      if (parseInt(this.get('test.grade.id'), 10) < 7) {
        this.set('calc', false);
      }

      return this.get('currentQuestion.requiresCalculator');
    }.property('currentQuestion'),

    actions: {
      exit: function exit(route) {
        var oldRoute = this.get('applicationController.savedRoute'),
            oldModel = this.get('applicationController.savedModel');

        if (oldRoute && oldModel) {
          this.transitionToRoute(oldRoute, oldModel);
          this.set('applicationController.savedRoute', null);
          this.set('applicationController.savedModel', null);
        } else if (oldRoute) {
          this.transitionToRoute(oldRoute);
          this.set('applicationController.savedRoute', null);
        } else {
          this.transitionToRoute(route);
        }
      },
      toggleHideAnswers: function toggleHideAnswers() {
        this.toggleProperty('hideAnswers');
      },
      toggleRulerIN: function toggleRulerIN() {
        this.set('rulerCM', false);
        this.set('protractor', false);
        this.set('calc', false);
        this.toggleProperty('rulerIN');
      },
      toggleRulerCM: function toggleRulerCM() {
        this.set('rulerIN', false);
        this.set('protractor', false);
        this.set('calc', false);
        this.toggleProperty('rulerCM');
      },
      toggleProtractor: function toggleProtractor() {
        this.set('rulerCM', false);
        this.set('rulerIN', false);
        this.set('calc', false);
        this.toggleProperty('protractor');
      },
      toggleCalc: function toggleCalc() {
        this.set('rulerCM', false);
        this.set('rulerIN', false);
        this.set('protractor', false);
        this.toggleProperty('calc');
      }
    }
  });
});
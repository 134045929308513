define('vimme/controllers/sub-admin/paper-assignment-builder/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    paperAssignmentBuilder: Ember.inject.controller('sub-admin.paper-assignment-builder'),

    isSaving: false,

    addQuestions: Ember.computed.alias('paperAssignmentBuilder.addQuestions'),

    selectedCategories: '',
    selectedDifficulties: '',
    selectedGrades: '',
    page: 1,
    sort: 'grade',

    visibleCategoriesCache: [],
    visibleCategories: function (key, value) {
      var _this = this;

      if (arguments.length > 1) {
        this.set('visibleCategoriesCache', value);
        return value;
      }
      this.store.find('category', {
        grades: this.get('selectedGrades')
      }).then(function (categories) {
        _this.set('visibleCategories', categories);
      });
      return this.get('visibleCategoriesCache');
    }.property('selectedGrades'),

    quizQuestionsCache: [],
    quizQuestions: function (key, value) {
      var _this2 = this;

      if (arguments.length > 1) {
        this.set('quizQuestionsCache', value);
        return value;
      }
      this.store.find('quiz-question', {
        categories: this.get('selectedCategories'),
        difficulties: this.get('selectedDifficulties'),
        grades: this.get('selectedGrades'),
        page: this.get('page'),
        sort: this.get('sort')
      }).then(function (quizQuestions) {
        _this2.set('quizQuestions', quizQuestions);
      });
      return this.get('quizQuestionsCache');
    }.property('selectedCategories', 'selectedDifficulties', 'selectedGrades', 'page', 'sort'),

    actions: {
      cancel: function cancel() {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
        this.set('model.quiz', this.store.createRecord('quiz', {
          title: null,
          subAdmin: this.get('model.subAdmin')
        }));
      },
      save: function save(quiz) {
        var _this3 = this;

        this.set('isSaving', true);

        quiz.save().then(function (quiz) {
          _this3.set('isSaving', false);
          _this3.transitionToRoute('sub-admin.paper-assignment-builder.paper-assignment', quiz.get('id'));
        }).catch(function () {
          _this3.set('isSaving', false);
        });
      },
      hideAdd: function hideAdd() {
        this.set('addQuestions', false);
      },
      showAdd: function showAdd() {
        this.set('addQuestions', true);
      }
    }
  });
});
define('vimme/controllers/teacher/reports/week', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['classroom', 'print'],

    classroom: null,
    print: false
  });
});
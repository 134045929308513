define('vimme/services/assignment-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isPublicFilter: false,

    question: null
  });
});
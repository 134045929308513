define('vimme/controllers/admin/builder/tests/test/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      viewTest: function viewTest(id, model) {
        this.send('gotoTest', id, model);
      },
      saveTest: function saveTest(test) {
        var _this = this;

        test.save().then(function (test) {
          return _this.transitionTo('admin.builder.tests.test', test.get('id'));
        }).catch(function (error) {
          return alert(error.message);
        });
      },
      deleteTest: function deleteTest(test) {
        var _this2 = this;

        test.destroyRecord().then(function () {
          return _this2.transitionTo('admin.builder.tests');
        }).catch(function (error) {
          return alert(error.message);
        });
      }
    }
  });
});
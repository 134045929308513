define('vimme/models/assignment-question-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    isCorrect: _emberData.default.attr('boolean'),

    assignmentQuestion: _emberData.default.belongsTo('assignment-question')
  });
});
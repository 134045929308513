define('vimme/components/vimme-hoverable-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['vimme-hoverable-icon'],

    value: null,

    inMouseArea: false,

    click: function click() {
      if (!this.get('inMouseArea')) {
        this.sendAction('action', this.get('value'), this.get('element'));
      }
    },
    mouseEnter: function mouseEnter() {
      this.sendAction('action', this.get('value'), this.get('element'));
      this.set('inMouseArea', true);
    },
    mouseLeave: function mouseLeave() {
      this.sendAction('action', this.get('value'));
      this.set('inMouseArea', false);
    }
  });
});
define('vimme/controllers/guardian/profile', ['exports', 'ember-ajax/errors', 'vimme/config/environment'], function (exports, _errors, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    authAjax: Ember.inject.service(),
    session: Ember.inject.service(),

    isSaving: false,

    showDialogPassword: false,

    errorMessage: null,

    code: '',
    password: '',
    passwordConfirmation: '',

    isValidPassword: function () {
      var password = this.get('password'),
          passwordConfirmation = this.get('passwordConfirmation');

      return !!password && !!passwordConfirmation;
    }.property('password', 'passwordConfirmation'),

    isUnsavablePassword: Ember.computed.not('isValidPassword'),

    isValidUpdate: function () {
      var email = this.get('model.user.email'),
          lastName = this.get('model.user.lastName'),
          firstName = this.get('model.user.firstName');

      return !!email && !!lastName && !!firstName;
    }.property('model.user.{email,firstName,lastName}'),

    isUnsavable: Ember.computed.not('isValidUpdate'),

    actions: {
      cancelDialogPassword: function cancelDialogPassword() {
        this.set('password', '');
        this.set('passwordConfirmation', '');
        this.set('showDialogPassword', false);
      },
      showDialogPassword: function showDialogPassword() {
        this.set('password', '');
        this.set('passwordConfirmation', '');
        this.set('showDialogPassword', true);
      },
      save: function save() {
        var _this = this;

        this.set('isSaving', true);
        var user = this.get('model.user');
        user.save().then(function () {
          _this.set('isSaving', false);
        });
      },
      showAccessCode: function showAccessCode() {
        this.set('showAccessCode', true);
      },
      redeem: function redeem() {
        var _this2 = this;

        this.set('isSaving', true);
        var code = this.get('code');
        this.get('authAjax').request(_environment.default.API.host + '/access-codes/redeem', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            code: code
          })
        }).then(function () {
          _this2.set('isSaving', false);
          _this2.set('code', '');
          _this2.set('showAccessCode', false);
          _this2.transitionToRoute('guardian.reports');
        }, function (error) {
          _this2.set('isSaving', false);
          if ((0, _errors.isNotFoundError)(error)) {
            _this2.set('errorMessage', 'Access code not found or expired!');
          } else {
            _this2.set('errorMessage', 'An unknown error has occurred.');
          }
        });
      },
      closeErrorDialog: function closeErrorDialog() {
        this.set('errorMessage', null);
      },
      changePassword: function changePassword() {
        var _this3 = this;

        this.set('isSaving', true);
        var password = this.get('password');
        var password_confirmation = this.get('passwordConfirmation');
        this.get('authAjax').request(_environment.default.API.host + '/guardians/' + this.get('model.user.id') + '/password', {
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            guardian: {
              password: password,
              password_confirmation: password_confirmation
            }
          })
        }).then(function () {
          _this3.set('isSaving', false);
          _this3.set('showDialogPassword', false);
          _this3.set('password', '');
          _this3.set('passwordConfirmation', '');
        }).catch(function (error) {
          _this3.set('isSaving', false);

          if (error && Ember.get(error, 'payload.errors') && Object.values(Ember.get(error, 'payload.errors')).length) {
            alert(Object.values(Ember.get(error, 'payload.errors'))[0][0]);
          } else {
            alert('An error has occurred.');
          }
        });
      }
    }
  });
});
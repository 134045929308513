define('vimme/components/app-pages/student/assignments/assignment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    store: Ember.inject.service(),

    assignment: undefined,
    studentAssignment: undefined,
    question: undefined,
    questions: Ember.computed(function () {
      return [];
    }),
    studentQuestions: Ember.computed(function () {
      return [];
    }),
    studentQuestion: undefined,
    textAnswer: Ember.computed.oneWay('studentQuestion.textAnswer'),

    resetTextAnswer: function () {
      this.set('textAnswer', this.get('studentQuestion.textAnswer'));
    }.observes('studentQuestion.textAnswer'),

    number: 1,
    total: Ember.computed.alias('questions.length'),

    isTextType: Ember.computed('question.questionType', function () {
      return this.get('question.questionType') === 'text';
    }),

    isTextAnswerDirty: Ember.computed('textAnswer', 'studentQuestion.textAnswer', function () {
      return this.get('textAnswer') !== this.get('studentQuestion.textAnswer');
    }),

    isTextAnswerValid: Ember.computed.bool('textAnswer'),

    canSaveTextAnswer: Ember.computed('isTextAnswerDirty', 'isTextAnswerValid', 'isLoading', function () {
      return this.get('isTextAnswerDirty') && this.get('isTextAnswerValid') && !this.get('isLoading');
    }),

    answeredQuestions: Ember.computed('studentQuestions.@each.{assignmentQuestion.questionType,textAnswer,assignmentQuestionAnswers.length}', function () {
      return this.studentQuestions.filter(function (sa) {
        if (sa.get('assignmentQuestion.questionType') === 'text') {
          return !!sa.get('textAnswer');
        }
        return !!sa.get('assignmentQuestionAnswers.length');
      });
    }),

    left: Ember.computed('total', 'answeredQuestions.length', function () {
      return this.get('total') - this.get('answeredQuestions.length');
    }),

    prev: Ember.computed('number', function () {
      return this.get('number') - 1;
    }),
    next: Ember.computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: Ember.computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: Ember.computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    setup: function setup() {
      var _this = this;

      var studentQuestion = this.get('studentQuestions').find(function (sa) {
        return Ember.get(sa, 'assignmentQuestion.id') === _this.get('question.id');
      });

      if (!studentQuestion) {
        studentQuestion = this.get('store').createRecord('student-assignment-question', {
          assignmentQuestion: this.get('question'),
          studentAssignment: this.get('studentAssignment')
        });
      }

      this.setProperties({
        studentQuestion: studentQuestion,
        tool: undefined
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setup();
    },


    actions: {
      saveTextAnswer: function saveTextAnswer() {
        var _this2 = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('isLoading', true);
        this.set('studentQuestion.textAnswer', this.get('textAnswer'));

        this.get('studentQuestion').save().then(function () {
          if (!_this2.isDestroyed) {
            _this2.set('isLoading', false);
          }
        }).catch(function (err) {
          if (!_this2.isDestroyed) {
            _this2.set('isLoading', false);
          }
          console.warn(err);
          alert('Something went wrong saving your answer!');
        });
      },
      toggleAnswer: function toggleAnswer(answer) {
        var _this3 = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('isLoading', true);

        var type = this.get('question.questionType');
        var answers = this.get('studentQuestion.assignmentQuestionAnswers');
        var a = answers.find(function (a) {
          return a.get('id') === answer.get('id');
        });

        if (a) {
          answers.removeObject(a);
        } else {
          if (type === 'normal') {
            answers.clear();
          }
          answers.pushObject(answer);
        }

        this.get('studentQuestion').save().then(function () {
          if (!_this3.isDestroyed) {
            _this3.set('isLoading', false);
          }
        }).catch(function (err) {
          if (!_this3.isDestroyed) {
            _this3.set('isLoading', false);
          }
          console.warn(err);
          alert('Something went wrong saving your answer!');
        });
      },
      finish: function finish() {
        var _this4 = this;

        if (this.get('isLoading')) {
          return;
        }
        this.set('isLoading', true);
        var sa = this.get('studentAssignment');
        sa.set('isComplete', true);
        sa.save().then(function () {
          _this4.set('isLoading', false);
          _this4.get('router').transitionTo('student.assignment-calendar');
        }).catch(function (err) {
          console.warn(err);
          _this4.set('isLoading', false);
          alert('Something went wrong finishing your assignment!');
        });
      },
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      },
      openFinishDialog: function openFinishDialog() {
        this.set('isFinishDialogVisible', true);
      },
      closeFinishDialog: function closeFinishDialog() {
        this.set('isFinishDialogVisible', false);
      }
    }
  });
});
define('vimme/models/admin', ['exports', 'ember-data', 'vimme/models/user'], function (exports, _emberData, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend({
    canEditLiveTests: _emberData.default.attr('boolean'),
    eula: _emberData.default.attr('boolean'),
    organization: _emberData.default.attr('string')
  });
});
define('vimme/routes/student/testing-calendar', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        tests: _this.modelFor('student').tests,
        scheduledTests: _this.modelFor('student').scheduledTests
      });
    }
  });
});
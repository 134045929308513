define('vimme/controllers/teacher/management/student', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    authAjax: Ember.inject.service(),
    management: Ember.inject.controller('teacher.management'),

    showDialogAccessCode: false,
    showDialogClaim: false,
    showDialogStudent: false,
    showDialogScore: false,
    showDialogPassword: false,

    selectedClassroom: null,

    password: '',
    passwordConfirmation: '',

    newAccessCode: '',

    selectedScore: null,

    noSelectedScore: Ember.computed.not('selectedScore'),

    gradeNum: Ember.computed.oneWay('model.student.grade.id'),

    isClean: Ember.computed.not('model.student.isDirty'),

    tab: 'scores',

    validPassword: Ember.computed('password', 'passwordConfirmation', function () {
      var password = this.get('password'),
          passwordConfirmation = this.get('passwordConfirmation');

      return !!password && password.length >= 5 && password === passwordConfirmation;
    }),
    invalidPassword: Ember.computed.not('validPassword'),

    studentTestSorting: ['scheduledTest.startDate', 'attempt:asc'],
    sortedStudentTests: Ember.computed.sort('model.student.studentTests', 'studentTestSorting'),

    resetSelectedScore: function () {
      this.set('selectedScore', null);
    }.on('init').observes('model.student.id'),

    isValid: function () {
      var firstName = this.get('model.student.firstName').trim(),
          lastName = this.get('model.student.lastName').trim(),
          gradeNum = this.get('model.student.grade.id');

      return !!firstName && !!lastName && !!gradeNum;
    }.property('model.student.{firstName,middleInitial,lastName,grade}'),

    isUnsavable: function () {
      return this.get('isClean') || !this.get('isValid');
    }.property('isClean', 'isValid'),

    updateGrade: function () {
      var gradeNum = this.get('gradeNum'),
          grade = this.get('model.student.grade'),
          _this = this;

      if (gradeNum && gradeNum !== grade.get('id')) {
        this.store.find('grade', gradeNum).then(function (grade) {
          _this.set('model.student.grade', grade);

          _this.get('model.student').send('becomeDirty');
        }).catch(function () {
          _this.set('gradeNum', grade.get('id'));
        });
      }
    }.observes('gradeNum'),

    actions: {
      onToggleAudio: function onToggleAudio(e) {
        e.preventDefault();
        this.toggleProperty('model.student.enableAudio');
      },
      onToggleAudioEs: function onToggleAudioEs(e) {
        e.preventDefault();
        this.toggleProperty('model.student.enableAudioEs');
      },


      createParentCode: function createParentCode(student) {
        var _this2 = this;

        var accessCode = this.store.createRecord('accessCode', {
          student: student
        });

        accessCode.save().then(function (accessCode) {
          _this2.set('newAccessCode', accessCode.get('code'));
          _this2.set('showDialogAccessCode', true);
        });
      },

      showDialogAccessCode: function showDialogAccessCode() {
        this.set('showDialogAccessCode', true);
      },

      cancelDialogAccessCode: function cancelDialogAccessCode() {
        this.set('newAccessCode', '');
        this.set('showDialogAccessCode', false);
      },

      showDialogClaim: function showDialogClaim() {
        this.set('showDialogClaim', true);
      },

      cancelDialogClaim: function cancelDialogClaim() {
        this.set('showDialogClaim', false);
      },

      showDialogStudent: function showDialogStudent() {
        this.set('showDialogStudent', true);
      },

      cancelDialogStudent: function cancelDialogStudent() {
        this.set('showDialogStudent', false);
      },

      showDialogScore: function showDialogScore() {
        this.set('showDialogScore', true);
      },

      cancelDialogScore: function cancelDialogScore() {
        this.set('showDialogScore', false);
      },

      showDialogPassword: function showDialogPassword() {
        this.set('showDialogPassword', true);
      },

      cancelDialogPassword: function cancelDialogPassword() {
        this.set('showDialogPassword', false);
      },

      setSelected: function setSelected(score) {
        this.set('selectedScore', score);
      },

      save: function save(student) {
        student.save();
      },

      delete: function _delete(student) {
        var _this3 = this;

        student.destroyRecord().then(function () {
          _this3.set('showDialogStudent', false);
          _this3.transitionToRoute('teacher.management');
        }).catch(function () {
          student.rollback();
        });
      },

      claim: function claim(student) {
        var _this4 = this;

        this.get('authAjax').request(_environment.default.API.host + '/student-transfers', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            student_transfer: {
              classroom_id: this.get('selectedClassroom.id'),
              student_id: student.get('id')
            }
          })
        }).then(function () {
          // student.get('studentTests').then((studentTests) => {
          //   studentTests.invoke('deleteRecord');
          // Ember.run.later(() => {
          _this4.set('showDialogClaim', false);
          _this4.get('management').set('unassigned', false);
          // this.transitionToRoute('teacher.management', { queryParams: { unassigned: false } });
          _this4.send('refreshModel');
          // this.set('password', '');
          // this.set('passwordConfirmation', '');
          // this.transitionToRoute('teacher.management.student');
          // });
          // });
          // student.deleteRecord();
        }).catch(function (error) {
          var message = Ember.get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      },

      unassign: function unassign(student) {
        var _this5 = this;

        this.get('authAjax').request(_environment.default.API.host + '/unassigned-transfers', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            unassigned_transfer: {
              student_id: student.get('id')
            }
          })
        }).then(function () {
          _this5.set('showDialogStudent', false);
          _this5.get('management').set('unassigned', true);
          // this.transitionToRoute('teacher.management', { queryParams: { unassigned: false } });
          _this5.send('refreshModel');
          // this.transitionToRoute('teacher.management', { queryParams: { unassigned: false } });
          // student.get('studentTests').then((studentTests) => {
          //   studentTests.invoke('deleteRecord');
          //   Ember.run.later(() => {
          //     this.set('showDialogStudent', false);
          //     this.set('password', '');
          //     this.set('passwordConfirmation', '');
          //     this.transitionToRoute('teacher.management', { queryParams: { unassigned: false } });
          //   });
          // });
          // student.deleteRecord();
        }).catch(function (error) {
          var message = Ember.get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            // alert('An error has occurred.');
            throw error;
          }
        });
      },

      deleteScore: function deleteScore(selectedTest) {
        var _this6 = this;

        selectedTest.destroyRecord().then(function () {
          _this6.set('selectedScore', null);
          _this6.set('showDialogScore', false);
        }).catch(function () {
          selectedTest.rollback();
        });
      },

      setTab: function setTab(tab) {
        this.set('tab', tab);
      },


      changePassword: function changePassword(password, passwordConfirmation) {
        var _this7 = this;

        this.get('authAjax').request(_environment.default.API.host + '/students/' + this.get('model.student.id') + '/password', {
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            student: {
              password: password,
              password_confirmation: passwordConfirmation
            }
          })
        }).then(function (student) {
          _this7.store.pushPayload(student);
          _this7.set('showDialogPassword', false);
          _this7.set('password', '');
          _this7.set('passwordConfirmation', '');
        }).catch(function (error) {
          var message = Ember.get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      }
    }
  });
});
define('vimme/routes/school-admin/management', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'school-admin.management.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.management.sub-admins');
        } else {
          this.transitionTo('school-admin.management.sub-admins');
        }
      }
    }
  });
});
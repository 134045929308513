define('vimme/components/color-key/component', ['exports', 'vimme/constants/color'], function (exports, _color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    grade: 0,
    print: false,

    align: 'right',

    showKey: Ember.computed('grade', 'print', function () {
      if (this.get('print')) {
        return false;
      }

      var grade = parseInt(this.get('grade'), 10);

      return !!grade && !!_color.showKey[grade];
    })
  });
});
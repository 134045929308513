define('vimme/controllers/admin/reports/classroom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['course', 'week'],

    course: null,
    week: null,

    actions: {
      setCourse: function setCourse(course) {
        this.set('course', course && course.get('id'));
      },
      setWeek: function setWeek(week) {
        this.set('week', week && week.get('id'));
      }
    }
  });
});
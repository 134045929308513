define('vimme/helpers/short-date-time', ['exports', 'npm:moment'], function (exports, _npmMoment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortDateTime = shortDateTime;
  function shortDateTime(params /*, hash*/) {
    if (!params[0]) return;
    return (0, _npmMoment.default)(params[0]).format('MM/DD/YY h:mm a');
  }

  exports.default = Ember.Helper.helper(shortDateTime);
});
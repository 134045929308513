define('vimme/models/per-course-by-school-weekly-average', ['exports', 'ember-data', 'vimme/utils/color', 'vimme/utils/round'], function (exports, _emberData, _color, _round) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    total: _emberData.default.attr('number'),
    scores: _emberData.default.attr('string'),

    school: _emberData.default.belongsTo('school', { async: true }),
    course: _emberData.default.belongsTo('course', { async: true }),
    grade: _emberData.default.belongsTo('grade', { async: true }), // TODO: #CLASSROOM can we remove this?
    test: _emberData.default.belongsTo('test', { async: true }),
    week: _emberData.default.belongsTo('week', { async: true }),

    score: Ember.computed('score', function () {
      var scores = this.get('scores').split(',');

      scores.sort(function (a, b) {
        return parseInt(a, 10) - parseInt(b, 10);
      });

      return scores.reduce(function (prev, curr) {
        return parseInt(prev, 10) + parseInt(curr, 10);
      }) / scores.length;
    }),

    percent: Ember.computed('score', 'total', function () {
      var score = this.get('score'),
          total = this.get('total');

      return score / total * 100;
    }),

    roundedPercent: Ember.computed('percent', function () {
      return (0, _round.default)(this.get('percent'));
    }),

    formattedPercent: Ember.computed('roundedPercent', function () {
      return this.get('roundedPercent') + '%';
    }),

    // TODO: #CLASSROOM is there a better way to do this without needing grade available?
    color: Ember.computed('percent', 'grade.id', function () {
      var percent = (0, _round.default)(this.get('percent'));
      var grade = this.get('grade.id');

      return (0, _color.default)(percent, grade);
    })
  });
});
define('vimme/routes/admin/management/schools/index', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        school: this.store.createRecord('school')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('school').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
define('vimme/controllers/teacher/questions/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    questionsController: Ember.inject.controller('teacher/questions'),

    studentQuestions: Ember.computed.alias('questionsController.studentQuestions'),

    isReviewing: Ember.computed.alias('questionsController.isReviewing'),

    hideAnswers: true,

    school: null, // set in setupController

    setHideAnswers: function () {
      var isReviewing = this.get('isReviewing');

      if (isReviewing) {
        this.set('hideAnswers', false);
      } else {
        this.set('hideAnswers', true);
      }
    }.observes('isReviewing', 'model'),

    studentQuestion: function () {
      var studentQuestions = this.get('studentQuestions');

      if (studentQuestions && studentQuestions.get('length') > 0) {
        return studentQuestions.findBy('question.id', this.get('model.id'));
      }
    }.property('studentQuestions.[]', 'model')
  });
});
define('vimme/models/school', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isLocked: _emberData.default.attr('boolean'),
    isSubscription: _emberData.default.attr('boolean'),
    isTrial: _emberData.default.attr('boolean'),
    subscriptionType: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    trialType: _emberData.default.attr('string'),

    courses: _emberData.default.hasMany('course', { async: true }),

    createdAt: _emberData.default.attr('isodate'),
    updatedAt: _emberData.default.attr('isodate'),

    teacherCanScheduleTests: Ember.computed('isTrial', 'isSubscription', function () {
      return this.get('isTrial') || this.get('isSubscription');
    })
  });
});
define('vimme/models/scheduled-assignment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startAt: _emberData.default.attr('isodate'),
    endAt: _emberData.default.attr('isodate'),

    studentAssignments: _emberData.default.hasMany('student-assignment', { async: true }),
    studentAssignmentQuestions: _emberData.default.hasMany('student-assignment-question', { async: true }),

    classroom: _emberData.default.belongsTo('classroom', { async: true }),
    assignment: _emberData.default.belongsTo('assignment', { async: true }),

    isTakable: Ember.computed('startAt', 'endAt', function () {
      if (!this.get('startAt') || !this.get('endAt')) {
        return false;
      }
      return moment().isBetween(this.get('startAt'), this.get('endAt'));
    }),

    isInPast: Ember.computed('endAt', function () {
      return moment().isAfter(this.get('endAt'));
    })
  });
});
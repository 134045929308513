define('vimme/components/app-pages/teacher/assignment-builder/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    question: undefined,

    isPreviewVisible: false,

    number: Ember.computed('question.order', function () {
      return this.get('question.order') + 1;
    }),

    actions: {
      showPreview: function showPreview() {
        this.set('isPreviewVisible', true);
      },
      hidePreview: function hidePreview() {
        this.set('isPreviewVisible', false);
      }
    }
  });
});
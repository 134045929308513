define("vimme/components/vimme-resources-list/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 14,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-container input-container--grades");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'style');
          morphs[1] = dom.createMorphAt(element9, 1, 1);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "showUserTypes", ["loc", [null, [4, 73], [4, 86]]]], "width: 30%"], [], ["loc", [null, [4, 68], [4, 101]]]]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "userTypes", ["loc", [null, [6, 24], [6, 33]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.value", "selection", ["subexpr", "@mut", [["get", "localSelectedUserTypes", ["loc", [null, [9, 26], [9, 48]]]]], [], []], "placeholder", "Select User Type", "select-item", "userTypeChanged"], ["loc", [null, [5, 12], [12, 14]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-container input-container--grades");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element8, 'style');
          morphs[1] = dom.createMorphAt(element8, 1, 1);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "showUserTypes", ["loc", [null, [16, 73], [16, 86]]]], "width: 30%"], [], ["loc", [null, [16, 68], [16, 101]]]]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [18, 24], [18, 30]]]]], [], []], "optionValuePath", "content.shortTitle", "optionLabelPath", "content.shortTitle", "selection", ["subexpr", "@mut", [["get", "localSelectedGrades", ["loc", [null, [21, 26], [21, 45]]]]], [], []], "placeholder", "Select Grade", "select-item", "gradeChanged"], ["loc", [null, [17, 12], [24, 14]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 16
              },
              "end": {
                "line": 45,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("Grade");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["th td-grade sortable ", ["subexpr", "if", [["get", "sortedByGrade", ["loc", [null, [44, 58], [44, 71]]]], "sorted"], [], ["loc", [null, [44, 53], [44, 82]]]]]]], ["element", "action", ["sortBy", "grade"], [], ["loc", [null, [44, 84], [44, 111]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 12
            },
            "end": {
              "line": 47,
              "column": 12
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Resource");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Week");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(fragment, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createAttrMorph(element7, 'class');
          morphs[4] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["th td-resource sortable ", ["subexpr", "if", [["get", "sortedByTitle", ["loc", [null, [42, 57], [42, 70]]]], "sorted"], [], ["loc", [null, [42, 52], [42, 81]]]]]]], ["element", "action", ["sortBy", "title"], [], ["loc", [null, [42, 83], [42, 110]]]], ["block", "unless", [["get", "hideGrades", ["loc", [null, [43, 26], [43, 36]]]]], [], 0, null, ["loc", [null, [43, 16], [45, 27]]]], ["attribute", "class", ["concat", ["th td-week sortable ", ["subexpr", "if", [["get", "sortedByWeek", ["loc", [null, [46, 53], [46, 65]]]], "sorted"], [], ["loc", [null, [46, 48], [46, 76]]]]]]], ["element", "action", ["sortBy", "week"], [], ["loc", [null, [46, 78], [46, 104]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 12
            },
            "end": {
              "line": 52,
              "column": 12
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "th td-edit");
          var el2 = dom.createTextNode("Edit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Resource");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Grade");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Week");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [3]);
          var element3 = dom.childAt(fragment, [5]);
          var element4 = dom.childAt(fragment, [7]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createAttrMorph(element3, 'class');
          morphs[3] = dom.createElementMorph(element3);
          morphs[4] = dom.createAttrMorph(element4, 'class');
          morphs[5] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["th td-resource sortable ", ["subexpr", "if", [["get", "sortedByTitle", ["loc", [null, [49, 57], [49, 70]]]], "sorted"], [], ["loc", [null, [49, 52], [49, 81]]]]]]], ["element", "action", ["sortBy", "title"], [], ["loc", [null, [49, 83], [49, 110]]]], ["attribute", "class", ["concat", ["th td-grade sortable ", ["subexpr", "if", [["get", "sortedByGrade", ["loc", [null, [50, 54], [50, 67]]]], "sorted"], [], ["loc", [null, [50, 49], [50, 78]]]]]]], ["element", "action", ["sortBy", "grade"], [], ["loc", [null, [50, 80], [50, 107]]]], ["attribute", "class", ["concat", ["th td-week sortable ", ["subexpr", "if", [["get", "sortedByWeek", ["loc", [null, [51, 53], [51, 65]]]], "sorted"], [], ["loc", [null, [51, 48], [51, 76]]]]]]], ["element", "action", ["sortBy", "week"], [], ["loc", [null, [51, 78], [51, 104]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 8
            },
            "end": {
              "line": 58,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-resource-row", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [57, 42], [57, 50]]]]], [], []], "viewOnly", ["subexpr", "@mut", [["get", "viewOnly", ["loc", [null, [57, 60], [57, 68]]]]], [], []], "hideGrades", ["subexpr", "@mut", [["get", "hideGrades", ["loc", [null, [57, 80], [57, 90]]]]], [], []], "setVideoUrl", ["subexpr", "action", ["setVideoUrl"], [], ["loc", [null, [57, 103], [57, 125]]]]], ["loc", [null, [57, 12], [57, 127]]]]],
        locals: ["resource"],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 8
            },
            "end": {
              "line": 62,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tr");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "td td-none");
          var el3 = dom.createElement("em");
          var el4 = dom.createTextNode("No Resources");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 16
              },
              "end": {
                "line": 69,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("< Prev");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["prev"], [], ["loc", [null, [68, 23], [68, 40]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 16
              },
              "end": {
                "line": 75,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Next >");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["next"], [], ["loc", [null, [74, 23], [74, 40]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 8
            },
            "end": {
              "line": 77,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tfoot__prev");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tfoot__next");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [9]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "pagination.links.previous", ["loc", [null, [67, 22], [67, 47]]]]], [], 0, null, ["loc", [null, [67, 16], [69, 23]]]], ["content", "paginationStart", ["loc", [null, [71, 12], [71, 31]]]], ["content", "paginationEnd", ["loc", [null, [71, 32], [71, 49]]]], ["content", "pagination.total", ["loc", [null, [71, 52], [71, 72]]]], ["block", "if", [["get", "pagination.links.next", ["loc", [null, [73, 22], [73, 43]]]]], [], 1, null, ["loc", [null, [73, 16], [75, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child7 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-video", [], ["video", ["subexpr", "@mut", [["get", "videoUrl", ["loc", [null, [82, 24], [82, 32]]]]], [], []], "close", ["subexpr", "action", ["setVideoUrl", null], [], ["loc", [null, [82, 39], [82, 66]]]]], ["loc", [null, [82, 4], [82, 68]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 83,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-resources-list/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "filter-bar");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "filter-bar-label");
        var el3 = dom.createTextNode("Filter By:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--weeks");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "table");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "thead");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "tr");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tfoot");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [7]);
        var element12 = dom.childAt(fragment, [2]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element10, 3, 3);
        morphs[1] = dom.createMorphAt(element10, 5, 5);
        morphs[2] = dom.createAttrMorph(element11, 'style');
        morphs[3] = dom.createMorphAt(element11, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element12, [1, 1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element12, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element12, [5]), 1, 1);
        morphs[7] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showUserTypes", ["loc", [null, [3, 10], [3, 23]]]]], [], 0, null, ["loc", [null, [3, 4], [14, 11]]]], ["block", "unless", [["get", "hideGrades", ["loc", [null, [15, 14], [15, 24]]]]], [], 1, null, ["loc", [null, [15, 4], [26, 15]]]], ["attribute", "style", ["concat", [["subexpr", "if", [["get", "showUserTypes", ["loc", [null, [27, 68], [27, 81]]]], "width: 30%"], [], ["loc", [null, [27, 63], [27, 96]]]]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "weeks", ["loc", [null, [29, 20], [29, 25]]]]], [], []], "optionValuePath", "content.longName", "optionLabelPath", "content.longName", "selection", ["subexpr", "@mut", [["get", "localSelectedWeeks", ["loc", [null, [32, 22], [32, 40]]]]], [], []], "placeholder", "Select Week", "select-item", "weekChanged"], ["loc", [null, [28, 8], [35, 10]]]], ["block", "if", [["get", "viewOnly", ["loc", [null, [41, 18], [41, 26]]]]], [], 2, 3, ["loc", [null, [41, 12], [52, 19]]]], ["block", "each", [["get", "resources", ["loc", [null, [56, 16], [56, 25]]]]], [], 4, 5, ["loc", [null, [56, 8], [62, 17]]]], ["block", "if", [["get", "pagination", ["loc", [null, [65, 14], [65, 24]]]]], [], 6, null, ["loc", [null, [65, 8], [77, 15]]]], ["block", "if", [["get", "videoUrl", ["loc", [null, [81, 6], [81, 14]]]]], [], 7, null, ["loc", [null, [81, 0], [83, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }());
});
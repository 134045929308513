define("vimme/components/mathbots-teacher-reporting/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "title", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [6, 20], [6, 26]]]]], [], []], "value", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [7, 18], [7, 23]]]]], [], []], "update", ["subexpr", "action", [["get", "setGrade", ["loc", [null, [8, 27], [8, 35]]]]], [], ["loc", [null, [8, 19], [8, 36]]]], "prompt", "Select Grade", "promptIsSelectable", true], ["loc", [null, [3, 8], [11, 10]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 23,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "title", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "schools", ["loc", [null, [17, 20], [17, 27]]]]], [], []], "value", ["subexpr", "@mut", [["get", "school", ["loc", [null, [18, 18], [18, 24]]]]], [], []], "update", ["subexpr", "action", [["get", "setSchool", ["loc", [null, [19, 27], [19, 36]]]]], [], ["loc", [null, [19, 19], [19, 37]]]], "prompt", "Select School", "promptIsSelectable", true], ["loc", [null, [14, 8], [22, 10]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 4
            },
            "end": {
              "line": 34,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "lastThenFirst", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [28, 20], [28, 28]]]]], [], []], "value", ["subexpr", "@mut", [["get", "teacher", ["loc", [null, [29, 18], [29, 25]]]]], [], []], "update", ["subexpr", "action", [["get", "setTeacher", ["loc", [null, [30, 27], [30, 37]]]]], [], ["loc", [null, [30, 19], [30, 38]]]], "prompt", "Select Teacher", "promptIsSelectable", true], ["loc", [null, [25, 8], [33, 10]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 4
            },
            "end": {
              "line": 58,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "mathbots-key");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "mathbots-key__left");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__item");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__box benchmark");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__text");
          var el5 = dom.createTextNode("Passed");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__item");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__box intensive");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__text");
          var el5 = dom.createTextNode("Failed");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "mathbots-key__right");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__item");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__box");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__text");
          var el5 = dom.createTextNode("Unattempted");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mathbots-key__item");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__box");
          var el5 = dom.createTextNode("1+");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "mathbots-key__text");
          var el5 = dom.createTextNode("Attempts");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 20
                },
                "end": {
                  "line": 75,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "cell cell-link");
              dom.setAttribute(el1, "style", "width: 80%");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("/");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7, 0, 0);
              morphs[2] = dom.createMorphAt(element7, 2, 2);
              return morphs;
            },
            statements: [["element", "action", [["get", "setTeacher", ["loc", [null, [74, 78], [74, 88]]]], ["get", "teacherScore.teacherId", ["loc", [null, [74, 89], [74, 111]]]]], [], ["loc", [null, [74, 69], [74, 113]]]], ["content", "teacherScore.teacherName", ["loc", [null, [74, 114], [74, 142]]]], ["content", "teacherScore.schoolName", ["loc", [null, [74, 143], [74, 170]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 20
                },
                "end": {
                  "line": 77,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              dom.setAttribute(el1, "style", "width: 80%");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("/");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element6, 0, 0);
              morphs[1] = dom.createMorphAt(element6, 2, 2);
              return morphs;
            },
            statements: [["content", "teacherScore.teacherName", ["loc", [null, [76, 61], [76, 89]]]], ["content", "teacherScore.schoolName", ["loc", [null, [76, 90], [76, 117]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 12
              },
              "end": {
                "line": 79,
                "column": 12
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            dom.setAttribute(el2, "style", "width: 10%; text-align: center;");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            dom.setAttribute(el2, "style", "width: 10%");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n             ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(element8, 5, 5);
            return morphs;
          },
          statements: [["inline", "add", [["get", "index", ["loc", [null, [71, 84], [71, 89]]]], 1], [], ["loc", [null, [71, 78], [71, 93]]]], ["content", "teacherScore.score", ["loc", [null, [72, 57], [72, 79]]]], ["block", "if", [["subexpr", "includes", [["get", "teacherIds", ["loc", [null, [73, 36], [73, 46]]]], ["get", "teacherScore.teacherId", ["loc", [null, [73, 47], [73, 69]]]]], [], ["loc", [null, [73, 26], [73, 70]]]]], [], 0, 1, ["loc", [null, [73, 20], [77, 27]]]]],
          locals: ["teacherScore", "index"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 0
            },
            "end": {
              "line": 82,
              "column": 0
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-general column");
          dom.setAttribute(el1, "style", "width: 100%");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-general-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 10%; text-align: center;");
          var el5 = dom.createTextNode("Rank");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 10%");
          var el5 = dom.createTextNode("Score");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header no-hover");
          dom.setAttribute(el4, "style", "width: 80%");
          var el5 = dom.createTextNode("Classroom");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 6, 6);
          return morphs;
        },
        statements: [["block", "each", [["get", "leaderboards.topTeacherScores", ["loc", [null, [69, 20], [69, 49]]]]], [], 0, null, ["loc", [null, [69, 12], [79, 21]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child5 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 16
                },
                "end": {
                  "line": 89,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell-header student-column no-hover");
              var el2 = dom.createTextNode("Sign Up");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n             ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 93,
                  "column": 16
                },
                "end": {
                  "line": 95,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("%");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n             ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "signupAvg", ["loc", [null, [94, 38], [94, 51]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 24
                    },
                    "end": {
                      "line": 100,
                      "column": 124
                    }
                  },
                  "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "student.lastThenFirst", ["loc", [null, [100, 99], [100, 124]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 20
                  },
                  "end": {
                    "line": 101,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "link-to", [["get", "routeName", ["loc", [null, [100, 35], [100, 44]]]], ["get", "student.userId", ["loc", [null, [100, 45], [100, 59]]]]], ["class", "cell student-column cell-link"], 0, null, ["loc", [null, [100, 24], [100, 136]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 101,
                    "column": 20
                  },
                  "end": {
                    "line": 103,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "cell student-column");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["content", "student.lastThenFirst", ["loc", [null, [102, 57], [102, 82]]]]],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 24
                    },
                    "end": {
                      "line": 107,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                         ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createElement("span");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                     ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(element1, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element1, 'class');
                  morphs[1] = dom.createAttrMorph(element2, 'class');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell average-column ", ["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [106, 65], [106, 85]]]], "benchmark", "intensive"], [], ["loc", [null, [106, 60], [106, 111]]]]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [106, 131], [106, 151]]]], "icon-check", "icon-delete"], [], ["loc", [null, [106, 126], [106, 180]]]]]]]],
                locals: ["attempt"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 20
                  },
                  "end": {
                    "line": 108,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                 ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [105, 50], [105, 58]]]], ["get", "student.userId", ["loc", [null, [105, 59], [105, 73]]]]], [], ["loc", [null, [105, 32], [105, 74]]]]], [], 0, null, ["loc", [null, [105, 24], [107, 33]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 12
                },
                "end": {
                  "line": 110,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createComment("\n                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                 ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n             ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["block", "if", [["get", "routeName", ["loc", [null, [99, 26], [99, 35]]]]], [], 0, 1, ["loc", [null, [99, 20], [103, 27]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [104, 26], [104, 34]]]]], [], 2, null, ["loc", [null, [104, 20], [108, 27]]]]],
            locals: ["student"],
            templates: [child0, child1, child2]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 19
                },
                "end": {
                  "line": 118,
                  "column": 19
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell-header no-hover");
              var el2 = dom.createTextNode("L");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "level.order", ["loc", [null, [117, 58], [117, 73]]]]],
            locals: ["level"],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 19
                },
                "end": {
                  "line": 123,
                  "column": 19
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("%");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "average", ["loc", [null, [122, 41], [122, 52]]]]],
            locals: ["average"],
            templates: []
          };
        }();
        var child5 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 130,
                          "column": 81
                        },
                        "end": {
                          "line": 130,
                          "column": 129
                        }
                      },
                      "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "userLevel.attempts", ["loc", [null, [130, 107], [130, 129]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 129,
                        "column": 31
                      },
                      "end": {
                        "line": 131,
                        "column": 31
                      }
                    },
                    "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element0, 'class');
                    morphs[1] = dom.createMorphAt(element0, 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "attempt-color", [["get", "userLevel", ["loc", [null, [130, 68], [130, 77]]]]], [], ["loc", [null, [130, 52], [130, 79]]]]]]], ["block", "if", [["get", "userLevel.attempts", ["loc", [null, [130, 87], [130, 105]]]]], [], 0, null, ["loc", [null, [130, 81], [130, 136]]]]],
                  locals: ["userLevel"],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 128,
                      "column": 27
                    },
                    "end": {
                      "line": 132,
                      "column": 27
                    }
                  },
                  "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempt", ["loc", [null, [129, 57], [129, 64]]]], ["get", "level.id", ["loc", [null, [129, 65], [129, 73]]]]], [], ["loc", [null, [129, 39], [129, 74]]]]], [], 0, null, ["loc", [null, [129, 31], [131, 40]]]]],
                locals: ["level"],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 127,
                    "column": 23
                  },
                  "end": {
                    "line": 133,
                    "column": 19
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "each", [["get", "levels", ["loc", [null, [128, 35], [128, 41]]]]], [], 0, null, ["loc", [null, [128, 27], [132, 36]]]]],
              locals: ["attempt"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 12
                },
                "end": {
                  "line": 135,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createComment("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [127, 49], [127, 57]]]], ["get", "student.userId", ["loc", [null, [127, 58], [127, 72]]]]], [], ["loc", [null, [127, 31], [127, 73]]]]], [], 0, null, ["loc", [null, [127, 23], [133, 28]]]]],
            locals: ["student"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 0
              },
              "end": {
                "line": 138,
                "column": 0
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column no-hover");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n         ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n             ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n         ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createComment("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createComment("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
            morphs[2] = dom.createMorphAt(element4, 5, 5);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
            morphs[5] = dom.createMorphAt(element5, 5, 5);
            return morphs;
          },
          statements: [["block", "if", [["get", "signedUp", ["loc", [null, [87, 22], [87, 30]]]]], [], 0, null, ["loc", [null, [87, 16], [89, 23]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [93, 22], [93, 30]]]]], [], 1, null, ["loc", [null, [93, 16], [95, 23]]]], ["block", "each", [["get", "students", ["loc", [null, [97, 20], [97, 28]]]]], [], 2, null, ["loc", [null, [97, 12], [110, 21]]]], ["block", "each", [["get", "levels", ["loc", [null, [116, 27], [116, 33]]]]], [], 3, null, ["loc", [null, [116, 19], [118, 28]]]], ["block", "each", [["get", "averages", ["loc", [null, [121, 27], [121, 35]]]]], [], 4, null, ["loc", [null, [121, 19], [123, 28]]]], ["block", "each", [["get", "students", ["loc", [null, [125, 20], [125, 28]]]]], [], 5, null, ["loc", [null, [125, 12], [135, 21]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 0
            },
            "end": {
              "line": 138,
              "column": 0
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "students", ["loc", [null, [82, 10], [82, 18]]]]], [], 0, null, ["loc", [null, [82, 0], [138, 0]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 138,
            "column": 7
          }
        },
        "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "whole filter-bar");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element9, 1, 1);
        morphs[1] = dom.createMorphAt(element9, 2, 2);
        morphs[2] = dom.createMorphAt(element9, 3, 3);
        morphs[3] = dom.createMorphAt(element9, 4, 4);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "grades.length", ["loc", [null, [2, 10], [2, 23]]]]], [], 0, null, ["loc", [null, [2, 4], [12, 11]]]], ["block", "if", [["get", "schools.length", ["loc", [null, [13, 10], [13, 24]]]]], [], 1, null, ["loc", [null, [13, 4], [23, 11]]]], ["block", "if", [["get", "teachers.length", ["loc", [null, [24, 10], [24, 25]]]]], [], 2, null, ["loc", [null, [24, 4], [34, 11]]]], ["block", "if", [["get", "students", ["loc", [null, [35, 10], [35, 18]]]]], [], 3, null, ["loc", [null, [35, 4], [58, 11]]]], ["block", "if", [["get", "leaderboards", ["loc", [null, [60, 6], [60, 18]]]]], [], 4, 5, ["loc", [null, [60, 0], [138, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});
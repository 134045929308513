define('vimme/routes/student/test/questions', ['exports', 'vimme/routes/student'], function (exports, _student) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _student.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'student.test.questions.index') {
        this.replaceWith('student.test.questions.question', 1, { queryParams: transition.queryParams });
      }
    },
    model: function model() {
      return this.modelFor('student.test');
    },
    afterModel: function afterModel(model) {
      if (model.questions.get('length') === 0) {
        this.replaceWith('student');
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.get('model.studentQuestions').reload();
        controller.get('model.answers').reload();
      }
    }
  });
});
define('vimme/components/vimme-quick-question/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    attributeBindings: ['style'],
    classNames: ['vimme-quick-question'],

    studentQuestion: null,
    question: null,
    answers: null,

    alignLeft: null,
    alignTop: null,

    missingAnswers: computed('studentQuestion.answers.@each.isCorrect', 'question.isMultipleQuestion', 'answers.@each.isCorrect', function () {
      if (!this.get('question.isMultipleQuestion')) {
        return [];
      }
      var correctStudentAnswerIds = this.get('studentQuestion.answers').filterBy('isCorrect').mapBy('id');
      var correctAnswers = this.get('answers').filterBy('isCorrect');

      return correctAnswers.filter(function (answer) {
        return !correctStudentAnswerIds.contains(answer.get('id'));
      });
    }),

    style: computed('alignTop', 'alignLeft', function () {
      var alignTop = this.get('alignTop');
      var alignLeft = this.get('alignLeft');
      var styles = '';
      if (alignTop !== null) {
        styles += 'top: ' + alignTop + 'px; ';
      }
      if (alignLeft !== null) {
        styles += 'left: ' + alignLeft + 'px;';
      }
      return Ember.String.htmlSafe(styles);
    })
  });
});
define('vimme/controllers/school-admin/schedule-tests/index', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    isDemo: function () {
      return _environment.default.environment === 'demo' || _environment.default.environment === 'demo-dev';
    }.property(),

    selectedDate: null,
    selectedCourse: null,
    selectedClassroom: null,
    selectedTest: null,

    selectedCoursePG: null,

    pacingGuide: function () {
      var course = this.get('selectedCoursePG');

      if (!course) {
        return null;
      }

      var guideType = this.getGuideType(course.get('curriculum'));

      return this.assets.resolve('assets/pacing-guides/grade-' + course.get('grade.id') + '-pacing-guide-' + guideType + '.pdf');
    }.property('selectedCoursePG'),

    getGuideType: function getGuideType(curriculum) {
      switch (curriculum.get('value')) {
        case 'vimme':
          return 'VL';
        case 'core':
          return 'VCL';
        case 'early':
          return 'free-early';
        case 'late':
          return 'free-late';
        case '15':
          return '15';
        default:
          return 'VL';
      }
    },


    AMPM: [{ label: 'am', value: false }, { label: 'pm', value: true }],

    showStartPicker: false,
    showEndPicker: false,

    startDay: null,
    startTime: null,
    startHour: null,
    startMinute: null,
    startPM: false,

    endDay: null,
    endHour: null,
    endMinute: null,
    endPM: false,

    isScheduling: false,
    isNotScheduling: Ember.computed.not('isScheduling'),

    showLinks: Ember.computed.and('selectedCourse', 'selectedTest'),

    filteredClassrooms: Ember.computed.filter('model.classrooms', function (classroom) {
      if (this.get('selectedCourse')) {
        return classroom.get('course.id') === this.get('selectedCourse.id');
      }

      return true;
    }).property('model.classrooms', 'selectedCourse.grade.id'),

    filteredTests: Ember.computed.filter('model.tests', function (test) {
      if (!this.get('selectedCourse')) return false;

      return test.get('course.id') === this.get('selectedCourse.id');
    }).property('model.tests', 'selectedCourse.id'),

    updateStartHourMinute: function () {
      var startTime = this.get('startTime'),
          startHour = null,
          startMinute = null,
          other = null;

      if (startTime) {
        var _startTime$split = startTime.split(':');

        var _startTime$split2 = _slicedToArray(_startTime$split, 3);

        startHour = _startTime$split2[0];
        startMinute = _startTime$split2[1];
        other = _startTime$split2[2];
      }

      if (other) {
        startHour = 13;
      }

      this.set('startHour', startHour);
      this.set('startMinute', startMinute);
    }.on('init').observes('startTime'),

    updateEndHourMinute: function () {
      var endTime = this.get('endTime'),
          endHour = null,
          endMinute = null,
          other = null;

      if (endTime) {
        var _endTime$split = endTime.split(':');

        var _endTime$split2 = _slicedToArray(_endTime$split, 3);

        endHour = _endTime$split2[0];
        endMinute = _endTime$split2[1];
        other = _endTime$split2[2];
      }

      if (other) {
        endHour = 13;
      }

      this.set('endHour', endHour);
      this.set('endMinute', endMinute);
    }.on('init').observes('endTime'),

    startDate: function () {
      var startDay = this.get('startDay'),
          startHour = this.get('startHour'),
          startMinute = this.get('startMinute'),
          startPM = this.get('startPM'),
          startDate;

      if (startDay) {
        startDate = moment(startDay);

        if (startHour) {
          if (isNaN(startHour) || parseInt(startHour, 10) < 1 || parseInt(startHour, 10) > 12) {
            return null;
          }
          if (parseInt(startHour, 10) !== 12) {
            startDate.add(startHour, 'hours');
          }
        }

        if (startMinute) {
          if (isNaN(startMinute) || parseInt(startMinute, 10) < 0 || parseInt(startMinute, 10) > 59) {
            return null;
          }
          if (startMinute.length === 1) {
            if (parseInt(startMinute, 10) > 5) {
              return null;
            }
            startMinute += '0';
          }
          startDate.add(startMinute, 'minutes');
        }

        if (startPM) {
          startDate.add(12, 'hours');
        }

        if (startDate.isValid()) {
          return startDate.toDate();
        }
      }

      return null;
    }.property('startDay', 'startHour', 'startMinute', 'startPM'),

    endDate: function () {
      var endDay = this.get('endDay'),
          endHour = this.get('endHour'),
          endMinute = this.get('endMinute'),
          endPM = this.get('endPM'),
          endDate;

      if (endDay) {
        endDate = moment(endDay);

        if (endHour) {
          if (isNaN(endHour) || parseInt(endHour, 10) < 1 || parseInt(endHour, 10) > 12) {
            return null;
          }
          if (parseInt(endHour, 10) !== 12) {
            endDate.add(endHour, 'hours');
          }
        }

        if (endMinute) {
          if (isNaN(endMinute) || parseInt(endMinute, 10) < 0 || parseInt(endMinute, 10) > 59) {
            return null;
          }
          if (endMinute.length === 1) {
            endMinute += '0';
          }
          endDate.add(endMinute, 'minutes');
        }

        if (endPM) {
          endDate.add(12, 'hours');
        }

        if (endDate.isValid()) {
          return endDate.toDate();
        }
      }

      return null;
    }.property('endDay', 'endHour', 'endMinute', 'endPM'),

    endDateIsAfterStartDate: Ember.computed('startDate', 'endDate', function () {
      var startDate = moment(this.get('startDate')),
          endDate = moment(this.get('endDate'));

      return startDate.isValid() && endDate.isValid() && endDate.isAfter(startDate);
    }),

    isSchedulable: Ember.computed.and('selectedTest', 'startDate', 'endDate', 'endDateIsAfterStartDate', 'isNotScheduling'),
    isNotSchedulable: Ember.computed.not('isSchedulable'),

    selectDay: function selectDay(day) {
      this.set('selectedDate', day);
      this.transitionToRoute('school-admin.schedule-tests.day', moment(day).format('MM-DD-YYYY'));
    },

    actions: {
      select: function select(day) {
        this.selectDay(day);
      },

      selectCourse: function selectCourse(course) {
        this.set('selectedCourse', course);
      },
      selectClassroom: function selectClassroom(classroom) {
        this.set('selectedClassroom', classroom);
      },
      selectTest: function selectTest(test) {
        this.set('selectedTest', test);
      },


      selectToday: function selectToday() {
        this.selectDay(moment().startOf('day').toDate());
      },

      selectStartDay: function selectStartDay(day) {
        this.set('startDay', day);
        this.set('showStartPicker', false);
      },

      selectEndDay: function selectEndDay(day) {
        this.set('endDay', day);
        this.set('showEndPicker', false);
      },

      toggleStartPicker: function toggleStartPicker() {
        this.toggleProperty('showStartPicker');
      },

      toggleEndPicker: function toggleEndPicker() {
        this.toggleProperty('showEndPicker');
      },

      schedule: function schedule() {
        var _this = this;

        var selectedCourse = this.get('selectedCourse'),
            selectedClassroom = this.get('selectedClassroom'),
            selectedTest = this.get('selectedTest'),
            scheduledTests = this.get('model.scheduledTests'),
            startDate = this.get('startDate'),
            endDate = this.get('endDate'),
            classrooms,
            testsToSchedule,
            scheduledTestsToSave;

        if (!selectedCourse) {
          return;
        }

        this.set('isScheduling', true);

        if (selectedClassroom) {
          classrooms = [selectedClassroom];
        } else {
          classrooms = this.get('model.classrooms').filterBy('course.id', selectedCourse.get('id'));
        }

        if (selectedTest) {
          testsToSchedule = [selectedTest];
        } else {
          testsToSchedule = this.get('model.tests').filterBy('grade.id', selectedCourse.get('grade.id'));
        }

        scheduledTestsToSave = Ember.RSVP.all(classrooms.map(function (classroom) {
          return Ember.RSVP.all(testsToSchedule.map(function (test) {
            var found = true;
            var scheduledTest = scheduledTests.find(function (scheduledTest) {
              return scheduledTest.get('classroom.id') === classroom.get('id') && scheduledTest.get('test.id') === test.get('id');
            });

            if (!scheduledTest) {
              found = false;
              scheduledTest = _this.store.createRecord('scheduledTest', {
                classroom: classroom,
                test: test,
                startDate: startDate,
                endDate: endDate
              });
            } else {
              scheduledTest.set('startDate', startDate);
              scheduledTest.set('endDate', endDate);
            }

            return scheduledTest.save().then(function (scheduledTest) {
              if (!found) {
                scheduledTests.pushObject(scheduledTest);
              }
            });
          }));
        }));

        scheduledTestsToSave.finally(function () {
          _this.set('isScheduling', false);
          _this.set('showStartPicker', false);
          _this.set('showEndPicker', false);
        });
      }
    }
  });
});
define('vimme/routes/school-admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode'], function (exports, _authenticatedRouteMixin, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'index',
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref.role;

      if (role !== 'SchoolAdmin') {
        this.replaceWith('index');
      } else if (transition.targetName === 'school-admin.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.schedule-tests');
        } else {
          this.transitionTo('school-admin.schedule-tests');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref2.role,
          id = _ref2.id;

      var tests = this.store.all('test');

      return Ember.RSVP.hash({
        tests: tests.get('length') ? tests : this.store.findAll('test'),
        user: this.store.find(role, id)
      });
    }
  });
});
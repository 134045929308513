define('vimme/routes/admin/management/schools', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        schools: this.modelFor('admin.management').schools
      });
    }
  });
});
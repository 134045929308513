define('vimme/helpers/get-user-level', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getUserLevel = getUserLevel;
  function getUserLevel(params /*, hash*/) {
    return params[0].filterBy('level.id', params[1].get('id')).findBy('hasBeenPassed') || { hasBeenPassed: false };
  }

  exports.default = Ember.Helper.helper(getUserLevel);
});
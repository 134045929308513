define('vimme/components/app-pages/student/assignments/assignment-wrapper/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    isFinishDialogVisible: false,
    isFinishDialogHidden: Ember.computed.not('isFinishDialogVisible')
  });
});
define('vimme/components/app-pages/teacher/assignment-builder/question/component', ['exports', 'vimme/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    tagName: '',

    authAjax: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    isLoading: false,
    isDeleteAssignmentQuestionDialogVisible: false,

    answers: Ember.computed(function () {
      return [];
    }),

    questionTypes: Ember.computed(function () {
      return [{
        id: 'normal',
        title: 'Mutiple Choice with Single Answer'
      }, {
        id: 'multiple',
        title: 'Multiple Choice with Multiple Answers'
      }, {
        id: 'text',
        title: 'Text Answer'
      }];
    }),

    tools: Ember.computed(function () {
      return [{
        id: 'ruler',
        title: 'Ruler'
      }, {
        id: 'protractor',
        title: 'Protractor'
      }, {
        id: 'calculator',
        title: 'Calculator'
      }, {
        id: 'scientificCalculator',
        title: 'Scientific Calculator'
      }];
    }),

    isPreviewVisible: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.setup();
    },
    setTools: function setTools() {
      var tools = [];

      if (this.get('question.allowRuler')) {
        tools.push(this.get('tools').find(function (tool) {
          return tool.id === 'ruler';
        }));
      }
      if (this.get('question.allowProtractor')) {
        tools.push(this.get('tools').find(function (tool) {
          return tool.id === 'protractor';
        }));
      }
      if (this.get('question.allowCalculator')) {
        tools.push(this.get('tools').find(function (tool) {
          return tool.id === 'calculator';
        }));
      }
      if (this.get('question.allowScientificCalculator')) {
        tools.push(this.get('tools').find(function (tool) {
          return tool.id === 'scientificCalculator';
        }));
      }

      return tools;
    },
    setup: function setup() {
      var selectedTools = this.setTools();

      var editModel = {
        title: this.get('question.title'),
        imagePath: this.get('question.imagePath'),
        questionType: this.get('question.questionType')
      };

      this.setProperties({
        answers: this.get('question.assignmentQuestionAnswers').toArray().map(function (answer) {
          return {
            isCorrect: answer.get('isCorrect'),
            title: answer.get('title')
          };
        }),
        image: undefined,
        editModel: editModel,
        selectedTools: selectedTools,
        isNew: this.get('question.isNew')
      });
    },


    question: undefined,
    image: undefined,
    isNew: false,
    selectedTools: Ember.computed(function () {
      return [];
    }),

    imagePath: Ember.computed('editModel.imagePath', 'image', function () {
      var imagePath = this.get('image.name') || this.get('editModel.imagePath');

      if (imagePath) {
        var splitForward = imagePath.split('/');
        var splitBack = imagePath.split('\\');
        if (splitForward.length > 0) {
          imagePath = splitForward[splitForward.length - 1];
        } else if (splitBack.length > 0) {
          imagePath = splitBack[splitBack.length - 1];
        }
        imagePath = decodeURI(imagePath);
      }

      return imagePath;
    }),

    uploadImage: function uploadImage(image, question) {
      var _this = this;

      if (!image) {
        return Promise.resolve();
      }
      return this.get('authAjax').request(_environment.default.API.host + '/assignment-questions-upload-url', {
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify({
          type: image.type,
          name: image.name
        })
      }).then(function (data) {
        return _this.get('ajax').request(data.upload_url, {
          type: 'PUT',
          dataType: 'text',
          cache: false,
          processData: false,
          contentType: false,
          headers: {
            'Cache-Control': 'max-age=86400'
          },
          data: image
        }).then(function () {
          Ember.set(question, 'imagePath', 'https://s3.amazonaws.com/' + _environment.default.S3.bucket + '/new-quiz-questions/' + encodeURIComponent(data.filename));
        });
      });
    },
    back: function back() {
      var id = this.get('question.assignment.id');
      this.set('image', null);

      this.get('store').peekAll('assignment-question-answer').filterBy('id', null).invoke('deleteRecord');
      this.get('store').peekAll('assignment-question').filterBy('id', null).invoke('deleteRecord');

      var answers = this.get('deletedAnswers');
      answers.forEach(function (answer) {
        answer.rollbackAttributes();
      });

      this.get('router').transitionTo('teacher.builder.assignments.assignment', id);
    },


    isTextType: Ember.computed('editModel.questionType', function () {
      return this.get('editModel.questionType') === 'text';
    }),

    isQuestionTypeDirty: Ember.computed('editModel.questionType', 'question.questionType', function () {
      var em = this.get('editModel.questionType');
      var q = this.get('question.questionType');

      if (!em && !q) {
        return false;
      }

      return em !== q;
    }),

    isTitleDirty: Ember.computed('editModel.title', 'question.title', function () {
      var em = this.get('editModel.title');
      var q = this.get('question.title');

      if (!em && !q) {
        return false;
      }

      return em !== q;
    }),

    isImagePathDirty: Ember.computed('editModel.imagePath', 'imagePath', 'image', function () {
      if (this.get('image')) {
        return true;
      }

      var em = this.get('editModel.imagePath');
      var q = this.get('question.imagePath');

      if (!em && !q) {
        return false;
      }

      return em !== q;
    }),

    isAnswersDirty: Ember.computed('answers.@each.{isCorrect,title}', 'question.assignmentQuestionAnswers.@each.{isCorrect,title}', function () {
      var em = this.get('answers');
      var q = this.get('question.assignmentQuestionAnswers');

      if (em.length !== q.length) {
        return true;
      }

      return q.toArray().some(function (a, i) {
        return a.get('isCorrect') !== em[i].isCorrect || a.get('title') !== em[i].title;
      });
    }),

    isToolsDirty: Ember.computed('selectedTools.@each.id', 'question.{allowRuler,allowProtractor,allowCalculator,allowScientificCalculator}', function () {
      var tools = this.setTools().map(function (tool) {
        return Ember.get(tool, 'id');
      }).sort().join(',');
      var selectedTools = this.get('selectedTools').map(function (tool) {
        return Ember.get(tool, 'id');
      }).sort().join(',');

      return tools !== selectedTools;
    }),

    isTitleValid: Ember.computed('editModel.title', function () {
      return !!this.get('editModel.title');
    }),

    isQuestionTypeValid: Ember.computed('editModel.questionType', function () {
      return !!this.get('editModel.questionType');
    }),

    isAnswersValid: Ember.computed('question.assignmentQuestionAnswers.@each.{title,isCorrect}', 'isTextType', function () {
      var answers = this.get('question.assignmentQuestionAnswers');
      var isTextType = this.get('isTextType');

      if (isTextType && answers.get('length') < 1) return false;

      if (!isTextType && answers.get('length') < 2) return false;

      if (!isTextType && !answers.find(function (answer) {
        return !answer.get('isCorrect');
      })) return false;

      if (!isTextType && !answers.find(function (answer) {
        return answer.get('isCorrect');
      })) return false;

      if (answers.find(function (answer) {
        return !answer.get('title');
      })) return false;

      return true;
    }),

    isValid: Ember.computed.and('isTitleValid', 'isQuestionTypeValid', 'isAnswersValid'),
    isInvalid: Ember.computed.not('isValid'),

    isDirty: Ember.computed.or('isQuestionTypeDirty', 'isImagePathDirty', 'isTitleDirty', 'isAnswersDirty', 'isToolsDirty'),
    isClean: Ember.computed.not('isDirty'),

    canSave: Ember.computed('isDirty', 'isValid', 'isLoading', function () {
      return this.get('isDirty') && this.get('isValid') && !this.get('isLoading');
    }),
    cannotSave: Ember.computed.not('canSave'),

    deletedAnswers: Ember.computed(function () {
      return [];
    }),

    actions: {
      showPreview: function showPreview() {
        this.set('isPreviewVisible', true);
      },
      hidePreview: function hidePreview() {
        this.set('isPreviewVisible', false);
      },
      save: function save() {
        var _this2 = this;

        var image = this.get('image');

        var selectedTools = this.get('selectedTools').map(function (tool) {
          return tool.id;
        });

        this.set('isLoading', true);

        this.uploadImage(image, this.get('editModel')).then(function () {
          _this2.get('question').setProperties(_extends({}, _this2.get('editModel'), {
            allowRuler: selectedTools.includes('ruler'),
            allowProtractor: selectedTools.includes('protractor'),
            allowCalculator: selectedTools.includes('calculator'),
            allowScientificCalculator: selectedTools.includes('scientificCalculator')
          }));

          if (_this2.get('isTextType')) {
            _this2.get('question.assignmentQuestionAnswers').forEach(function (answer) {
              answer.set('isCorrect', true);
            });
          }

          return _this2.get('question').save().then(function (question) {
            _this2.set('isLoading', false);
            _this2.get('store').peekAll('assignment-question-answer').filterBy('id', null).invoke('deleteRecord');
            if (_this2.get('isNew')) {
              _this2.get('router').transitionTo('teacher.builder.assignments.assignment.question', question.get('id'));
            } else {
              _this2.setup();
            }
          });
        }).catch(function (err) {
          _this2.set('isLoading', false);
          console.warn(err);
          alert('Something went wrong saving your assignment question!');
        });
      },


      clear: function clear() {
        this.set('image', undefined);
        this.set('editModel.imagePath', undefined);
      },

      newAnswer: function newAnswer() {
        this.get('store').createRecord('assignment-question-answer', {
          assignmentQuestion: this.get('question'),
          isCorrect: false
        });
      },
      deleteAnswer: function deleteAnswer(answer) {
        var deletedAnswers = this.get('deletedAnswers');
        if (!answer.get('isNew')) {
          deletedAnswers.push(answer);
        }
        answer.deleteRecord();
      },
      deleteQuestion: function deleteQuestion() {
        var _this3 = this;

        this.set('isLoading', true);

        this.get('question').destroyRecord().then(function () {
          _this3.set('isLoading', false);
          _this3.back();
        }).catch(function (err) {
          _this3.set('isLoading', false);
          console.warn(err);
          alert('Something went wrong deleting your assignment question!');
        });
      },
      back: function back() {
        this.back();
      },
      openDeleteAssignmentQuestionDialog: function openDeleteAssignmentQuestionDialog() {
        this.set('isDeleteAssignmentQuestionDialogVisible', true);
      },
      closeDeleteAssignmentQuestionDialog: function closeDeleteAssignmentQuestionDialog() {
        this.set('isDeleteAssignmentQuestionDialogVisible', false);
      }
    }
  });
});
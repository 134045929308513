define('vimme/components/vimme-search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['vimme-search'],

    inputValue: '',

    placeholder: null,

    isOpen: false,

    list: null,

    items: [],

    focused: null,

    onInput: function () {
      var inputValue = this.get('inputValue');

      if (inputValue) {
        this.set('isOpen', true);
      } else {
        this.set('isOpen', false);
      }

      this.sendAction('on-input', this, inputValue);
    }.observes('inputValue'),

    focusNext: function focusNext(event) {
      event.preventDefault();

      var index = 0,
          focused = this.get('focused');

      if (focused) {
        index = this.get('items').indexOf(focused);
        index++;
      }

      this.focusItemAtIndex(index);
    },

    focusPrev: function focusPrev(event) {
      event.preventDefault();

      var index = 0,
          focused = this.get('focused');

      if (focused) {
        index = this.get('items').indexOf(focused);
        index--;
      }

      this.focusItemAtIndex(index);
    },

    focusItemAtIndex: function focusItemAtIndex(index) {
      var items = this.get('items');

      if (index === -1) {
        index = items.get('length') - 1;
      } else if (index === items.get('length')) {
        index = 0;
      }

      var item = items.objectAt(index);

      if (item) {
        this.focusItem(item);
      }
    },

    focusItem: function focusItem(item) {
      this.set('focused', item);
    },

    unfocus: function unfocus() {
      this.set('inputValue', '');
      this.set('focused', null);
    },

    onLostFocus: function () {
      Ember.run.later(this, function () {
        var el = this.get('element');

        if (el && !el.contains(document.activeElement)) {
          this.unfocus();
        }
      });
    }.on('focusOut'),

    select: function select() {
      var focused = this.get('focused'),
          items = this.get('items');

      if (focused && items.length) {
        focused.select();
      }
    },

    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case 13 /*enter*/:
          this.select();
          break;
        case 40 /*down*/:
          this.focusNext(event);
          break;
        case 38 /*up*/:
          this.focusPrev(event);
          break;
        case 27 /*esc*/:
          this.unfocus();
          break;
      }
    },

    registerItem: function registerItem(item) {
      var items = this.get('items');

      if (!items.length) {
        this.focusItem(item);
      }

      items.addObject(item);
    },

    deregisterItem: function deregisterItem(item) {
      this.get('items').removeObject(item);
    }
  });
});
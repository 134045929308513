define('vimme/constants/color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var scores = exports.scores = {
    0: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }],
    1: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }],
    2: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }],
    3: [{
      name: 'Above Proficiency',
      max: 85,
      color: 'blue'
    }, {
      name: 'At Proficiency',
      max: 71,
      color: 'green'
    }, {
      name: 'Approaching Proficiency',
      max: 60,
      color: 'yellow'
    }, {
      name: 'Below Proficiency',
      max: 0,
      color: 'red'
    }],
    4: [{
      name: 'Above Proficiency',
      max: 86,
      color: 'blue'
    }, {
      name: 'At Proficiency',
      max: 68,
      color: 'green'
    }, {
      name: 'Approaching Proficiency',
      max: 51,
      color: 'yellow'
    }, {
      name: 'Below Proficiency',
      max: 0,
      color: 'red'
    }],
    5: [{
      name: 'Above Proficiency',
      max: 88,
      color: 'blue'
    }, {
      name: 'At Proficiency',
      max: 72,
      color: 'green'
    }, {
      name: 'Approaching Proficiency',
      max: 52,
      color: 'yellow'
    }, {
      name: 'Below Proficiency',
      max: 0,
      color: 'red'
    }],
    6: [{
      name: 'Above Proficiency',
      max: 84,
      color: 'blue'
    }, {
      name: 'At Proficiency',
      max: 70,
      color: 'green'
    }, {
      name: 'Approaching Proficiency',
      max: 56,
      color: 'yellow'
    }, {
      name: 'Below Proficiency',
      max: 0,
      color: 'red'
    }],
    7: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }],
    8: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }],
    9: [{
      max: 85,
      color: 'green'
    }, {
      max: 63,
      color: 'yellow'
    }, {
      max: 0,
      color: 'red'
    }]
  };

  var showKey = exports.showKey = {
    0: false,
    1: false,
    2: false,
    3: true,
    4: true,
    5: true,
    6: true,
    7: false,
    8: false,
    9: false
  };
});
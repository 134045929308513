define('vimme/components/print-view-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    position: '',

    style: Ember.computed('position', function () {
      switch (this.get('position')) {
        case 'high':
          return 'margin-top: -25px;';
        case 'low':
          return 'margin-top: 15px;';
        default:
          return '';
      }
    })
  });
});
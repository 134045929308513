define('vimme/components/app-pages/student/assignment-calendar/assignment/component', ['exports', 'vimme/utils/date-in-range'], function (exports, _dateInRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    scheduledAssignment: undefined,

    studentAssignments: Ember.computed(function () {
      return [];
    }),

    studentAssignment: Ember.computed('scheduledAssignment', 'studentAssignments.@each.scheduledAssignment.id', function () {
      var _this = this;

      return this.get('studentAssignments').find(function (studentAssignment) {
        return studentAssignment.get('scheduledAssignment.id') === _this.get('scheduledAssignment.id');
      });
    }),

    isSelected: function () {
      var selected = this.get('selectedDate');
      var startDate = moment(this.get('scheduledAssignment.startAt'));
      var endDate = moment(this.get('scheduledAssignment.endAt'));

      return !!selected && (0, _dateInRange.default)(selected, startDate, endDate);
    }.property('selectedDate', 'scheduledAssignment.{startAt,endAt}')
  });
});
define('vimme/routes/school-admin/reports', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'school-admin.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.reports.week');
        } else {
          this.transitionTo('school-admin.reports.week');
        }
      }
    },
    model: function model() {
      var courses = this.store.findAll('course');
      var grades = this.store.findAll('grade');
      var schoolYears = this.store.findAll('schoolYear');
      var weeks = this.store.findAll('week');

      return Ember.RSVP.hash({ courses: courses, grades: grades, schoolYears: schoolYears, weeks: weeks });
    }
  });
});
define('vimme/routes/admin/tests', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('admin.test-data');
    }
  });
});
define('vimme/routes/admin/reports/question', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _admin.default.extend({
    model: function model() {
      var models = this.modelFor('admin.reports');
      return _extends({}, models, {
        schools: models.schools.filterBy('isTrial', false).filterBy('isLocked', false),
        tests: this.modelFor('admin').tests
      });
    }
  });
});
define('vimme/routes/school-admin/management/classrooms/index', ['exports', 'vimme/routes/school-admin'], function (exports, _schoolAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _schoolAdmin.default.extend({
    model: function model(params) {
      var _modelFor = this.modelFor('school-admin.management.classrooms'),
          courses = _modelFor.courses,
          teachers = _modelFor.teachers;

      return Ember.RSVP.hash({
        classroom: this.store.createRecord('classroom'),
        courses: courses,
        teachers: teachers
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('classroom').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});
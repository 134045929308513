define('vimme/controllers/teacher/reports/assignment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['assignment', 'classroom', 'grade'],

    assignment: null,
    classroom: null,
    grade: null
  });
});
define('vimme/controllers/school-admin/guardian-instructions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['lang'],

    lang: 'en'
  });
});
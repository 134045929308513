define('vimme/controllers/admin/builder/paper-assignment-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    new: Ember.inject.controller('admin.builder.paper-assignment-questions.new'),

    actions: {
      newPaperAssignmentQuestion: function newPaperAssignmentQuestion() {
        if (this.get('application.currentRouteName') !== 'admin.builder.paper-assignment-questions.new') {
          this.transitionToRoute('admin.builder.paper-assignment-questions.new');
        } else {
          this.store.all('quizQuestion').filterBy('id', null).invoke('deleteRecord');
          this.set('new.model.quizQuestion', this.store.createRecord('quizQuestion', {
            title: null
          }));
        }
      }
    }
  });
});
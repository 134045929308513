define('vimme/routes/sub-admin/paper-assignment-builder', ['exports', 'vimme/routes/sub-admin'], function (exports, _subAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      var subAdmin = this.modelFor('sub-admin').user;
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        quizzes: subAdmin.get('quizzes'),
        subAdmin: subAdmin
      });
    }
  });
});
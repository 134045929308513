define('vimme/routes/admin/test-data/test', ['exports', 'vimme/routes/admin'], function (exports, _admin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend({
    model: function model(params) {
      return this.store.find('test', params.test_id);
    }
  });
});
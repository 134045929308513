define('vimme/components/app-pages/student/assignments/assignment-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    store: Ember.inject.service(),

    assignment: undefined,
    scheduledAssignment: undefined,
    studentAssignment: undefined,
    question: undefined,
    questions: Ember.computed(function () {
      return [];
    }),
    studentQuestions: Ember.computed(function () {
      return [];
    }),
    studentQuestion: undefined,

    missingAnswers: Ember.computed('studentQuestion.assignmentQuestionAnswers.[]', 'question.assignmentQuestionAnswers.[]', function () {
      var _this = this;

      return this.get('question.assignmentQuestionAnswers').filter(function (answer) {
        return answer.get('isCorrect') && !_this.get('studentQuestion.assignmentQuestionAnswers').find(function (a) {
          return a.get('id') === answer.get('id');
        });
      });
    }),

    showMissingAnswers: Ember.computed('studentQuestion.isCorrect', 'question.isMultipleQuestion', 'missingAnswers.length', function () {
      if (!this.get('question.isMultipleQuestion')) return false;
      if (this.get('studentQuestion.isCorrect')) return false;
      return !!this.get('missingAnswers.length');
    }),

    isUnanswered: Ember.computed('studentQuestion.{assignmentQuestionAnswers.length,textAnswer}', 'isTextType', function () {
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.assignmentQuestionAnswers.length');
      return !this.get('isTextType') && !answers || typeof textAnswer === 'undefined';
    }),

    isTextType: Ember.computed('question.questionType', function () {
      return this.get('question.questionType') === 'text';
    }),

    number: 1,
    total: Ember.computed.alias('questions.length'),

    prev: Ember.computed('number', function () {
      return this.get('number') - 1;
    }),
    next: Ember.computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: Ember.computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: Ember.computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    setup: function setup() {
      var _this2 = this;

      var studentQuestion = this.get('studentQuestions').find(function (sq) {
        return Ember.get(sq, 'assignmentQuestion.id') === _this2.get('question.id');
      });

      if (!studentQuestion) {
        studentQuestion = this.get('store').createRecord('student-assignment-question', {
          assignmentQuestion: this.get('question'),
          studentAssignment: this.get('studentAssignment')
        });
      }

      this.setProperties({
        studentQuestion: studentQuestion,
        tool: undefined
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setup();
    },


    actions: {
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      }
    }
  });
});
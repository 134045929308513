define('vimme/components/app-pages/teacher/assignment-builder/question-bank/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    assignmentBuilder: Ember.inject.service(),

    // input
    categories: Ember.computed(function () {
      return [];
    }),
    difficulties: Ember.computed(function () {
      return [];
    }),
    grades: Ember.computed(function () {
      return [];
    }),

    questions: Ember.computed(function () {
      return [];
    }),
    pagination: null,

    selectedQuestion: null,

    prev: Ember.computed.bool('pagination.links.previous'),
    next: Ember.computed.bool('pagination.links.next'),

    currentPage: Ember.computed.alias('pagination.current_page'),
    perPage: Ember.computed.alias('pagination.per_page'),
    count: Ember.computed.alias('pagination.count'),
    total: Ember.computed.alias('pagination.total'),
    totalPages: Ember.computed.alias('pagination.total_pages'),

    prevPage: Ember.computed('currentPage', function () {
      return this.get('currentPage') ? this.get('currentPage') - 1 : 0;
    }),
    nextPage: Ember.computed('currentPage', 'totalPages', function () {
      if (!this.get('currentPage')) return 0;
      return this.get('currentPage') < this.get('totalPages') ? this.get('currentPage') + 1 : 0;
    }),
    start: Ember.computed('pagination', 'currentPage', 'perPage', function () {
      if (!this.get('pagination')) {
        return 0;
      }
      return (this.get('currentPage') - 1) * this.get('perPage') + 1;
    }),
    end: Ember.computed('pagination', 'currentPage', 'perPage', 'count', function () {
      if (!this.get('pagination')) {
        return 0;
      }
      return (this.get('currentPage') - 1) * this.get('perPage') + this.get('count');
    }),

    selectedCategory: null,
    selectedDifficulty: null,
    selectedGrade: null,

    isLoading: false,

    reconcileCategories: function reconcileCategories() {
      var _this = this;

      var grade = this.get('selectedGrade');
      var category = this.get('selectedCategory');

      if (!grade) {
        return Ember.RSVP.resolve();
      }

      return this.get('store').find('category', { grades: grade }).then(function (categories) {
        var categoryFound = function categoryFound() {
          return categories.find(function (item) {
            return item.get('lowercaseTitle') === category;
          });
        };
        if (!category || !categoryFound()) {
          _this.set('selectedCategory', null);
        }
        _this.set('categories', categories);
      });
    },
    loadQuestions: function loadQuestions(page) {
      var _this2 = this;

      var category = this.get('selectedCategory');
      var difficulty = this.get('selectedDifficulty');
      var grade = this.get('selectedGrade');

      this.set('isLoading', true);

      this.get('store').find('quiz-question', {
        categories: category,
        difficulties: difficulty,
        grades: grade,
        page: page || 1
      }).then(function (questions) {
        if (!_this2.isDestroyed) {
          _this2.set('questions', questions);
          _this2.set('isLoading', false);
          _this2.set('pagination', questions.get('meta.pagination'));
        }
      }).catch(function (err) {
        console.warn(err);
        if (!_this2.isDestroyed) {
          _this2.set('isLoading', false);
        }
      });
    },


    actions: {
      findQuestions: function findQuestions(_, page) {
        var _this3 = this;

        if (this.get('isLoading')) return;

        if (!this.get('selectedCategory') && !this.get('selectedDifficulty') && !this.get('selectedGrade')) {
          this.set('questions', []);
          this.set('pagination', null);
          return;
        }

        this.reconcileCategories().then(function () {
          _this3.set('selectedQuestion', null);
          _this3.loadQuestions(page);
        });
      },
      showPreview: function showPreview(question) {
        this.set('selectedQuestion', question);
      },
      hidePreview: function hidePreview() {
        this.set('selectedQuestion', null);
      },
      createQuestion: function createQuestion(question) {
        this.set('assignmentBuilder.question', question);
        this.router.transitionTo('teacher.builder.assignments.assignment.new');
      }
    }
  });
});
define('vimme/routes/sub-admin/reports/growth', ['exports', 'vimme/routes/sub-admin'], function (exports, _subAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subAdmin.default.extend({
    queryParams: {
      from: {
        replace: true
      },
      to: {
        replace: true
      },
      course: {
        replace: true
      },
      classroom: {
        replace: true
      }
    },

    model: function model(params) {
      var weeks = this.modelFor('sub-admin.reports').weeks;
      var courses = this.modelFor('sub-admin.reports').courses;

      var user = this.modelFor('sub-admin').user;
      var schools = this.store.findAll('school');
      var selectedFrom = params.from ? weeks.find(function (week) {
        return week.get('id') === params.from;
      }) : undefined;
      var selectedTo = params.to ? weeks.find(function (week) {
        return week.get('id') === params.to;
      }) : undefined;
      var selectedCourse = params.course ? courses.find(function (course) {
        return course.get('id') === params.course;
      }) : undefined;
      var classrooms = selectedCourse ? this.store.query('classroom', { course: selectedCourse.get('id') }) : undefined;
      var selectedClassroom = params.classroom && selectedCourse ? classrooms.then(function (ts) {
        return ts.find(function (classroom) {
          return classroom.get('id') === params.classroom;
        });
      }) : undefined;
      var students = params.classroom && selectedCourse ? selectedClassroom.then(function (t) {
        return t.get('students');
      }) : undefined;

      return Ember.RSVP.hash({
        params: params,
        schools: schools,
        weeks: weeks,
        courses: courses,
        selectedCourse: selectedCourse,
        selectedClassroom: selectedClassroom,
        selectedFrom: selectedFrom,
        selectedTo: selectedTo,
        students: students,
        classrooms: classrooms,
        user: user
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedCourse', model.selectedCourse);
      controller.set('selectedClassroom', model.selectedClassroom);
      controller.set('selectedFrom', model.selectedFrom);
      controller.set('selectedTo', model.selectedTo);
      controller.set('students', model.students);
      controller.set('classrooms', model.classrooms);
      controller.set('selectedClassroom', model.selectedClassroom);
    }
  });
});
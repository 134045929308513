define('vimme/routes/teacher', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode'], function (exports, _authenticatedRouteMixin, _npmJwtDecode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'index',
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref.role;

      this._super(transition);

      if (role !== 'Teacher') {
        this.replaceWith('index');
      } else if (transition.targetName === 'teacher.index') {
        if (transition.sequence === 0) {
          this.replaceWith('teacher.testing-calendar');
        } else {
          this.transitionTo('teacher.testing-calendar');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode.default)(token) : {},
          role = _ref2.role,
          id = _ref2.id;

      var user = this.store.find(role, id);

      return Ember.RSVP.hash({
        professorLevels: user.then(function (user) {
          return user.get('professorLevels');
        }),
        tests: this.store.findAll('test'),
        scheduledTests: this.store.findAll('scheduledTest'),
        user: user,
        school: user.then(function (user) {
          return user.get('school');
        }),
        classrooms: user.then(function (user) {
          return user.get('classrooms');
        }),
        courses: user.then(function (user) {
          return user.get('courses');
        })
      });
    },
    afterModel: function afterModel(model) {
      if (!window.teacherInited) {
        window.teacherInited = true;

        var professorLevels = model.professorLevels;

        if (professorLevels && professorLevels.get('length') && !window.crate) {
          try {
            window.crate = new Crate({
              server: '767817740282232832', // Vimme Learning
              channel: '767817740282232835', // #vimme-professors
              location: ['bottom', 'left'],
              color: '#fff',
              glyph: ['/assets/images/icon.png', '90%']
            });
          } catch (err) {}
        }
      }
    }
  });
});
define('vimme/components/app-pages/teacher/reports/assignment/question-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    questions: null,
    studentQuestions: null,
    studentAssignment: null,

    paddedStudentQuestions: Ember.computed('studentQuestions', 'studentAssignment', function () {
      var _this = this;

      var studentQuestions = this.get('studentQuestions').filter(function (sa) {
        return sa.get('studentAssignment.id') === _this.get('studentAssignment.id');
      });
      var questions = this.get('questions');

      if (!questions) {
        return;
      }

      return questions.map(function (question, num) {
        var studentQuestion = studentQuestions.find(function (studentQuestion) {
          return studentQuestion.get('assignmentQuestion.id') === question.get('id');
        });

        return { studentQuestion: studentQuestion || null, num: num + 1 };
      });
    })
  });
});